import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon, Link } from "../index";

export default function WidgetAction({
  text,
  iconName,
  isIconFirst,
  onClick,
  isLink,
  href,
  testid,
}) {
  return isLink ? (
    <Link
      href={href}
      testid={testid}
      iconName={iconName}
      isIconFirst={isIconFirst}
      styleType="gray"
      size="xsmall"
      text={text}
      isUnderlined
      additionalClasses="self-start bg-transparent"
    />
  ) : (
    // FIXME: This really can't be a button... it's styled too much like a link and doesn't make sense.
    <button
      className={classNames(
        "flex text-custom-gray-300 gap-1 items-center underline",
        {
          "flex-row-reverse": !isIconFirst,
        }
      )}
      type="submit"
      onClick={onClick}
      data-testid={testid}
    >
      {iconName && (
        <Icon iconName={iconName} fill="fill-custom-gray-300" size="xsmall" />
      )}
      <h5 className="text-[12px] leading-6">{text}</h5>
    </button>
  );
}

WidgetAction.propTypes = {
  text: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  isIconFirst: PropTypes.bool,
  // Is required if not isLink
  onClick: PropTypes.func,
  isLink: PropTypes.bool,
  // Is required if isLink
  href: PropTypes.string,
  testid: PropTypes.string,
};

WidgetAction.defaultProps = {
  isIconFirst: true,
  testid: "widgetAction",
};
