import { ethers } from "ethers";
import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { populateTransaction } from "./ethereumHelpers";
import { SAVE_MINTING_TRANSACTION } from "../queries";

const useEthers = () => {
  const ethersProvider = window.ethereum
    ? new ethers.providers.Web3Provider(window.ethereum)
    : null;

  // Note: Need to be careful about which methods are MetaMask specific vs which are
  // generic, but trying to wrap everything within Ethers at least.
  const [isMetaMaskInstalled, setIsInstalled] = useState(
    ethersProvider?.connection?.url === "metamask" || false
  );
  const [accounts, setAccounts] = useState([]);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState();
  const [isMinting, setIsMinting] = useState();
  const [transaction, setTransaction] = useState();
  const [mintResult, setMintResult] = useState();
  const [isMintSuccessful, setIsMintSuccessful] = useState();

  // FIXME: Not sure what to do if saving the transaction fails or errors.
  // Perhaps retry?
  const [saveMintingTransactionHash] = useMutation(SAVE_MINTING_TRANSACTION);

  const handleError = (e) => {
    if (e.code === 4001) {
      // EIP-1193 userRejectedRequest error
      // If this happens, the user rejected the connection request.
      return;
    }
    if (import.meta.env.DEV) console.error(e);
    setError(e);
  };

  const handleAccountsChanged = (accts) => {
    if (accts.length === 0) {
      // Wallet is locked or the user has not connected any accounts
      return setIsConnected(false);
    }
    const checkSumAddressNewAcct = ethers.utils.getAddress(accts[0]);
    const checkSumAddressStateAcct =
      accounts.length > 0 ? ethers.utils.getAddress(accounts[0]) : null;

    if (checkSumAddressNewAcct !== checkSumAddressStateAcct) {
      setAccounts(accts);
    }
    return setIsConnected(true);
  };

  const getConnectedAccount = async () => {
    // This method just gets the connected account
    const accts = await ethersProvider?.send("eth_accounts", []);
    if (accts) {
      handleAccountsChanged(accts);
      if (error) setError();
    }
    return accts[0];
  };

  const requestAccounts = async () => {
    // This method opens MetaMask and should be used on user action.
    const accts = await ethersProvider?.send("eth_requestAccounts", []);
    if (accts) {
      handleAccountsChanged(accts);
    }
  };

  useEffect(() => {
    // Not sure if there is another ethers method for this that I haven't figured out yet
    // For this reason, also unsure if this is just a MetaMask specific method...
    ethersProvider?.provider?.on("accountsChanged", handleAccountsChanged);

    return () => {
      ethersProvider?.provider?.removeListener(
        "accountsChanged",
        handleAccountsChanged
      );
    };
  }, []);

  useEffect(() => {
    // Not sure if there is another ethers method for this that I haven't figured out yet
    // For this reason, also unsure if this is just a MetaMask specific method...
    ethersProvider?.provider?.on("disconnect", () => {
      setIsConnected(false);
    });
  }, []);

  useEffect(() => {
    if (isMetaMaskInstalled) {
      const getAccounts = async () => getConnectedAccount();
      try {
        getAccounts();
      } catch (e) {
        handleError(e);
      }
    }
  }, [isMetaMaskInstalled]);

  // This connect will allow for connection to any Eth Web3Provider
  // so this means that a user could have MetaMask installed, but still choose
  // to connect to a different, wallet or account.
  const connect = async () => {
    setIsConnecting(true);

    try {
      const requestResolved = await requestAccounts();
      if (requestResolved) {
        setIsConnecting(false);
      }
    } catch (e) {
      handleError(e);
    }
  };

  const mint = async (
    recipientAddress,
    tokenUri,
    authorization,
    validUntilTimestamp,
    contractAddress,
    courseId
  ) => {
    setIsMinting(true); // TODO: what happens if a user tries to navigate away? Should we warn to wait?
    // Should we save the user's address and/or populated transaction just in case?

    const selectedAddress = await getConnectedAccount();

    const tx = populateTransaction(
      selectedAddress,
      contractAddress,
      authorization,
      validUntilTimestamp,
      recipientAddress,
      tokenUri
    );

    try {
      const txn = await ethersProvider?.send("eth_sendTransaction", [tx]);
      if (txn) {
        saveMintingTransactionHash({
          variables: {
            transactionHash: txn,
            contractAddress,
            tokenUri,
            courseId,
          },
        });
        setTransaction(txn);
      }
    } catch (e) {
      handleError(e);
      // Assumption that if there is no transaction, there is no minting...
      // TODO: Check if this is where the URI error catches
      setIsMinting(false);
    }
  };

  useEffect(() => {
    // TODO: Call for transaction from BE, in the case of a user navigating away
    // before the transaction is complete
    if (transaction && !mintResult) {
      const minted = async () => {
        try {
          const result = await ethersProvider?.waitForTransaction(transaction);
          if (result) {
            setMintResult(result);
          }
        } catch (e) {
          handleError(e);
        }
      };

      minted();
    }
  }, [transaction]);

  // The waitForTransaction will wait/confirm if the transaction has been mined.
  // https://docs.ethers.org/v5/api/providers/provider/#Provider-getTransactionReceipt
  const getReceipt = async (txnHash) => {
    try {
      const txnReceipt = await ethersProvider?.getTransactionReceipt(txnHash);
      if (txnReceipt) {
        setMintResult(txnReceipt);
      }
    } catch (e) {
      handleError(e);
    }
  };

  useEffect(() => {
    setIsMintSuccessful(mintResult?.status === 1);
    if (mintResult?.status === 1) setIsMinting(false);
  }, [mintResult]);

  return {
    isMetaMaskInstalled,
    connect,
    isConnecting,
    isConnected,
    accounts,
    error,
    mintToEth: mint,
    transaction,
    isMinting,
    isMintSuccessful,
    getReceipt,
  };
};

export default useEthers;
