import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { GET_COURSES, GET_USER_COURSES } from "../queries";
import { authentication } from "../context";
import { normalizeCourseFromApi } from "./helpers/courseHelpers";
import { hardcodedCourses } from "../hardcodedData";

export function normalizeCoursesFromApi(apiCourses) {
  if (!apiCourses || apiCourses?.length === 0) return [];

  const normalizedCourses = apiCourses?.map((course) =>
    normalizeCourseFromApi(course)
  );

  return normalizedCourses;
}

export default function useCourses() {
  const { isAuthenticated } = authentication() || false;

  const query = isAuthenticated ? GET_USER_COURSES : GET_COURSES;

  const { loading, data, error } = useQuery(query);

  const courses = useMemo(() => {
    const ids = {};
    return normalizeCoursesFromApi(data?.courses)
      .concat(hardcodedCourses)
      .filter((c) => {
        const seenBefore = ids[c.id];
        ids[c.id] = true;
        return !seenBefore;
      });
  }, [data?.courses.length]);

  return { loading, error, courses };
}
