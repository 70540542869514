import { BASE_OAUTH_CONFIG } from "./base";

export const OAUTH_PROVIDER_DISCORD_NAME = "discord";

export const DISCORD_AUTH_URL = "https://discord.com/api/oauth2/authorize";

export const DISCORD_OAUTH_LOGIN_CONFIG = {
  ...BASE_OAUTH_CONFIG,
  client_id: import.meta.env.VITE_DISCORD_QB_VERIFIED_CLIENT_ID,
  // redirect_uri: import.meta.env.VITE_DISCORD_LOGIN_REDIRECT_URI, // If we ever decide to support login via Discord OAuth
  scope: "role_connections.write identify", // If we ever decide to support login via Discord OAuth, add "email" to scope (if using the same Discord bot for both login and linked-roles)
};

export const DISCORD_LINKED_ROLES_CONFIG = {
  ...DISCORD_OAUTH_LOGIN_CONFIG,
  redirect_uri: import.meta.env.VITE_DISCORD_QB_VERIFIED_REDIRECT_URI,
};
