/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext } from "react";
import PropTypes from "prop-types";
import { useTheme } from "../hooks";

export const Context = createContext();

export function ThemeContext({ children }) {
  const [currentTheme, setTheme] = useTheme();

  return (
    <Context.Provider value={[currentTheme, setTheme]}>
      {children}
    </Context.Provider>
  );
}

export default function theme() {
  const context = React.useContext(Context);
  if (!Context && context === undefined) {
    throw new Error("useTheme must be used within a Provider");
  }
  return context;
}

ThemeContext.propTypes = {
  children: PropTypes.any.isRequired,
};
