import React from "react";
import { Link } from "../../components";

import { ReactComponent as EarthImage } from "../../assets/svgs/landingPage/earth.svg";
import { ReactComponent as BackgroundStars } from "../../assets/svgs/landingPage/background_stars.svg";

export default function CommunitySection() {
  const discordSupportPath = import.meta.env
    .VITE_DISCORD_FREE_SUPPORT_CHANNEL_URL;

  const COMMUNITY_MEET_OTHERS = "Meet other learners in the community";
  const JOIN_DISCORD_CTA = "Join our Discord";

  return (
    <div className="self-center max-w-[1440px] relative overflow-hidden px-5 pt-10 md:pt-0 md:px-20 z-10 md:grid md:grid-cols-2">
      <BackgroundStars className="absolute right-0 left-0 m-auto -z-10" />
      <div className="flex flex-col justify-center items-center z-10 md:items-start md:pt-12 md:pb-16 lg:pt-14 lg:pb-20 xl:pt-32 xl:pb-36">
        <h3 className="text-4xl lg:text-5xl text-center font-bold text-editor-base3 md:pr-20 mb-8 md:text-left">
          {COMMUNITY_MEET_OTHERS}
        </h3>
        <Link href={discordSupportPath} asButton text={JOIN_DISCORD_CTA} />
      </div>
      <div className="flex flex-col justify-end -z-10">
        <EarthImage className="-mt-[32%] -z-10" />
      </div>
    </div>
  );
}

CommunitySection.propTypes = {};
