import React from "react";

import quickbeamLineLogo, {
  ReactComponent as QuickbeamLineLogo,
} from "../../assets/svgs/landingPage/quickbeam_line_logo.svg";
import { Link } from "..";

export default function FooterSection() {
  const SUPPORT_LINK = "Support";
  const MANIFESTO_LINK = "Manifesto";
  const ABOUT_US_LINK = "About Us";
  const LEARNING_ENVIRONMENT_LINK = "Learning Environment";
  const TEAM_LINK = "The Team";

  const lineLogoClassNames = [
    "p-0",
    "w-full",
    "animate-bg-oscillating",
    "[background:linear-gradient(230.68deg,#FBF9F3_0%,#EAC130_16.68%,#FBF9F3_32.32%,#916FEF_50.05%,#FBF9F3_67.77%,#2AE4AE_83.41%,#FBF9F3_100.1%)]",
    "[background-size:400%_400%]",
    "bg-no-repeat",
    "[mask-repeat:no-repeat]",
    "[-webkit-mask-repeat:no-repeat]",
    "[mask-position:top_left]",
    "[-webkit-mask-position:top_left]",
  ].join(" ");

  // since twcss can't seem to deal with mask-image correctly, we'll use an inline style instead
  const maskImageStyles = {
    maskImage: `url('${quickbeamLineLogo}')`,
    WebkitMaskImage: `url('${quickbeamLineLogo}')`,
  };

  const discordSupportPath = import.meta.env
    .VITE_DISCORD_FREE_SUPPORT_CHANNEL_URL;

  return (
    <footer className="flex flex-col px-5 md:px-20 pt-6 font-light">
      <div className="flex flex-col md:flex-row md:justify-between mb-10 md:mb-32 text-custom-gray-300 text-body-1-mobile">
        <ul className="md:flex md:gap-12 [&_li]:mb-1">
          <div>
            {/* 
            use when we have these pages
          <li>
            <a href="/blog">
              Blog
            </a>
          </li> */}
            <li>
              <a href="/support">{SUPPORT_LINK}</a>
            </li>
            <li>
              <a href="/manifesto">{MANIFESTO_LINK}</a>
            </li>
            <li>
              <a href="/about">{ABOUT_US_LINK}</a>
            </li>
          </div>
          <div>
            <li>
              <a href="/team">{TEAM_LINK}</a>
            </li>
            <li>
              <a href="/learning-environment">{LEARNING_ENVIRONMENT_LINK}</a>
            </li>
          </div>
        </ul>
        <div className="md:flex md:flex-col md:items-end">
          <ul className="flex gap-3 mt-8 md:mt-0">
            <Link
              href="mailto:hello@quickbeam.xyz"
              size="xlarge"
              styleType="gray"
              iconName="envelope"
              isIconOnly
              ariaLabel="email"
            />
            <Link
              href={discordSupportPath}
              size="xlarge"
              styleType="gray"
              iconName="discord"
              isIconOnly
              ariaLabel="discord"
            />
            <Link
              href="https://twitter.com/QuickbeamPBC"
              size="xlarge"
              styleType="gray"
              iconName="twitter"
              isIconOnly
              ariaLabel="twitter"
            />
          </ul>
          <p className="mt-8 md:mt-2.5 md:text-right">
            Made with ❤️ by{" "}
            <a
              className="underline cursor-pointer"
              href="https://redsquirrel.com/"
              target="_blank"
              rel="noreferrer"
            >
              Red Squirrel
            </a>{" "}
            &copy;{new Date().getFullYear()} Quickbeam
          </p>
          <div className="mt-1 md:mt-2.5">
            <a className="underline cursor-pointer" href="/privacy">
              Privacy Policy
            </a>
            {" | "}
            <a className="underline cursor-pointer" href="/terms">
              Terms & Conditions
            </a>
          </div>
        </div>
      </div>
      <QuickbeamLineLogo
        className={lineLogoClassNames}
        style={maskImageStyles}
      />
    </footer>
  );
}
