// https://github.com/altercation/solarized

import { tags as t } from "@lezer/highlight";
import { createTheme } from "@uiw/codemirror-themes";

import resolveConfig from "tailwindcss/resolveConfig";
// custom alias in the vite config
// eslint-disable-next-line import/no-unresolved
import tailwindConfig from "@tailwindConfig";

const fullConfig = resolveConfig(tailwindConfig);
const { colors } = fullConfig.theme;
const { editor } = colors || {};

const customSolarizedDark = createTheme({
  theme: "dark",
  settings: {
    background: editor.base03,
    foreground: editor.base3,
    caret: editor.base1, // aka cursor
    selection: editor.base01,
    selectionMatch: editor.base02,
    gutterBackground: editor.base03,
    gutterForeground: editor.base0,
  },
  styles: [
    { tag: t.comment, color: editor.base00 },
    { tag: t.angleBracket, color: editor.base01 },
    { tag: t.attributeName, color: editor.base01 },
    { tag: t.string, color: editor.cyan },
    { tag: t.regexp, color: "#D30102" },
    { tag: t.meta, color: editor.base00 },
    { tag: t.bool, color: editor.base00 },
    { tag: t.null, color: editor.base00 },
    { tag: t.keyword, color: editor.green },
    { tag: t.operator, color: editor.green },
    { tag: t.punctuation, color: editor.green },
    { tag: t.paren, color: editor.green },
    { tag: t.typeName, color: editor.green },
    { tag: t.atom, color: editor.magenta },
    { tag: t.bracket, color: editor.magenta },
    { tag: t.number, color: editor.magenta },
    { tag: t.tagName, color: editor.blue },
    { tag: t.variableName, color: editor.blue },
    { tag: t.className, color: editor.blue },
    { tag: t.self, color: editor.blue },
    { tag: t.definition(t.propertyName), color: editor.blue },
    { tag: t.function(t.variableName), color: editor.blue },
    { tag: t.definitionKeyword, color: editor.yellow, fontWeight: "bold" },
    { tag: t.modifier, color: editor.yellow, fontWeight: "bold" },
  ],
});

export default customSolarizedDark;
