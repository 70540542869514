import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useCodeMirror } from "@uiw/react-codemirror";
import classNames from "classnames";
import languageExtensions from "./languageExtensions";
import customSolarizedDark from "./customSolarizedDark";
import customSolarizedLight from "./customSolarizedLight";

// TODO: Dig deeper on onBlur
export default function CodeMirror({
  language,
  isReadonly,
  useCodeState,
  isDarkTheme,
  filePath,
  isOutputOpen,
  isFullScreenMode,
}) {
  const [codeValue, setCodeValue] = useCodeState;

  const editorRef = useRef();
  const cm = useCodeMirror({
    container: editorRef.current,
    extensions: [languageExtensions[language]],
    value: codeValue,
    width: "100%",
    theme: isDarkTheme ? customSolarizedDark : customSolarizedLight,
    readOnly: isReadonly,
    onChange: setCodeValue,
  });

  useEffect(
    () => editorRef.current && cm.setContainer(editorRef.current),
    [editorRef.current]
  );

  if (!language || !languageExtensions[language]) {
    return <h1>Oops! Something went wrong!</h1>;
  }

  const determineCodeMirrorHeight = () => {
    if (isOutputOpen) {
      return "h-[54vh]";
    }
    if (isFullScreenMode) {
      return "h-[94vh]";
    }
    return "h-[44vh] md:h-[95vh]";
  };

  return (
    <div
      ref={editorRef}
      className={classNames(
        "pb-[4.4rem] pl-3 overflow-y-auto w-full",
        determineCodeMirrorHeight()
      )}
      data-testid={`codeMirrorFor${filePath}`}
    />
  );
}

CodeMirror.propTypes = {
  language: PropTypes.string.isRequired,
  useCodeState: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  ).isRequired,
  isReadonly: PropTypes.bool,
  isDarkTheme: PropTypes.bool,
  filePath: PropTypes.string.isRequired,
  isOutputOpen: PropTypes.bool,
  isFullScreenMode: PropTypes.bool,
};

CodeMirror.defaultProps = {
  isReadonly: false,
  isDarkTheme: true,
  isOutputOpen: false,
  isFullScreenMode: false,
};
