import { hardcodedCourseData } from "../../hardcodedData";

const addPricingInfo = (id) => {
  if (!hardcodedCourseData[id]) {
    return null;
  }

  return hardcodedCourseData[id].pricingInfo;
};

// FIXME: Add this concept of levels from the BE or curriculum?
// defaulting to beginner for now bc that's the level we have
const getLogoBackgroundColor = (id) => {
  const level = hardcodedCourseData[id]?.level || "default";

  const availableColors = {
    beginner: "bg-gradient-green-light",
    intermediate: "bg-gradient-yellow-light",
    advanced: "bg-gradient-red-light",
    free: "bg-gradient-purple-light",
    default: "bg-gradient-purple-light",
  };

  return availableColors[level];
};

const normalizeCourseFromApi = (apiCourse) => {
  if (!apiCourse) return null;

  const {
    id,
    version,
    displayName,
    availabilityStatus,
    numLessons,
    description,
    logo,
    firstLessonId,
    currentLessonId,
    isUnlocked,
    mintTransaction,
  } = apiCourse;

  const lessonIndexesById = apiCourse.lessons
    ? Object.fromEntries(apiCourse?.lessons?.map((l, index) => [l.id, index]))
    : [];
  const currentLessonIndex = lessonIndexesById[currentLessonId] || null;

  // TODO: consolidate API presented here with the one we present from the `useLesson` hook?
  const lessons =
    apiCourse?.lessons?.map((lsn, index) => ({
      id: lsn.id,
      title: lsn.title,
      isUnlocked: index <= currentLessonIndex,
      isComplete: index < currentLessonIndex,
      paymentRequired: lsn.paymentRequired,
      prevLessonId: lsn.prevLessonId,
      ordinal: lsn.ordinal,
    })) || [];

  return {
    id,
    version,
    displayName,
    availabilityStatus,
    numLessons,
    isUnlocked,
    logo, // TODO: set a default here if it's nil?
    description,
    firstLessonId,
    mintTransaction,
    percentComplete: apiCourse.percentComplete || 0, // no progress if not logged in
    currentLessonId: currentLessonId || firstLessonId, // use first lesson as current lesson if not logged in

    logoBackgroundColor: getLogoBackgroundColor(id),
    // NOTES:
    // * Add 1 to translate between indexes and lengths
    // * Allow to be equal b/c you do not need to complete the last lesson (last lesson is minting, it's not "normal")
    // * Really this should go away, it's here for the minting page, but the minting
    //   page should instead check if that lesson is unlocked. But I don't want to
    //   do that refactoring right now b/c it probably implies that we should merge
    //   the different representations of our lessons and use a single lesson model.
    areNormalLessonsComplete:
      apiCourse?.lessons?.length <= currentLessonIndex + 1,

    // FIXME: This is all just very hardcoded and should be very temporary
    // bc this needs to move to the BE
    ...addPricingInfo(id),

    getLesson: (lessonId) => lessons[lessonIndexesById[lessonId]],
    lessons,
  };
};

export { addPricingInfo, getLogoBackgroundColor, normalizeCourseFromApi };
