/* eslint-disable import/prefer-default-export */

export const BASE_OAUTH_CONFIG = {
  client_id: "OVERRIDE-THIS-VALUE",
  redirect_uri: "OVERRIDE-THIS-VALUE",
  scope: "OVERRIDE-THIS-VALUE",
  state: "OVERRIDE-THIS-VALUE",
  response_type: "code",
  access_type: "offline",
  prompt: "consent",
};

Object.freeze(BASE_OAUTH_CONFIG);
