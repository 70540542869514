import React from "react";
import PropTypes from "prop-types";
import backgroundLeaves from "../../assets/pngs/backgroundLeaves.png";

export default function LeavesBackground({ children }) {
  return (
    <div className="flex flex-col dark h-screen">
      <img
        className="m-0 m-auto [mix-blend-mode:screen] hidden md:flex md:fixed top-0 left-0 w-1/2 opacity-20"
        src={backgroundLeaves}
        alt="leafy background for quickbeam logo"
      />
      <img
        className="m-0 m-auto [mix-blend-mode:screen] hidden md:flex md:fixed top-0 right-0 w-1/2 -scale-x-100 opacity-20"
        src={backgroundLeaves}
        alt="leafy background for quickbeam logo"
      />
      {children}
    </div>
  );
}

LeavesBackground.propTypes = {
  children: PropTypes.any,
};
