import React, { useRef, useEffect } from "react";
import classNames from "classnames";
import { Widget, Button, GradientBorder, Icon, WidgetAction } from "../index";
import { mint } from "../../context";

export default function MintWidget() {
  const {
    accounts,
    baseTransactionViewerUrl,
    isMinting,
    loading,
    error,
    transaction,
    mintToken,
    course,
    hasCompletedAllLessons,
    isMintSuccessful,
    getReceipt,
  } = mint() || {};

  const { logo, displayName, mintTransaction } = course || {};

  const TITLE = "Course Completion Token";
  const CONNECT_WARNING = "Connect wallet to mint token";
  const VIEW_TRANSACTION = "View Transaction";
  const TOKEN_MINTED = "Token Minted";
  const MINTING_COMING_SOON = "Token minting coming soon!";
  const LESSONS_NOT_COMPLETED = "Complete the course to mint your token!";
  const MINT_YOUR_TOKEN = "Mint Your Token";
  const MINT_IN_PROGRESS = "Minting in Progress";
  const MINT_LOADING_WARNING =
    "Please don't refresh or leave the page while transaction is being submitted. The link to the transaction will be viewable.";

  const svgRef = useRef();
  // FIXME:
  // Temp hack for the solidity logo, it's stored in the BE as a black logo, and we can
  // target it with the below until we update the logo (and eventually have a light/dark mode logo)
  const svgGChildClass = "[&_svg>g]:fill-white";

  useEffect(() => {
    if (logo) {
      svgRef.current.innerHTML = logo;
    }
  }, []);

  useEffect(() => {
    if (mintTransaction?.transactionHash) {
      getReceipt(mintTransaction.transactionHash);
    }
  }, [mintTransaction?.transactionHash]);

  const sharedClasses = "text-[12px] leading-4 text-custom-gray-200";
  const isFlagged = () =>
    !import.meta.env.VITE_FEATURE_FLAG_MINTING ||
    import.meta.env.VITE_FEATURE_FLAG_MINTING === "true";

  const transactionLink = () => {
    const txn = transaction || mintTransaction?.transactionHash;
    return (
      <WidgetAction
        href={`${baseTransactionViewerUrl}${txn}`}
        testid="transaction"
        iconName="linkTo"
        isIconFirst={false}
        text={VIEW_TRANSACTION}
        isLink
      />
    );
  };

  const widgetState = () => {
    // This likely means the transaction was not successful, and we need to investigate.
    // The transaction explorer should give more details like invalid authorization or non unique token uri.s
    if (
      isMintSuccessful !== undefined &&
      !isMintSuccessful &&
      (mintTransaction?.transactionHash || transaction)
    ) {
      return (
        <>
          <p className={sharedClasses} data-testid="mintError">
            Something went wrong during the minting process. Please contact us
            with your transaction details.
          </p>
          {transactionLink()}
        </>
      );
    }

    if (error) {
      if (import.meta.env.DEV) console.error(error);
      let text = "An error occured.";

      // when the user rejects the transaction
      if (error?.code === 4001)
        text = "The transaction was rejected in MetaMask.";

      // A guard in the case of a request already being resolved. (Should be disabled, but a good check.)
      if (error?.code === -32002) text = "An error occured. Check MetaMask.";

      return (
        <p className={sharedClasses} data-testid="mintError">
          {text}
        </p>
      );
    }

    if (isMintSuccessful) {
      return (
        <>
          <GradientBorder
            gradientBorderClass="bg-gradient-green-light"
            backgroundColor="bg-editor-base03"
          >
            <Icon iconName="check" fill="fill-primary-green" />
            <h4 className="font-bold text-[16px]" data-testid="minted">
              {TOKEN_MINTED}
            </h4>
          </GradientBorder>
          {transactionLink()}
        </>
      );
    }

    if (!accounts?.at(0) && !isFlagged()) {
      return (
        <GradientBorder
          gradientBorderClass="bg-gradient-yellow-light"
          backgroundColor="bg-editor-base03"
        >
          <Icon iconName="warning" fill="fill-primary-yellow" />
          <h4 className="text-[16]" data-testid="connectWarning">
            {CONNECT_WARNING}
          </h4>
        </GradientBorder>
      );
    }

    return (
      <>
        <Button
          onClick={() => mintToken()}
          isDisabled={
            accounts?.length === 0 ||
            !hasCompletedAllLessons ||
            isFlagged() ||
            isMinting ||
            loading ||
            mintTransaction?.transactionHash // added catch. it should resolve in a txn receipt, and we show error if not or success, but just in case
          }
          isMinting={isMinting || false}
          label={!isMinting ? MINT_YOUR_TOKEN : MINT_IN_PROGRESS}
          iconName="coin"
          isFullWidth
          testId="mintSubmit"
        />
        {isFlagged() && (
          <p
            className={classNames("italic", sharedClasses)}
            data-testid="mintingComingSoon"
          >
            {MINTING_COMING_SOON}
          </p>
        )}
        {!hasCompletedAllLessons && (
          <p
            className={classNames("italic", sharedClasses)}
            data-testid="lessonsNotCompleted"
          >
            {LESSONS_NOT_COMPLETED}
          </p>
        )}
        {isMinting && !transaction && (
          <p className={sharedClasses} data-testid="mintLoadingMessage">
            {MINT_LOADING_WARNING}
          </p>
        )}
        {transaction && transactionLink()}
      </>
    );
  };

  return (
    <Widget title={TITLE} testid="mintWidget">
      <div className="flex gap-2 items-center">
        {logo && (
          <span
            ref={svgRef}
            id="courseLogo"
            className={classNames("[&_svg]:h-6", svgGChildClass)}
          />
        )}
        <h3 className="text-headlines-h3">{displayName}</h3>
      </div>
      {widgetState()}
    </Widget>
  );
}
