import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { theme } from "../../context";
import { GradientBorder } from "../index";

export default function Widget({
  title,
  status,
  children,
  testid,
  backgroundColor,
  borderColor,
  size,
}) {
  const isDarkMode = theme().at(0) === "dark";

  return (
    <GradientBorder
      gradientBorderClass={borderColor}
      rounded="rounded-lg"
      backgroundColor={
        backgroundColor ||
        (isDarkMode ? "dark bg-editor-base03" : "light bg-editor-base3")
      }
      additionalClassesForOuter={classNames(
        "w-full min-w-[370px] max-w-[840px]",
        { "w-1/2": size === "default" }
      )}
      additionalClassesForInner="p-4 flex-col"
      testid={testid}
    >
      {title && (
        <div className="flex items-center gap-1.5">
          {status}
          <h5 className="uppercase text-headlines-h5">{title}</h5>
        </div>
      )}
      {children}
    </GradientBorder>
  );
}

Widget.propTypes = {
  title: PropTypes.string,
  status: PropTypes.any,
  children: PropTypes.any,
  testid: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  size: PropTypes.oneOf(["default", "large"]),
};

Widget.defaultProps = {
  testid: "widget",
  size: "default",
};
