import React, { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import {
  WalletContext,
  NavigatorContext,
  ThemeContext,
  AuthContext,
  theme,
} from "./context";
import AllRoutes from "./routes";
import apolloClient from "./apollo";

function App() {
  const isDarkMode = theme()?.at(0) === "dark" || true;
  useEffect(() => {
    // FIXME: Not all pages support the light theme yet, so force it to dark if the theme is undefined.
    const themeClass = isDarkMode ? "dark" : "light";
    document.body.className = themeClass;
  }, [isDarkMode]);

  const router = createBrowserRouter(AllRoutes);

  return (
    <ApolloProvider client={apolloClient}>
      <AuthContext>
        <NavigatorContext>
          <ThemeContext>
            <WalletContext>
              <RouterProvider router={router} />
            </WalletContext>
          </ThemeContext>
        </NavigatorContext>
      </AuthContext>
    </ApolloProvider>
  );
}

export default App;
