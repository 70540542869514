/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React, { useEffect, useMemo } from "react";
import { Outlet, Navigate, useParams, useLocation } from "react-router-dom";
import {
  AboutUsPage,
  CoursesPage,
  CourseDetailsPage,
  HomePage,
  MintPage,
  ErrorPage,
  ProfilePage,
  LessonPage,
  LegalPage,
  MarketingPage,
  Login,
  OAuthCallback,
  ArticlePage,
  PaymentPage,
  WalletPage,
  NftPage,
  DiscordLinkedRoleCallback,
  DiscordLinkedRole,
  AuthenticatedHomePage,
  CurrentCourseRedirectPage,
  ResourcesPage,
  SupportMarketingPage,
  TeamPage,
} from "../pages";
import { CourseContext, DeepDiveContext } from "../context";
import { useCourse } from "../hooks";
import { LeavesBackground, Header, SidePanel } from "../components";
import { OAUTH_PROVIDER_GOOGLE_NAME } from "../utils/auth/oauthConfigs";
import analytics from "../utils/analyticsTracker";

function AuthLayout() {
  return (
    <LeavesBackground>
      {/* FIXME: Should header be fixed at the top? */}
      <Header isDarkMode logoOnly />
      <Outlet id="outlet" />
    </LeavesBackground>
  );
}

function SidePanelLayout() {
  const deepDive = SidePanel();
  return (
    <DeepDiveContext value={deepDive}>
      <deepDive.component />
      <Outlet id="outlet" />
    </DeepDiveContext>
  );
}

function CourseChildrenLayout() {
  const params = useParams();
  const location = useLocation();
  const deepDive = SidePanel();

  const courseId = params?.courseId || location?.state?.courseId;

  const { course, isLoading, isError } = useCourse(courseId, "0.0.1");
  const { isUnlocked: isCourseUnlocked } = course || false;

  const lessonId = params?.lessonId || location?.state?.lessonId;
  // TODO: paymentRequired is being fixed to also be on all...
  const { isUnlocked: isLessonUnlocked, paymentRequired } = course?.getLesson(
    lessonId
  ) || {
    isUnlocked: false,
    paymentRequired: true,
  };

  if (course) {
    // if the course is not paid and payment is required
    if (
      !isCourseUnlocked &&
      paymentRequired &&
      !window.location.pathname.includes("/payment") &&
      !window.location.pathname.includes("/current")
    ) {
      return (
        <Navigate
          to={`/course/${course.id}/payment`}
          // currentLessonId should match params.lessonId, but it might not
          state={{ currentLessonId: lessonId }}
          replace
        />
      );
    }

    // if the lesson is not unlocked, go last unlocked (current) lesson
    if (!!lessonId && !isLessonUnlocked) {
      return <Navigate to={`lesson/${course?.currentLessonId}`} replace />;
    }
  }

  return (
    // TODO: We can remove the hooks and use data loaders in the future,
    // or we can do a hybrid like this.
    // https://reactrouter.com/en/main/route/loader#returning-responses
    <CourseContext currentCourse={{ course, isLoading, isError }}>
      <DeepDiveContext value={deepDive}>
        <deepDive.component />
        <Outlet id="outlet" />
      </DeepDiveContext>
    </CourseContext>
  );
}

function checkAuthentication() {
  // By moving this "here" to the routes, we can continue refactoring so a user can't go to lessons they haven't unlocked yet.
  // we might need to move this out higher so we can use it in the data loaders for the page
  const authToken = localStorage.getItem("accTok");

  const getUser = () => {
    if (!authToken) return null;

    const parsedToken = JSON.parse(window.atob(authToken.split(".")[1]));
    return {
      id: parsedToken.id,
      email: parsedToken.email,
      fullName: parsedToken.full_name,
      preferredName: parsedToken.full_name, // We don't yet have a preferred name, but we want this to be the interface, even if the value isn't yet exactly what it should be
    };
  };

  const user = getUser();
  useMemo(() => {
    if (user?.email !== undefined) {
      analytics.setUserEmail(user.email);
    }
  }, [user?.email]);

  return !!user;
}

function AuthenticatedHomeRedirect({ children }) {
  const isAuthenticated = checkAuthentication();

  if (!isAuthenticated) {
    return children ?? <Outlet />;
  }

  return <Navigate to="/home" replace />;
}

function ProtectedRoutesRedirect({ children }) {
  const isAuthenticated = checkAuthentication();

  if (isAuthenticated) {
    return children ?? <Outlet />;
  }

  return (
    <Navigate
      to="/login"
      state={{
        postLoginPath:
          window.location.pathname +
          window.location.search +
          window.location.hash,
      }}
      replace
    />
  );
}

function TrackPageVisits() {
  const { host, pathname } = useLocation();

  useEffect(() => {
    analytics.visitRoute(host, pathname);
  }, [host, pathname]);

  return <Outlet id="outlet" />;
}

const UnprotectedRoutes = [
  {
    path: "/",
    children: [
      {
        element: <AuthenticatedHomeRedirect />,
        children: [
          {
            index: true,
            element: <HomePage />,
          },
        ],
      },
      {
        element: <SidePanelLayout />,
        children: [
          { path: "/manifesto", element: <MarketingPage type="manifesto" /> },
          {
            path: "/learning-environment",
            element: <MarketingPage type="learningEnvironment" />,
          },
          { path: "/privacy", element: <LegalPage type="privacy" /> },
          { path: "/terms", element: <LegalPage type="terms" /> },
          { path: "/cancel", element: <LegalPage type="cancel" /> },
          { path: "/CCPA-form", element: <LegalPage type="ccpa" /> },
          { path: "/GDPR", element: <LegalPage type="gdpr" /> },
          {
            path: "/scholarships",
            element: <MarketingPage type="scholarships" />,
          },
          { path: "/support", element: <SupportMarketingPage /> },
          {
            path: "/resources",
            children: [
              { index: true, element: <ResourcesPage type="articles" /> },
              { path: "articles", element: <ResourcesPage type="articles" /> },
              { path: "glossary", element: <ResourcesPage type="glossary" /> },
              // { path: "articles/:tag", element: <ResourcesPage type="articles" /> }, // TODO: filtered by tag
              { path: "articles/:tag/:topic", element: <ArticlePage /> },
            ],
          },
          { path: "/articles", element: <Navigate to="/resources/articles" /> },
          { path: "/glossary", element: <Navigate to="/resources/glossary" /> },
          {
            path: "/wallets/:provider",
            element: <CourseChildrenLayout />,
            children: [{ index: true, element: <WalletPage /> }],
          },
        ],
      },
      {
        path: "/courses",
        element: <CoursesPage />,
      },
      { path: "/about", element: <AboutUsPage /> },
      { path: "/team", element: <TeamPage /> },
      {
        path: "/course/:courseId/details",
        element: <CourseDetailsPage />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthLayout />,
    // loader: defer({ getUser }), this is a data loader where we can check for the user and give that context to the page
    children: [
      { path: "/signup", index: true, element: <Login isSignUp /> },
      { path: "/login", index: true, element: <Login /> },
    ],
  },
  {
    path: "/oauth/callback",
    element: <OAuthCallback provider={OAUTH_PROVIDER_GOOGLE_NAME} />,
  },
  {
    path: "/nfts/:courseId",
    element: <NftPage />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
];

const ProtectedRoutes = [
  {
    path: "/",
    element: <ProtectedRoutesRedirect />,
    children: [
      {
        path: "/home",
        children: [
          {
            index: true,
            element: <AuthenticatedHomePage />,
          },
        ],
      },
      {
        path: "/course/:courseId",
        element: <CourseChildrenLayout />,
        children: [
          {
            path: "lesson/current",
            element: <CurrentCourseRedirectPage />,
          },
          { path: "mint", element: <MintPage /> },
          { path: "wallets/:provider", element: <WalletPage /> },
          {
            path: "lesson/:lessonId",
            element: <LessonPage />,
          },
          // FIXME: All of these should have :courseId
          { path: "payment", element: <PaymentPage /> },
          { path: "payment/make", element: <PaymentPage.ToStripe /> },
          { path: "payment/made", element: <PaymentPage.FromStripe /> },
        ],
      },
      {
        path: "/profile",
        element: <AuthLayout />,
        children: [{ index: true, element: <ProfilePage /> }],
      },
      {
        path: "/discord/",
        element: <AuthLayout />,
        children: [
          { path: "linked-role", element: <DiscordLinkedRole /> },
          { path: "oauth-callback", element: <DiscordLinkedRoleCallback /> }, // path probably should have been "linked-role-oauth-callback"
          // { path: "login-oauth-callback", element: <OAuthCallback provider={OAUTH_PROVIDER_DISCORD_NAME} /> }, // if ever supporting Discord OAuth Login
        ],
      },
    ],
  },
];

const AllRoutes = [
  {
    path: "/",
    element: <TrackPageVisits />,
    children: UnprotectedRoutes.concat(ProtectedRoutes),
  },
];

export default AllRoutes;
