import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Icon, Markdown } from "../index";
import { theme } from "../../context";

export default function Hint({ message }) {
  const currentTheme = theme()[0];
  const isDarkTheme = currentTheme === "dark";
  const themeTextColor = isDarkTheme
    ? "text-custom-white"
    : "text-secondary-purple-500";
  const themeBackgroundColor = isDarkTheme
    ? "bg-secondary-purple-400"
    : "bg-secondary-purple-100";
  const themeBorderColor = isDarkTheme
    ? "border-secondary-purple-100"
    : "border-secondary-purple-400";
  const iconFillColor = isDarkTheme ? "fill-white" : "fill-purple-300";

  return (
    <div
      className={classNames(
        "flex flex-row items-center mx-2 my-4 p-4 rounded-sm border gap-2",
        themeBackgroundColor,
        themeBorderColor,
        themeTextColor
      )}
    >
      <Icon fill={iconFillColor} iconName="leaf" />
      <p className="font-bold">Hint:</p>
      <Markdown content={message} elementStyles={{ p: [themeTextColor] }} />
    </div>
  );
}

Hint.propTypes = {
  message: PropTypes.string,
};
