// https://github.com/altercation/solarized
// https://github.com/altercation/solarized/blob/master/textmate-colors-solarized/Solarized%20(light).tmTheme

import { tags as t } from "@lezer/highlight";
import { createTheme } from "@uiw/codemirror-themes";

import resolveConfig from "tailwindcss/resolveConfig";
// custom alias in the vite config
// eslint-disable-next-line import/no-unresolved
import tailwindConfig from "@tailwindConfig";

const fullConfig = resolveConfig(tailwindConfig);
const { colors } = fullConfig.theme;
const { editor } = colors || {};

const customSolarizedLight = createTheme({
  theme: "light",
  settings: {
    background: editor.base3,
    foreground: editor.base01,
    caret: editor.base03,
    selection: editor.base02,
    selectionMatch: editor.base02,
    gutterBackground: editor.base3,
    gutterForeground: editor.base0,
  },
  styles: [
    {
      tag: [t.comment, t.angleBracket, t.attributeName],
      color: editor.base1,
    },
    { tag: t.string, color: editor.cyan },
    { tag: t.regexp, color: "#D30102" },
    { tag: [t.meta, t.bool, t.null], color: editor.yellow },
    {
      tag: [t.operator, t.keyword, t.punctuation, t.typeName],
      color: editor.green,
    },
    { tag: [t.atom, t.bracket, t.number], color: editor.magenta },
    {
      tag: [
        t.tagName,
        t.variableName,
        t.className,
        t.self,
        t.definition(t.propertyName),
        t.function(t.variableName),
      ],
      color: editor.blue,
    },
    {
      tag: [t.definitionKeyword, t.modifier],
      color: editor.yellow,
      fontWeight: "bold",
    },
  ],
});

export default customSolarizedLight;
