/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import classNames from "classnames";
import { theme } from "../../context";
import { Icon, Markdown } from "../index";

export default function CheckBoxInput({
  label,
  name,
  isCorrectAnswer,
  value,
  isDisabled,
}) {
  const [field, meta] = useField({ name, value, type: "checkbox" });
  const isDarkMode = theme()[0] === "dark";

  const selectedValues = meta.value;

  const isSelectedValue = selectedValues?.includes(field.value.toString());

  const themeOrFeedbackStyles = () => {
    if (isSelectedValue) {
      return isCorrectAnswer
        ? "border-primary-green bg-secondary-green-500 text-custom-white"
        : "border-secondary-red-400 bg-custom-gray-400 text-secondary-red-100";
    }

    return isDarkMode
      ? "dark editor border-editor-base0 text-custom-white"
      : "light editor";
  };

  const getIcon = (isCorrect) => {
    if (isSelectedValue) {
      return (
        <Icon
          iconName={isCorrect ? "check" : "close"}
          fill={isCorrect ? "fill-primary-green" : "fill-secondary-red-100"}
          ariabLabel={isCorrect ? "success" : "try again!"}
        />
      );
    }
    return null;
  };

  return (
    <>
      <label
        className={classNames(
          "relative rounded-sm border w-full px-4 py-3.5 flex justify-between",
          themeOrFeedbackStyles()
        )}
        htmlFor={name}
      >
        {label && (
          <Markdown
            content={label}
            elementStyles={{ p: [themeOrFeedbackStyles()] }}
          />
        )}
        <input
          type="checkbox"
          name={name}
          value={value}
          disabled={isDisabled}
          {...field}
          className="w-full h-full absolute top-0 left-0 z-10 opacity-0"
        />
        {getIcon(isCorrectAnswer)}
      </label>
      {meta?.touched && meta?.error && (
        <p className="text-primary-red">{meta?.error}</p>
      )}
    </>
  );
}

CheckBoxInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  isCorrectAnswer: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
