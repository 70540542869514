import React from "react";
import PropTypes from "prop-types";
import images from "./availableImages";

export default function Image({ name, alt, additionalClasses }) {
  const image = images[name];
  return (
    <img
      src={image.x2.img}
      srcSet={`${image.x1.img} ${image.x1.sizeW}, ${image.x2.img} ${image.x2.sizeW}`}
      sizes={`(max-width: 768px) ${image.x1.sizePx}, ${image.x2.sizePx}`}
      alt={alt || image.defaultAlt}
      className={additionalClasses}
    />
  );
}

Image.propTypes = {
  name: PropTypes.oneOf(Object.keys(images)).isRequired,
  alt: PropTypes.string,
  additionalClasses: PropTypes.string,
};
