import React from "react";
import PropTypes from "prop-types";
import { ApolloError } from "@apollo/client";

// A component to easily change for now
export default function Error({ message, errorDetails }) {
  if (import.meta.env.DEV) {
    console.error("error:", errorDetails);
    console.error("network:", errorDetails?.networkError);
    console.error("graphql:", errorDetails?.graphQLErrors);
  }

  return <h5>{message}</h5>;
}

Error.propTypes = {
  message: PropTypes.string,
  errorDetails: PropTypes.instanceOf(ApolloError),
};

Error.defaultProps = {
  message: "Oops!",
  errorDetails: null,
};
