import { OAUTH_PROVIDERS, OAUTH_PROVIDER_DISCORD_NAME } from "./oauthConfigs";
import { OAuthStateManager } from "./sessionStateUtil";

const redirectToOAuthProvider = (oauthUrl, oauthParams) => {
  const internalRedirectUrl = oauthParams.redirect_uri;
  const oauthState = OAuthStateManager.initiateFor(internalRedirectUrl);

  if (import.meta.env.VITE_USE_MOCK_SERVER === "true") {
    const mockedUrl = new URL(internalRedirectUrl);
    mockedUrl.search = new URLSearchParams({
      code: "mockCode",
      state: oauthState,
    });
    window.location.assign(mockedUrl);
    return;
  }

  const authProviderUrl = new URL(oauthUrl);
  authProviderUrl.search = new URLSearchParams({
    ...oauthParams,
    state: oauthState,
  });

  window.location.replace(authProviderUrl);
};

export const redirectToOAuthProviderForLogin = (
  oauthProvider,
  { postLoginPath }
) => {
  OAuthStateManager.pushPostLoginPath(postLoginPath);
  const oauthConfig = OAUTH_PROVIDERS[oauthProvider];

  redirectToOAuthProvider(oauthConfig.url, oauthConfig.loginConfig);
};

export const redirectToDiscordForLinkedRoles = () => {
  const oauthConfig = OAUTH_PROVIDERS[OAUTH_PROVIDER_DISCORD_NAME];

  redirectToOAuthProvider(oauthConfig.url, oauthConfig.linkedRolesConfig);
};
