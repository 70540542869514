import React from "react";
import { Header, FooterSection } from "../../components/index";
import HeroSection from "./HeroSection";
import FeatureItemsSection from "./FeatureItemsSection";
import CommunitySection from "./CommunitySection";
import MentorAndCourseSection from "./MentorAndCourseSection";
import TeamsSection from "./TeamsSection";
import TaglineSection from "./TaglineSection";
import { useCourses } from "../../hooks";

export default function Homepage() {
  const { loading, error, courses } = useCourses();

  return (
    <div className="flex flex-col">
      <div className="bg-gradient-rad-green-dark flex justify-center">
        <div className="w-full max-w-[1440px] flex flex-col px-5 md:px-20 pt-4 md:pt-10 justify-center relative overflow-x-clip">
          <Header
            isDarkMode
            headerStyle="min-h-0 lg:min-h-0 px-0 lg:px-0"
            noSidePadding
            noTopPadding
          />
          <HeroSection />
        </div>
      </div>

      <TaglineSection />
      <div className="bg-gradient-purple-light flex justify-center">
        <MentorAndCourseSection
          courses={courses}
          isLoading={loading}
          isError={error}
        />
      </div>
      <FeatureItemsSection />
      <TeamsSection />

      <div className="bg-gradient-purple-dark flex justify-center">
        <CommunitySection />
      </div>

      <div className="bg-custom-green-dark flex justify-center">
        <div className="w-full max-w-[1440px]">
          <FooterSection />
        </div>
      </div>
    </div>
  );
}

Homepage.propTypes = {};
