/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import CodeMirror from "@uiw/react-codemirror";
import classNames from "classnames";

import languageExtensions from "../codeMirror/languageExtensions";
import customSolarizedDark from "../codeMirror/customSolarizedDark";
import customSolarizedLight from "../codeMirror/customSolarizedLight";
import { theme } from "../../context";

export default function Code({
  inline,
  className,
  children,
  additionalClasses,
  ...props
}) {
  const languageMatch = /language-(\w+)/.exec(className || "");
  const isDarkMode = theme()[0] === "dark";

  if (additionalClasses === "stdout") {
    return (
      <code
        className={classNames(
          isDarkMode ? "text-editor-base00" : "text-editor-base01"
        )}
      >
        {children}
      </code>
    );
  }

  if (additionalClasses === "stderr") {
    return <code className="text-primary-red">{children}</code>;
  }

  if (!inline && languageMatch)
    return (
      <CodeMirror
        value={String(children).replace(/\n$/, "")}
        extensions={[languageExtensions[languageMatch[1].toLowerCase()] || []]}
        theme={isDarkMode ? customSolarizedDark : customSolarizedLight}
        autoFocus={false}
        editable={false}
        basicSetup={{
          // https://github.com/uiwjs/react-codemirror/tree/v4.13.2#props
          lineNumbers: false,
          highlightActiveLineGutter: false,
          foldGutter: false,
          highlightActiveLine: false,
          highlightSelectionMatches: true,
        }}
      />
    );

  if (!inline)
    return (
      <code className={className} {...props}>
        {children}
      </code>
    );

  return (
    <span
      className={classNames(
        "not-prose px-1 pb-1 rounded",
        isDarkMode
          ? "bg-editor-base03 text-white"
          : "bg-editor-base2 text-editor-base01"
      )}
      aria-label="code background"
    >
      <code>{children}</code>
    </span>
  );
}

Code.propTypes = {
  // inline: true for `some code`, false for codefences
  inline: PropTypes.bool,
  // className: set to language-js or so when using ```js
  className: PropTypes.string,
  children: PropTypes.object,
  additionalClasses: PropTypes.string,
  // props: probably empty, IDK
  props: PropTypes.object,
};
