import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { useQuery, useLazyQuery } from "@apollo/client";
import { wallet } from "./index";
import { GET_MINT_VALUES, GET_MINT_AUTHORIZATION } from "../queries";

export const Context = createContext();

export function MintingContext({ children, course }) {
  const [error, setError] = useState();
  const {
    accounts,
    mintToEth,
    transaction,
    isMinting,
    isMintSuccessful,
    error: walletError,
    getReceipt,
  } = wallet() || {};

  const {
    loading: mintValuesLoading,
    data: mintValuesData,
    error: mintValuesError,
  } = useQuery(GET_MINT_VALUES, {
    // FIXME: version is currently required.
    variables: { courseId: course.id },
  });

  const recipientAddress = accounts[0];

  const [
    getAuthorization,
    { loading: mintAuthLoading, data: mintAuthData, error: mintAuthError },
  ] = useLazyQuery(GET_MINT_AUTHORIZATION);

  useEffect(() => {
    // Don't try to authorize a token if we get an error trying to get the details needed to create a transaction anyway.
    if (
      recipientAddress &&
      course.id &&
      mintValuesError?.message !== "Unprocessable"
    ) {
      getAuthorization({
        variables: { recipientAddress, courseId: course.id },
      });
    }
  }, [recipientAddress, course.id]);

  const mintToken = () => {
    const { authorization, validUntilTimestamp, tokenUri } =
      mintAuthData.authorizeMinting;

    const timestampInSeconds = Math.floor(new Date(validUntilTimestamp) / 1000);

    const { contractAddress, protocol } = mintValuesData.mintValues;

    if (protocol === "Ethereum") {
      return mintToEth(
        recipientAddress,
        tokenUri,
        authorization,
        timestampInSeconds,
        contractAddress,
        course.id
      );
    }

    return setError("Minting protocol doesn't exist yet!");
  };

  const values = useMemo(
    () => ({
      accounts,
      baseTransactionViewerUrl:
        mintValuesData?.mintValues?.baseTransactionViewerUrl,
      isMinting,
      error: walletError || mintValuesError || mintAuthError || error,
      loading: mintValuesLoading || mintAuthLoading,
      transaction,
      mintToken,
      course,
      hasCompletedAllLessons: course.areNormalLessonsComplete,
      isMintSuccessful,
      getReceipt,
    }),
    [error, mintToken]
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
}

export default function mint() {
  const context = useContext(Context);
  if (!Context && context === undefined) {
    throw new Error("Mint must be used within a Provider");
  }
  return context;
}

MintingContext.propTypes = {
  children: PropTypes.any.isRequired,
  course: PropTypes.object,
};
