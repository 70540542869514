import React from "react";
import Confetti from "react-confetti";
import PropTypes from "prop-types";
import resolveConfig from "tailwindcss/resolveConfig";
// custom alias in the vite config
// eslint-disable-next-line import/no-unresolved
import tailwindConfig from "@tailwindConfig";

export default function CustomConfetti({ height, width }) {
  const fullConfig = resolveConfig(tailwindConfig);

  const { colors } = fullConfig.theme;

  const { primary } = colors || {};

  const confettiColors = [primary.green, primary.yellow, primary.purple];

  // Note: This library doesn't automatically resize the confetti.
  return (
    <>
      <Confetti
        numberOfPieces={60}
        wind={0.004}
        colors={confettiColors}
        width={width}
        height={height}
        drawShape={(ctx) => {
          ctx.beginPath();
          ctx.arc(3, 3, 2, 0, Math.PI * 2, true);
          ctx.fill();
          ctx.stroke();
          ctx.closePath();
        }}
      />
      <Confetti
        numberOfPieces={60}
        wind={0.004}
        colors={confettiColors}
        width={width}
        height={height}
        drawShape={(ctx) => {
          ctx.beginPath();
          ctx.arc(6, 6, 4, 0, Math.PI * 2, true);
          ctx.fill();
          ctx.stroke();
          ctx.closePath();
        }}
      />
    </>
  );
}

CustomConfetti.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

CustomConfetti.defaultProps = {
  height: window.innerHeight,
  width: window.innerWidth,
};
