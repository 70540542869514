import React from "react";
import PropTypes from "prop-types";
import { Articles, Glossary, Link, StaticPageLayout } from "../../components";

export default function ResourcesPage({ type }) {
  const isArticles = type === "articles";

  const resources = {
    articles: <Articles />,
    glossary: <Glossary />,
  };

  const RESOURCES = "Resources";
  const RESOURCES_SUBTITLE =
    "Our courses feature deep dives and definitions to help our learners understand important technical concepts and lingo while staying in flow. While the core course content mostly sits behind a paywall, we share these resources publicly.";
  const ARTICLES = "Articles";
  const GLOSSARY = "Glossary";

  return (
    <StaticPageLayout
      title={RESOURCES}
      testid={`resources-${type}`}
      mentorOption="lunaWelcoming"
    >
      <h3 className="text-headlines-h3 text-custom-green-slate w-full lg:w-1/2">
        {RESOURCES_SUBTITLE}
      </h3>
      <hr className="divide-y-custom-gray-400 border-custom-gray-400 my-10 w-1/2" />
      <div className="flex gap-6">
        <Link
          href="/resources/articles"
          text={ARTICLES}
          styleType={isArticles ? "primary" : "outline"}
          testid="articlesButton"
          asButton
        />
        <Link
          href="/resources/glossary"
          text={GLOSSARY}
          styleType={isArticles ? "outline" : "primary"}
          testid="glossaryButton"
          asButton
        />
      </div>
      {resources[type]}
    </StaticPageLayout>
  );
}

ResourcesPage.propTypes = {
  type: PropTypes.oneOf(["articles", "glossary"]).isRequired,
};
