import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import apolloClient from "./apollo";
import analytics from "./utils/analyticsTracker";
import App from "./App";
import "./index.css";

// eslint-disable-next-line no-extend-native
Array.prototype.at =
  Array.prototype.at ||
  function (index) {
    return this[index];
  };

const mode = import.meta.env.MODE;
const initSentry = () =>
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_LATEST_GIT_SHA,
    environment: mode,
    tracesSampleRate: 1.0,
  });

if (mode === "production") {
  analytics.enable();
  initSentry();
} else if (mode === "staging") {
  initSentry();
  analytics.disable();
  analytics.verbose(true);
} else if (mode === "development") {
  analytics.disable();
  analytics.verbose(true);
}

analytics.configureBackendClient(apolloClient);

if (import.meta.env.VITE_AMPLITUDE_ANALYTICS_API_KEY) {
  analytics.configureAmplitude(
    import.meta.env.VITE_AMPLITUDE_ANALYTICS_API_KEY
  );
}

// GTM track page load
window.dataLayer.push({ event: "pageView" });

ReactDOM.createRoot(document.getElementById("root")).render(<App />);
