import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { GET_LESSON } from "../queries";
import normalizeLesson from "./helpers/lessonHelpers";

export default function useLesson(
  lessonId,
  version,
  onCompletedCallback,
  userId
) {
  const variables = { id: lessonId, version };
  const {
    loading: isLoading,
    data,
    error,
  } = useQuery(GET_LESSON, {
    variables,
    onCompleted: (d) => onCompletedCallback(d?.lesson),
  });

  const lesson = useMemo(
    () => normalizeLesson(data?.lesson, userId),
    [data?.lesson, userId]
  );

  // should this memoize the lesson value, and only calculate it if the user id is present?
  // userid is weird: it only matters is you have code files you need to store in localstorage,
  // which only happens on editor sections. So we don't necessarily need it
  return { isLoading, error, lesson };
}
