import { useMemo } from "react";
import { useQuery } from "@apollo/client";

import {
  GET_COURSE_AUTHENTICATED,
  GET_COURSE_UNAUTHENTICATED,
} from "../queries";

import { authentication } from "../context";
import { normalizeCourseFromApi } from "./helpers/courseHelpers";

export default function useCourse(id, version) {
  const { isAuthenticated } = authentication() || {};
  const query = isAuthenticated
    ? GET_COURSE_AUTHENTICATED
    : GET_COURSE_UNAUTHENTICATED;

  const { loading, data, error } = useQuery(query, {
    variables: { id, version },
  });

  const course = useMemo(
    () => normalizeCourseFromApi(data?.course),
    [data?.course]
  );

  return { isLoading: loading, error, course };
}
