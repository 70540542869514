import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CourseWidget, Loading, Error } from "../index";

export default function CourseList({
  courses,
  isLoading,
  isError,
  additionalClasses,
}) {
  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div
      className={classNames("flex flex-col items-center", additionalClasses)}
      data-testid="courseList"
    >
      {courses?.map((courseItem) => (
        <CourseWidget key={courseItem.id} course={courseItem} />
      ))}
    </div>
  );
}

CourseList.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object),
  // Note: Careful! Any classes already defined with tailwind will not necessarily be overridden
  // this is mostly meant to allow flexibility to add positioning without adding extra divs
  additionalClasses: PropTypes.string,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};
