import React from "react";
import { Form as BaseForm, Formik } from "formik";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "../index";

export default function Form({
  initialValues,
  onSubmit,
  buttonLabel,
  isButtonFullWidth,
  width,
  buttonIconName,
  isButtonIconFirst,
  isDisabled,
  children,
}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        onSubmit(values);
      }}
    >
      <BaseForm className={classNames("flex flex-col gap-6", width)}>
        {children}
        {buttonLabel && (
          <Button
            label={buttonLabel}
            isFullWidth={isButtonFullWidth}
            iconName={buttonIconName}
            isIconFirst={isButtonIconFirst}
            onClick={() => {}}
            isDisabled={isDisabled}
            testId="formSubmit"
          />
        )}
      </BaseForm>
    </Formik>
  );
}

Form.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.any,
  buttonLabel: PropTypes.string,
  isButtonFullWidth: PropTypes.bool,
  width: PropTypes.string,
  buttonIconName: PropTypes.string,
  isButtonIconFirst: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Form.defaultProps = {
  initialValues: {},
  width: "full",
  isButtonFullWidth: false,
  isDisabled: false,
};
