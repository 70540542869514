import React from "react";
import PropTypes from "prop-types";
import AccordionItem from "./accordionItem";

export default function Accordion({ items, themeOverride }) {
  const accordionItems = items.map((item) => (
    <AccordionItem key={item.title} item={item} themeOverride={themeOverride} />
  ));

  return <ul data-testid="accordion">{accordionItems}</ul>;
}

Accordion.propTypes = {
  items: PropTypes.array.isRequired,
  themeOverride: PropTypes.string,
};
