import React from "react";
import {
  FooterSection,
  Header,
  Loading,
  Error,
  CourseList,
} from "../../components";
import { useCourses } from "../../hooks";

export default function CoursesPage() {
  const { loading, error, courses } = useCourses();
  const COURSES_PAGE_TITLE = "Courses";

  const getCourses = () => {
    if (loading) {
      return <Loading />;
    }

    // Error handling still TBD.
    if (error) {
      return <Error errorDetails={error} />;
    }

    return (
      <>
        <h2 className="text-headlines-h2 w-full lg:w-full md:w-4/5 md:text-headlines-h1 font-bold pl-2 pb-4">
          {COURSES_PAGE_TITLE}
        </h2>
        <CourseList
          courses={courses}
          additionalClasses="z-10 w-3/4 lg:w-full gap-6"
        />
      </>
    );
  };

  return (
    <div className="flex flex-col bg-gradient-purple-light min-h-screen justify-between">
      <Header isDarkMode loginButtonStyle="secondary" />
      <div className="flex justify-center md:flex flex-col items-center max-w-[1440px] self-center pt-4 pb-20 lg:pt-[6.5rem]">
        {getCourses()}
      </div>
      <div className="bg-custom-green-dark flex justify-center">
        <div className="w-full max-w-[1440px]">
          <FooterSection />
        </div>
      </div>
    </div>
  );
}
