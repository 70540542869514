import React from "react";
import { Loading, Error, Form, Input, Select } from "../../components";

export default function Profile() {
  // FIXME: Either query or auth context
  const { loading, data, error } = {
    loading: false,
    data: {
      firstName: "",
      lastName: "",
      companyName: "",
      profession: "",
      programmingLevel: "",
      web3Level: "",
    },
    error: null,
  };

  // FIXME: To be a mutation to update the profile.
  const updateProfile = (v) => {
    console.log("update profile to:", v);
  };

  if (error) {
    console.error(error);
    return <Error errorDetails={error} />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="flex flex-col w-full items-center gap-6 px-4 lg:px-0 py-4 lg:py-[100px] z-10">
      <div className="flex flex-col h-fit border-b border-custom-gray-300 items-center w-full lg:w-1/3">
        <h1 className="text xl lg:twoxl gradient-light-green pb-6">
          Create Your Profile
        </h1>
        <h5 className="text text-sub-header text-center text-custom-green-soft pb-6">
          Tell us a little about yourself! All fields are optional.
        </h5>
      </div>
      <Form
        onSubmit={(v) => updateProfile(v)}
        initialValues={data}
        buttonLabel="Submit"
        isButtonFullWidth
        width="w-full lg:w-1/3"
      >
        <Input name="firstName" placeholder="First Name" />
        <Input name="lastName" placeholder="Last Name" />
        <Input name="companyName" placeholder="Company Name" />
        <Select
          name="profession"
          label="What is your profession?"
          titleOption="Select profession"
          options={[
            "Founder or leadership team",
            "Engineering manager",
            "Engineer",
            "Product manager",
            "Designer",
            "Software developer",
            "Business operations",
            "Other",
          ]}
        />
        <Select
          name="programmingLevel"
          label="What is your level of programming experience?"
          titleOption="Select experience level"
          options={[
            "Less than 1 year",
            "1-5 years",
            "5-10 years",
            "10-15 years",
            "15+ years",
          ]}
        />
        <Select
          name="web3Level"
          label="What is your level of Web3 experience?"
          titleOption="Select experience level"
          options={["None", "Less than 1 year", "1-5 years", "5+ years"]}
        />
        <hr className="border-custom-gray-300" />
      </Form>
    </section>
  );
}
