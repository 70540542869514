import { useEffect, useState } from "react";

export const useSlideAnimation = (setShowComponentFn, type) => {
  const animations = {
    slideUpAndDown: {
      start: "animate-slide-from-bottom",
      end: "animate-slide-from-top",
    },
    slideRightToLeft: {
      start: "animate-slide-from-right-to-left",
      end: "animate-slide-from-right-to-right",
    },
    slideLeftToRight: {
      start: "animate-slide-from-left",
      end: "animate-slide-from-right",
    },
  };
  const [animation, setAnimation] = useState(animations[type].start || null);

  const switchAnimation = () => {
    if (type) {
      const newAnimation =
        animation === animations[type].end
          ? animations[type].start
          : animations[type].end;
      setAnimation(newAnimation);
    }
  };

  useEffect(() => {
    if (type && animation === animations[type].end) {
      setTimeout(() => {
        setShowComponentFn(false);
      }, [500]);
    }
  }, [animation]);

  return [animation, switchAnimation];
};

export default useSlideAnimation;
