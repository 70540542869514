import React, { useState } from "react";
import { Panel } from "./panel";
import { ProgressMenuPanel } from "./progressMenu";

function SidePanel() {
  const [isOpen, setIsOpen] = useState(false);
  const [children, setChildren] = useState();

  const open = (newChildren = children) => {
    setChildren(newChildren);
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const component = () => {
    if (!isOpen) return null;

    return (
      <Panel testId="sidePanel" close={close} isRight>
        {children}
      </Panel>
    );
  };

  return { open, close, isOpen, component };
}

export { SidePanel, Panel, ProgressMenuPanel };
