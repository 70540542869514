/* eslint-disable react/jsx-props-no-spreading */
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link as BaseLink } from "react-router-dom";
import { AvailableIcons, Icon } from "../index";

function LinkEl({ children, ...props }) {
  const { reloadDocument, ...rest } = props;

  if (props?.disabled) {
    return <div {...rest}>{children}</div>;
  }

  const isOutsideLink =
    props?.to?.startsWith("http") || props?.to?.startsWith("mailto");

  return isOutsideLink ? (
    <a href={props.to} target="_blank" rel="noreferrer" {...rest}>
      {children}
    </a>
  ) : (
    <BaseLink reloadDocument={reloadDocument} {...rest}>
      {children}
    </BaseLink>
  );
}

LinkEl.propTypes = {
  children: PropTypes.any,
  props: PropTypes.any,
  reloadDocument: PropTypes.bool,
  to: PropTypes.string,
  disabled: PropTypes.bool,
};

// TODO: Hover, active, visited states
export default function Link({
  href,
  text,
  styleType,
  size,
  isDisabled, // FIXME: disabled classes should not return as an a tag.
  disabledIconStyleClasses,
  asButton,
  isFullWidth,
  onClick,
  iconName,
  isIconFirst,
  isIconOnly,
  ariaLabel,
  isUnderlined,
  additionalClasses,
  testid,
  customLinkColor,
  reloadDocument,
}) {
  const isInline = !!additionalClasses?.includes("inline-block");
  const sizeClasses = {
    default: classNames("gap-2", asButton ? "default" : "text-link-md"),
    large: classNames("gap-2", asButton ? "large" : "text-link-lg"),
    small: classNames("gap-1", asButton ? "small" : "text-link-sm"),
    xsmall: "gap-1 text-link-xs",
  };

  const linkColor = (type) => {
    if (isDisabled)
      return type === "icon"
        ? disabledIconStyleClasses
        : "text-custom-gray-200";

    if (customLinkColor)
      return type === "icon"
        ? `fill-${customLinkColor}`
        : `text-${customLinkColor}`;

    if (styleType === "gray")
      return type === "icon" ? "fill-custom-gray-300" : "text-custom-gray-300";

    return type === "icon" ? "fill-primary-green" : "text-primary-green";
  };

  return asButton ? (
    <LinkEl
      to={href}
      className={classNames(
        "button flex items-center justify-center hover:opacity-75",
        sizeClasses[size],
        isFullWidth ? "w-full" : "w-full md:w-fit",
        { "flex-row-reverse": !isIconFirst },
        styleType.toString(),
        { disabled: isDisabled },
        additionalClasses
      )}
      aria-label={ariaLabel}
      disabled={isDisabled}
      onClick={onClick}
      data-testid={testid}
      reloadDocument={reloadDocument}
    >
      {iconName && (
        <Icon
          styleType={isDisabled ? null : styleType}
          fill={linkColor("icon")}
          iconName={iconName}
          size={size}
          additionalClasses={`icon ${size}`}
          ariaLabel={isIconOnly ? ariaLabel : null}
        />
      )}
      {text && !isIconOnly && <p>{text}</p>}
    </LinkEl>
  ) : (
    <LinkEl
      to={isDisabled ? null : href}
      disabled={isDisabled}
      className={classNames(
        "items-center link",
        sizeClasses[size],
        { underline: isUnderlined },
        { flex: !isInline },
        { "flex-row-reverse": !isIconFirst },
        linkColor(),
        additionalClasses
      )}
      onClick={isDisabled ? null : onClick}
      data-testid={testid}
      reloadDocument={reloadDocument}
    >
      {iconName && (
        <Icon fill={linkColor("icon")} iconName={iconName} size={size} />
      )}
      {text && !isIconOnly && <p>{text}</p>}
    </LinkEl>
  );
}

Link.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
  isDisabled: PropTypes.bool,
  disabledIconStyleClasses: PropTypes.string,
  size: PropTypes.oneOf(["default", "xlarge", "large", "small", "xsmall"]),
  asButton: PropTypes.bool,
  // FIXME: Only if asButton is true
  styleType: PropTypes.oneOf([
    "primary",
    "secondary",
    "outline",
    "outline-white",
    "gray",
    "none",
  ]),
  // Only if asButton is true
  isFullWidth: PropTypes.bool,
  // Only if asButton is true
  isIconOnly: PropTypes.bool,
  // Optional for accessibility, especially if iconOnly
  ariaLabel: PropTypes.string,
  // FIXME: Only for not asButton
  onClick: PropTypes.func,
  // these icons might only make sense if they are also asButton
  iconName: PropTypes.oneOf(AvailableIcons),
  isIconFirst: PropTypes.bool,
  isUnderlined: PropTypes.bool,
  // Note: Careful! Any classes already defined with tailwind will not necessarily be overridden
  // this is mostly meant to allow flexibility to add positioning without adding extra divs
  additionalClasses: PropTypes.string,
  testid: PropTypes.string,
  customLinkColor: PropTypes.string,
  // https://reactrouter.com/en/main/components/link - use <Link reloadDocument> to skip client side routing
  // and let the browser handle the transition normally (as if it were an <a href>).
  reloadDocument: PropTypes.bool,
};

Link.defaultProps = {
  isDisabled: false,
  disabledIconStyleClasses: "fill-custom-gray-200",
  styleType: "primary",
  size: "default",
  isIconFirst: true,
  isUnderlined: false,
};
