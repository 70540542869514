import { useMutation } from "@apollo/client";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import QueueCongratulatory2 from "../../assets/pngs/queue_congratulatory_2_circle.png";
import QuickbeamBook from "../../assets/svgs/quickbeam-book.svg";
import { course as CourseContext, lesson, theme } from "../../context";
import { useLocalStorage } from "../../hooks";
import { SUBMIT_QUIZ } from "../../queries";
import analytics from "../../utils/analyticsTracker";
import {
  AutoSave,
  Button,
  CheckBoxInput,
  Form,
  Icon,
  Image,
  Markdown,
  Modal,
  ProgressMenuHeader,
  ResetButton,
} from "../index";
import { areSetsEqual } from "../utils";

// so that changing an answer changes the object, which is necessary for dependency arrays
const dupAnswers = (answers) =>
  Object.fromEntries(
    Object.keys(answers).map((key) => [key, [...answers[key]]])
  );

export default function Quiz() {
  const isDarkMode = theme()[0] === "dark";
  const navigate = useNavigate();
  const {
    lessonValue: { id, sections, courseId, prevLessonId, title },
    navigateToNextLesson,
  } = lesson() || {};
  const {
    currentCourse: course,
    isLoadingCurrentCourse,
    isErrorCurrentCourse,
  } = CourseContext();

  const [questionNum, setQuestionNum] = useState(0);
  const [timesAnswered, setTimesAnswered] = useState(0);
  const quizSection = sections.find((s) => s.type === "QUIZ");
  const [submitQuiz] = useMutation(SUBMIT_QUIZ);

  // const hasCompletedSuccessfully =
  //   typeof id === "number" && course.getLesson(id).isComplete;
  const prevQuestionPassedRef = useRef();

  const { numQuestions, questions } = quizSection;
  const [allQuizAnswers, setAllQuizAnswers] = useState(
    Object.fromEntries(questions.map((_, i) => [i, []]))
  );

  const currentQuestion = questions[questionNum];
  const correctAnswers = useMemo(
    () => currentQuestion?.answers?.filter((a) => a?.correct) || [],
    [currentQuestion?.answers]
  );
  const correctValues = useMemo(
    () => correctAnswers?.map((ca) => ca.answer) || [],
    [correctAnswers]
  );

  const userQuizHistoryLocalStorageKey = "userQuizHistory";
  const [userQuizHistory, setUserQuizHistory] = useLocalStorage(
    userQuizHistoryLocalStorageKey,
    { startedAQuiz: false, finishedAQuiz: false }
  );
  const [displayQuizIntroModal, setDisplayQuizIntroModal] = useState(
    !userQuizHistory.startedAQuiz
  );
  const [displayCongratsModal, setDisplayCongratsModal] = useState(false);

  const currentQuestionAnswers = allQuizAnswers[questionNum];

  const hasWrongAnswer = useMemo(
    () =>
      currentQuestionAnswers.filter((a) => !correctValues?.includes(a)).length >
      0,
    [currentQuestionAnswers, correctValues]
  );
  const isQuestionPassed = useMemo(
    () => areSetsEqual(currentQuestionAnswers, correctValues),
    [currentQuestionAnswers, correctValues]
  );

  // Multiple choice questions aren't considered "attempted" until we know
  // that they got it wrong (chose an incorrect answer) or right (chose all
  // the correct answers).
  const isQuestionComplete = hasWrongAnswer || isQuestionPassed;

  useEffect(
    () => {
      if (isQuestionComplete) {
        analytics.attemptQuizQuestion({
          courseId: courseId,
          lessonId: id,
          questionIndex: questionNum,
          questionText: currentQuestion.question,
          success: isQuestionPassed,
        });
      }
    },
    // The eslint rule is just wrong in this case. We want analytics to report
    // whenever they answer a question, not when the properties it passes change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timesAnswered]
  );

  const onContinue = () => {
    const hasCompletedAllQuestions = questionNum + 1 === numQuestions;
    if (hasCompletedAllQuestions && !userQuizHistory.finishedAQuiz) {
      setUserQuizHistory({ ...userQuizHistory, finishedAQuiz: true });
      submitQuiz({
        variables: {
          lessonId: id,
          version: "0.0.1",
          answers: JSON.stringify(allQuizAnswers),
        },
      });
      setDisplayCongratsModal(true);
    } else if (hasCompletedAllQuestions) {
      submitQuiz({
        variables: {
          lessonId: id,
          version: "0.0.1",
          answers: JSON.stringify(allQuizAnswers),
        },
        onCompleted: navigateToNextLesson,
      });
    } else {
      setQuestionNum(questionNum + 1);
    }
  };

  useEffect(() => {
    prevQuestionPassedRef.current = isQuestionPassed;
    // this condition avoids scrolling to bottom when modal is displayed
    if (!displayCongratsModal && !displayQuizIntroModal) {
      scroll.scrollToBottom();
    }
  }, [isQuestionPassed]);

  return (
    <>
      {!!displayQuizIntroModal && (
        <Modal
          isInterstitial={false}
          modalContent={{
            title: "Welcome to your first quiz!",
            icon: QuickbeamBook,
            iconStyling: "h-24 mb-7",
            description:
              "We use quizzes to reinforce what you've been exposed to so far. Don't worry, you aren't being graded! If you choose an incorrect answer, you can always change it and figure out the correct one(s). And it's perfectly OK to go back to previous lessons to figure out answers!",
            buttonText: "Start Quiz",
            onClick: () => {
              setUserQuizHistory({ ...userQuizHistory, startedAQuiz: true });
              setDisplayQuizIntroModal(false);
            },
          }}
        />
      )}
      {!!displayCongratsModal && (
        <Modal
          isInterstitial
          modalContent={{
            title: "Congratulations!",
            icon: QueueCongratulatory2,
            iconStyling: "h-72 mb-3 [transform:matrix(-1,0,0,1,0,0)]",
            description:
              "Great job! I hope that was helpful. There are more quizzes in your future. Let's keep going.",
            buttonText: "Next Lesson",
            buttonIcon: "arrowRight",
            onClick: () => {
              navigateToNextLesson();
            },
          }}
          backgroundStyling="bg-gradient-green-light animate-bg-oscillating bg-[length:400%_400%] bg-no-repeat"
        />
      )}
      <div
        className={classNames(
          "flex flex-col w-screen h-fit px-4 lg:px-6 pb-6",
          isDarkMode ? "dark" : "light"
        )}
      >
        <ProgressMenuHeader
          currentLessonTitle={title}
          headerStyle="bg-transparent z-50"
          course={course}
          isCenteredNavigator={false}
          isLoading={isLoadingCurrentCourse || !course}
          error={isErrorCurrentCourse}
        />
        <section className="flex flex-col pt-6 lg:pt-0 items-center">
          <div
            className={classNames(
              "lg:w-1/2 relative flex flex-col gap-6",
              isDarkMode ? "dark" : "light"
            )}
            id={questionNum}
          >
            <h3 className="text-xs uppercase">{`Question ${(
              questionNum + 1
            ).toString()} of ${numQuestions}`}</h3>
            {/* This needs to be Markdown so any code snippets the question contains render properly. */}
            <Markdown
              content={currentQuestion.question}
              elementStyles={{ p: ["h2"] }}
            />
            <hr className="border border-editor-base0 my-6" />
            <Form
              initialValues={allQuizAnswers}
              onSubmit={(answers) => {
                setAllQuizAnswers(dupAnswers(answers));
                setTimesAnswered(timesAnswered + 1);
              }}
              key={questionNum}
            >
              <AutoSave />
              <div
                role="group"
                /* eslint-disable-next-line jsx-a11y/aria-proptypes */
                aria-labelledby={questionNum}
                className="flex flex-col gap-6 relative"
              >
                {currentQuestion.answers.map((a) => {
                  const { answer } = a;
                  return (
                    <CheckBoxInput
                      label={answer}
                      name={questionNum.toString()}
                      value={answer}
                      key={answer}
                      isCorrectAnswer={a.correct}
                      isDisabled={isQuestionComplete}
                    />
                  );
                })}
              </div>
              {isQuestionComplete && (
                <div className="h-32">
                  <div
                    className={classNames(
                      "flex w-full h-20 rounded-sm items-center text-xl justify-center",
                      isQuestionPassed
                        ? "bg-gradient-green-dark border border-primary-green"
                        : "bg-gradient-red-dark border border-primary-red"
                    )}
                  >
                    <div className="w-32 mr-auto invisible" />
                    <div className="flex items-center gap-4">
                      <Icon
                        iconName={
                          isQuestionPassed ? "checkInCircle" : "xInCircle"
                        }
                        fill="none"
                        size="large"
                        additionalClasses="mt-2"
                      />
                      <h2 className="headlines-h2-mobile whitespace-nowrap text-custom-white">
                        {isQuestionPassed ? "Correct" : "Try again"}
                      </h2>
                    </div>
                    <Image
                      name={
                        isQuestionPassed
                          ? "ventureCongratulatory3"
                          : "queueConfused"
                      }
                      additionalClasses="w-32 mt-12 ml-auto"
                    />
                  </div>
                </div>
              )}
              {hasWrongAnswer && (
                <ResetButton
                  newValues={{ ...allQuizAnswers, [questionNum]: [] }}
                  label="Reset Question"
                  styleType={isDarkMode ? "secondary" : "primary"}
                  testId="quizReset"
                  isFullWidth
                />
              )}
            </Form>
            {isQuestionPassed && (
              <Button
                onClick={() => onContinue()}
                label="Continue"
                styleType="secondary"
                iconName="arrowRight"
                isIconFirst={false}
                testId="quizContinue"
                isFullWidth
              />
            )}
            {questionNum > 0 && (
              <Button
                onClick={() => setQuestionNum(questionNum - 1)}
                label={`Back to Question ${questionNum}`}
                styleType="outline"
                iconName="arrowLeft"
                testId="quizBack"
                isFullWidth
                additionalClasses="bg-transparent"
              />
            )}
            {/* TODO: move navigate to prev lesson to Lesson Context? */}
            <Button
              onClick={() =>
                navigate(`/course/${courseId}/lesson/${prevLessonId}`)
              }
              label="Back to Lesson"
              styleType="outline"
              iconName="arrowLeft"
              testId="quizBackToLesson"
              isFullWidth
              additionalClasses="bg-transparent"
            />
          </div>
        </section>
      </div>
    </>
  );
}

Quiz.propTypes = {};
