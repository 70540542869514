import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Markdown } from "../index";

export default function WordBubble({ textItems, additionalClasses }) {
  return (
    <div className="flex flex-col items-center">
      <div
        className={classNames(
          "flex flex-col gap-4 bg-custom-white text-custom-green-slate p-6 rounded-3xl shadow-two-shadows-purple",
          additionalClasses
        )}
      >
        {textItems.map((text) => (
          <Markdown key={text} content={text} themeOverride="light" />
        ))}
      </div>
      <div className="flex flex-col items-center">
        <div
          className="w-0 h-0 2 z-10
      border-l-[48px] border-l-transparent
      border-t-[48px] border-primary-purple
      border-r-[48px] border-r-transparent
      "
        />
        <div
          className="w-0 h-0 -mt-12 z-10
      border-l-[40px] border-l-transparent
      border-t-[40px] border-custom-white
      border-r-[40px] border-r-transparent
      "
        />
      </div>
    </div>
  );
}

WordBubble.propTypes = {
  textItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  // Additional classes sometimes used for margin, padding, and width of the outer container
  additionalClasses: PropTypes.string,
};

WordBubble.defaultProps = {
  additionalClasses: null,
};
