import { OAUTH_PROVIDERS } from "../utils/auth/oauthConfigs";
import { LOG_IN_OAUTH } from "../queries";
import useOAuthCodeExchange from "./useOAuthCodeExchange";
import { OAuthStateManager } from "../utils/auth/sessionStateUtil";

const useOAuthLogin = ({ provider, successCallback, errorCallback }) => {
  const loginSuccessCallback = (data) => {
    const postLoginPath = OAuthStateManager.popPostLoginPath();
    successCallback(data, postLoginPath);
  };

  return useOAuthCodeExchange({
    mutation: LOG_IN_OAUTH,
    clientId: OAUTH_PROVIDERS[provider].loginConfig.client_id,
    redirectUri: OAUTH_PROVIDERS[provider].loginConfig.redirect_uri,
    successCallback: loginSuccessCallback,
    errorCallback: errorCallback,
  });
};

export default useOAuthLogin;
