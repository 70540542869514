import React from "react";
import PropTypes from "prop-types";
import { AllMentorsChevron, WordBubble, CourseList } from "../../components";

export default function MentorAndCourseSection({
  courses,
  isLoading,
  isError,
}) {
  const MEET_THE_MENTORS = "Meet the Mentors";
  const BUBBLE_TEXT_INTRO =
    "I’m <b>Luna</b> and together with <b>Venture</b> and <b>Queue</b> we will get curious about your goals, introduce concepts, test your retention, and help you reflect on your learning, so it sticks.";
  const BUBBLE_TEXT_INSTRUCTIONS =
    "Ready? Choose a course below, and start a new adventure! We will be with you all the way.";

  return (
    <div className="flex flex-col items-center pt-10 lg:pb-8 lg:pt-28 max-w-[1440px] self-center">
      <h2 className="h2 text-custom-white pb-10 lg:pb-12">
        {MEET_THE_MENTORS}
      </h2>
      <WordBubble
        textItems={[BUBBLE_TEXT_INTRO, BUBBLE_TEXT_INSTRUCTIONS]}
        additionalClasses="w-11/12"
      />
      <AllMentorsChevron />
      <CourseList
        courses={courses}
        isLoading={isLoading}
        isError={isError}
        additionalClasses="-translate-y-20 md:-translate-y-36 z-10 w-3/4 lg:w-full gap-6"
      />
    </div>
  );
}

MentorAndCourseSection.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};
