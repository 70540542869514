import { ethers } from "ethers";

const padLeft = (str, toLength) => "0".repeat(toLength - str.length) + str;
const padRight = (str, toLength) => str + "0".repeat(toLength - str.length);
const padRightToEndOfWord = (str) => {
  const remainder = str.length % 64;
  if (remainder === 0) {
    return str;
  }

  return padRight(str, str.length + (64 - remainder));
};

const add0x = (str) => (str.startsWith("0x") ? str : `0x${str}`);

const remove0x = (str) => (str.startsWith("0x") ? str.substring(2) : str);

const populateMintTxData = (
  auth,
  validUntilTimestamp,
  recipientAddress,
  tokenURI
) => {
  const methodID = "0xfca69679";
  // opAuth starts after the 4th word (each word is 32 (or 0x20) bytes, which is 64 chars)
  const opAuthStart = padLeft("80", 64);
  const timestamp = padLeft(validUntilTimestamp.toString(16), 64);
  const recAddr = padLeft(remove0x(recipientAddress), 64);
  const uriStart = padLeft("100", 64);
  // Sig is 65 (or 0x41) bytes
  const opAuthDataLength = padLeft("41", 64);
  // sig is 65 bytes, or just over 2 words, meaning it will take 3 since parameters cannot only use part of a word.
  const opAuthData = padRight(remove0x(auth), 192);
  const uriData = remove0x(
    ethers.utils.hexlify(ethers.utils.toUtf8Bytes(tokenURI))
  );
  const uriDataLength = padLeft((uriData.length / 2).toString(16), 64);
  const paddedUriData = padRightToEndOfWord(uriData);

  return (
    methodID +
    opAuthStart + // 1st word is Auth param (an array, so just the byte index of the start of this array is here)
    timestamp + // 2nd word is valid until timestamp param
    recAddr + // 3rd word is the recipient address
    uriStart + // 4th word is the URI param (an array, so just the byte index of the start of this array is here)
    opAuthDataLength + // opAuthStart points here, this is the length of the authData (should be 65 bytes)
    opAuthData + // 65 bytes (+ right padding) of the signature
    uriDataLength + // uriStart points here, this is the length of the uriData
    paddedUriData
  ); // the uri data bytes right-padded to the nearest word
};

const populateTransaction = (
  selectedAddress,
  contractAddress,
  auth,
  validUntilTimestamp,
  recipientAddress,
  tokenURI
) => ({
  to: add0x(contractAddress),
  from: selectedAddress,
  value: "0x00",
  data: populateMintTxData(
    auth,
    validUntilTimestamp,
    recipientAddress,
    tokenURI
  ),
  chainId: "0x5", // hard-coded to Goerli. See: https://chainlist.org/
});

// Moved the real implementation to the BE, this is now just for the mock
async function createOperatorAuthorization(
  operatorPrivateKey,
  validUntilTimestamp,
  recipientAddress,
  tokenURI
) {
  const operatorSigner = new ethers.Wallet(operatorPrivateKey);
  const messageHash = ethers.utils.keccak256(
    ethers.utils.solidityPack(
      ["uint256", "address", "string"],
      [validUntilTimestamp, recipientAddress, tokenURI]
    )
  );

  return operatorSigner.signMessage(ethers.utils.arrayify(messageHash));
}

export { createOperatorAuthorization, populateTransaction };
