import React from "react";
import PropTypes from "prop-types";
import { Markdown } from "../index";

export default function Connection({ connection }) {
  const CONNECT_WALLET = "Connect Wallet";

  return (
    <div className="flex flex-col gap-2 p-2">
      <h3 className="text-headlines-h3">{CONNECT_WALLET}</h3>
      <Markdown content={connection} />
    </div>
  );
}

Connection.propTypes = {
  connection: PropTypes.string,
};

Connection.defaultProps = {
  connection:
    "Now you’ll connect a MetaMask wallet. MetaMask does a good job of guiding you through the set up process, but we’ve provided some instructions below that can help provide guidance and explain concepts.",
};
