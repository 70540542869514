import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default function LogoWrapper({
  backgroundColor,
  additionalClasses,
  children,
}) {
  return (
    <div
      className={classNames(
        "flex w-[56px] lg:w-[120px] min-w-[56px] lg:min-w-[120px] h-[56px] lg:h-[120px] rounded-xl p-2 lg:p-4 gap-6 justify-center",
        backgroundColor,
        additionalClasses
      )}
    >
      {children}
    </div>
  );
}

LogoWrapper.propTypes = {
  children: PropTypes.any,
  backgroundColor: PropTypes.string,
  // Note: Any classes already defined with tailwind will not necessarily be overridden
  // this is mostly meant to allow flexibility to add padding, positioning, etc
  additionalClasses: PropTypes.string,
};
