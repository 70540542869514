import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import LogoWrapper from "./logoWrapper";
import { theme } from "../../context";
import { Widget, Link, ProgressBar, SubscribeForm } from "../index";
import analytics from "../../utils/analyticsTracker";

const VIEW_DETAILS = "View Details";
const LESSONS = "Lessons";
const PREVIEW_LESSONS = "Preview Lessons";
const BUY_NOW_CTA = "Buy Now";
const COMING_SOON = "Coming Soon";
const PREVIEW_COURSE_CTA = "Preview Course";
const PREVIEW_COURSE_AGAIN_CTA = "Start Preview Again";
const START_COURSE = "Start Course";
const CONTINUE_COURSE = "Continue Course";
const COMPLETED_COURSE = "Completed";

export default function CourseWidget({ course }) {
  const {
    displayName,
    logo,
    description,
    numLessons,
    id,
    availabilityStatus,
    price,
    firstLessonId,
    currentLessonId,
    currencySymbol,
    discountPrice,
    discountName,
    logoBackgroundColor,
    isUnlocked,
    percentComplete,
  } = course;

  const isPublished = availabilityStatus === "PUBLISHED";
  const isFuture = availabilityStatus === "FUTURE";
  const isPreview = availabilityStatus === "PREVIEW";

  const isDarkMode = theme()[0] === "dark";

  const location = useLocation();

  // Not my favorite solution, but working for now.
  const svgRefForMobile = useRef();
  const svgRefForDesktop = useRef();

  useEffect(() => {
    if (logo) {
      svgRefForMobile.current.innerHTML = logo;
      svgRefForDesktop.current.innerHTML = logo;
    }
  }, []);

  const getCTA = () => (
    <Link
      href={`/course/${id}/payment/make`}
      onClick={() => {
        analytics.clickedBuyNow(course.id, location.pathname);
      }}
      testid="coursePaymentLink"
      text={BUY_NOW_CTA}
      asButton
      size="small"
      additionalClasses="whitespace-nowrap"
    />
  );
  const getPreview = () => {
    const isOnPaymentPage = location.pathname.includes("payment");

    const path = () => {
      if (isOnPaymentPage) return `/course/${id}/lesson/${firstLessonId}`;
      return `/course/${id}/lesson/current`;
    };

    const hasStartedCourse = () => percentComplete > 0;

    const isFirstLesson = () =>
      !currentLessonId || currentLessonId === firstLessonId;

    const isCourseCompleted = () => percentComplete === 100;

    const ctaWord = () => {
      if (isUnlocked) {
        if (isFirstLesson()) {
          return START_COURSE;
        }
        if (isCourseCompleted()) {
          return COMPLETED_COURSE;
        }
        return CONTINUE_COURSE;
      }

      if (isOnPaymentPage) return PREVIEW_COURSE_AGAIN_CTA;
      if (hasStartedCourse()) return CONTINUE_COURSE;

      return PREVIEW_COURSE_CTA;
    };
    return (
      <Link
        href={path()}
        onClick={() => {
          if (ctaWord() === PREVIEW_COURSE_CTA) {
            analytics.previewCourse(id);
          }
        }}
        text={ctaWord()}
        styleType="secondary"
        asButton
        testid="linkToCourseLesson"
        size="small"
        additionalClasses="whitespace-nowrap"
      />
    );
  };

  const getLogo = (additionalClasses) => {
    const ref = additionalClasses.includes("lg:hidden")
      ? svgRefForMobile
      : svgRefForDesktop;
    return (
      <LogoWrapper
        backgroundColor={logoBackgroundColor}
        additionalClasses={additionalClasses}
      >
        <span
          ref={ref}
          id={`courseLogoFor${course.id}`}
          className={classNames("[&_svg]:h-full [&_svg]:w-full")}
        />
      </LogoWrapper>
    );
  };

  return (
    <Widget
      testid="courseWidget"
      backgroundColor="bg-custom-green-slate"
      borderColor="bg-gradient-green-light"
      size="large"
    >
      <div className="flex w-full gap-6 relative text-custom-white">
        {getLogo("hidden invisible lg:visible lg:flex")}
        <div className="flex flex-col gap-2 w-full">
          <div className="flex gap-4">
            {getLogo("lg:invisible lg:hidden")}
            <div className="flex flex-col lg:flex-row lg:gap-4 w-full">
              <h3
                className="text-headlines-h3 lg:text-headlines-h2-mobile"
                data-testid="courseDisplayName"
              >
                {displayName}
              </h3>
              {(isFuture || isPreview) && (
                <p
                  className="body-2 bg-editor-base03 px-2 rounded-sm text-custom-gray-100 mt-1 lg:pt-1 w-fit"
                  data-testid="comingSoonCourseTag"
                >
                  {COMING_SOON}
                </p>
              )}
            </div>
          </div>
          <h5 className="body-2-condensed">{description}</h5>
          {numLessons > 0 && (
            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-4">
                <h5
                  className="body-2 whitespace-nowrap"
                  data-testid="courseNumberOfLessons"
                >
                  {numLessons} {isPreview ? PREVIEW_LESSONS : LESSONS}
                </h5>
                {!isFuture && (
                  <Link
                    size="small"
                    href={`/course/${id}/details`}
                    text={VIEW_DETAILS}
                    isUnderlined
                    customLinkColor="primary-green"
                    additionalClasses="whitespace-nowrap"
                  />
                )}
              </div>
              {percentComplete > 0 && (
                <p
                  className="hidden md:block body-2"
                  data-testid="percentCompleteNumDesktop"
                >{`${percentComplete}% Complete`}</p>
              )}
            </div>
          )}
          {percentComplete > 0 && (
            <p
              className="md:hidden body-2"
              data-testid="percentCompleteNumMobile"
            >{`${percentComplete}% Complete`}</p>
          )}
          {percentComplete > 0 && (
            <ProgressBar
              percentComplete={course.percentComplete}
              additionalClasses="flex flex-col w-full"
            />
          )}
          <div
            className={classNames(
              "flex gap-2 items-end",
              price ? "justify-between" : "justify-end"
            )}
          >
            {price && (
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-2">
                  <div className="flex gap-2 pt-2">
                    <p
                      className="line-through text-custom-gray-200 body-2"
                      data-testid="coursePrice"
                    >
                      {currencySymbol}
                      {price}
                    </p>
                    {discountPrice && (
                      <p
                        className="text-primary-yellow body-2"
                        data-testid="courseDiscountPrice"
                      >
                        {currencySymbol}
                        {discountPrice}
                      </p>
                    )}
                    {discountName && (
                      <p className="body-2" data-testid="courseDiscountName">
                        {discountName}
                      </p>
                    )}
                  </div>
                </div>
                {!isUnlocked && isPublished && (
                  <p className="text-xxs-tight text-custom-gray-100">
                    Learn about our{" "}
                    <a
                      href="/scholarships"
                      className="underline"
                      data-testid="scholarshipLink"
                    >
                      Scholarship Program{" "}
                    </a>
                  </p>
                )}
              </div>
            )}
            <div className="hidden md:flex gap-2" data-testid="courseCTAs">
              {!isFuture && getPreview()}
              {!isUnlocked && isPublished && getCTA()}
            </div>
          </div>
          {isFuture && (
            <SubscribeForm
              submitText="Sign up to be notified"
              formType="courseInterest"
              courseId={id}
            />
          )}
          <div className="flex md:hidden gap-2" data-testid="mobileCourseCTAs">
            {!isFuture && getPreview()}
            {!isUnlocked && isPublished && getCTA()}
          </div>
        </div>
      </div>
    </Widget>
  );
}

CourseWidget.propTypes = {
  course: PropTypes.shape({
    displayName: PropTypes.string,
    logo: PropTypes.string,
    description: PropTypes.string,
    numLessons: PropTypes.number,
    availabilityStatus: PropTypes.oneOf(["PUBLISHED", "PREVIEW", "FUTURE"]),
    id: PropTypes.string,
    currentLessonId: PropTypes.string,
    firstLessonId: PropTypes.string,
    price: PropTypes.string,
    percentComplete: PropTypes.number,
    currencySymbol: PropTypes.string,
    discountPrice: PropTypes.string,
    discountName: PropTypes.string,
    logoBackgroundColor: PropTypes.string,
    isUnlocked: PropTypes.bool,
  }),
};
