/* eslint-disable no-nested-ternary */
import classNames from "classnames";
import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Error,
  Loading,
  MarkdownAndEditor,
  Quiz,
  LessonWithContentOnlySections,
} from "../../components";
import {
  LessonContext,
  theme,
  course as CourseContext,
  authentication,
} from "../../context";
import { useLesson } from "../../hooks";

export default function Lesson() {
  const { user } = authentication();
  const isDarkMode = theme().at(0) === "dark";
  const params = useParams();
  const navigate = useNavigate();

  const { currentCourse: course } = CourseContext();

  const hasCompletedLessonSuccessfullyBefore = useMemo(
    () => (id) => course?.getLesson(id).isComplete,
    [course]
  );

  const useLessonOnCompletedCallback = (lesson) => {
    // Set the context for the user course to update percentComplete and current lesson
    if (lesson?.sections?.some((s) => s.type === "WALLETS")) {
      const walletSection = lesson.sections.find((s) => s.type === "WALLETS");
      return navigate(
        `/course/${lesson.courseId}/wallets/${walletSection.provider}`,
        {
          state: {
            lessonId: params.lessonId,
            nextLessonId: lesson.nextLessonId,
            prevLessonId: lesson.prevLessonId,
            courseId: lesson.courseId,
            ordinal: lesson.ordinal,
          },
          replace: true,
        }
      );
    }

    if (lesson?.title?.includes("Mint")) {
      return navigate(`/course/${lesson.courseId}/mint`, {
        state: {
          title: lesson.title, // lesson title is needed for progress header
          // TODO: If we want traditional navigation, will need to pass those ids here too
        },
        replace: true,
      });
    }

    return null;
  };

  const {
    isLoading: isLessonLoading,
    error: lessonError,
    lesson,
  } = useLesson(
    params.lessonId,
    "0.0.1",
    useLessonOnCompletedCallback,
    user?.id // This is very weird, soemtimes user isn't present, but this page should require user be authenticed. Might just be an edge case around like user being stored in localstorage, but not memory or something
  );

  // Error handling still TBD.
  if (lessonError) {
    return <Error errorDetails={lessonError} />;
  }

  if (isLessonLoading || !course) {
    return <Loading />;
  }

  const lessonType =
    lesson.sections.some((s) => s.type === "EDITOR") &&
    lesson.sections.some((s) => s.type === "MARKDOWN") ? (
      <MarkdownAndEditor
        key={`ME${lesson.id}`}
        hasCompletedLessonSuccessfullyBefore={
          hasCompletedLessonSuccessfullyBefore
        }
      />
    ) : lesson.sections.length &&
      lesson.sections.every((s) => s.type === "MARKDOWN") ? (
      <LessonWithContentOnlySections
        isDarkMode={isDarkMode}
        course={course}
        lesson={lesson}
      />
    ) : lesson.sections.some((s) => s.type === "QUIZ") ? (
      <Quiz key={`Q${lesson.id}`} />
    ) : lesson.title.includes("MetaMask") ? (
      <Loading /> // Show loading until useLocation.
    ) : lesson.title.includes("Mint") ? (
      <Loading /> // Show loading until useLocation.
    ) : import.meta.env.DEV ? (
      console.error("Unsure how to render lesson", lesson)
    ) : (
      <Error />
    );

  return (
    <main
      className={classNames(
        "max-h-screen flex flex-col md:flex-row w-full",
        isDarkMode ? "dark" : "light"
      )}
      data-testid={lesson?.id}
    >
      <LessonContext lessonValue={lesson}>{lessonType}</LessonContext>
    </main>
  );
}
