import {
  OAUTH_PROVIDERS,
  OAUTH_PROVIDER_DISCORD_NAME,
} from "../utils/auth/oauthConfigs";
import { CONNECT_DISCORD_ACCOUNT } from "../queries";
import useOAuthCodeExchange from "./useOAuthCodeExchange";

const useOAuthDiscordLinkedRoles = ({ successCallback, errorCallback }) => {
  const config = OAUTH_PROVIDERS[OAUTH_PROVIDER_DISCORD_NAME].linkedRolesConfig;
  return useOAuthCodeExchange({
    mutation: CONNECT_DISCORD_ACCOUNT,
    clientId: config.client_id,
    redirectUri: config.redirect_uri,
    successCallback: successCallback,
    errorCallback: errorCallback,
  });
};

export default useOAuthDiscordLinkedRoles;
