/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Image } from "..";

export default function Avatar({ props }) {
  return <Image name={props.name} additionalClasses="w-32 mr-6 float-left" />;
}

Avatar.propTypes = {
  props: PropTypes.shape({ name: PropTypes.string }),
};

Avatar.defaultProps = {
  props: {
    name: "queueWelcomingCircle",
  },
};
