import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FooterSection, Header, Markdown } from "..";

export default function SupportPageLayout({ content, testid }) {
  return (
    <div
      className="flex flex-col bg-gradient-rad-green-dark min-h-screen justify-between"
      data-testid={testid}
    >
      <Header isDarkMode headerStyle="pt-6" />
      <div className={classNames("flex justify-center my-14 mx-4")}>
        <Markdown content={content} themeOverride="dark" />
      </div>
      <div className="bg-custom-green-dark flex justify-center">
        <div className="w-full max-w-[1440px]">
          <FooterSection />
        </div>
      </div>
    </div>
  );
}

SupportPageLayout.propTypes = {
  content: PropTypes.string.isRequired,
  testid: PropTypes.string,
};

SupportPageLayout.defaultProps = {
  testid: "supportPageLayout",
};
