import React from "react";
import { useJargons } from "../../hooks";
import { Loading, Error, Jargon } from "..";

export default function Glossary() {
  const { isLoading, error, jargons } = useJargons();

  const jargonPaths = Object.keys(jargons);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    if (import.meta.env.DEV) console.error(error);
    return <Error errorDetails={error} />;
  }

  return (
    <section className="flex w-full justify-center">
      <div className="flex flex-col md:w-1/2 max-w-lg mt-20">
        <ul className="flex flex-col">
          {jargonPaths.sort().map((jargonPath) => (
            <li key={jargonPath} className="pb-20">
              <Jargon
                key={jargonPath}
                path={jargonPath}
                themeOverride="light"
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
