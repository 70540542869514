import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useLazyQuery } from "@apollo/client";
import { GET_COURSES, GET_USER_COURSES } from "../queries";
import { authentication } from "./authContext";
import {
  addPricingInfo,
  getLogoBackgroundColor,
} from "../hooks/helpers/courseHelpers";
import { hardcodedCourses } from "../hardcodedData";

// TODO: logoBackgroundColor will probably always live on the FE and
// be determined by the level of course, so from the BE, we might
// determine this from a field with options about course level??
// Maybe we can get this from the title, but that feels riskier

export const Context = createContext();

export function CourseContext({ children, currentCourse }) {
  const { isAuthenticated } = authentication() || {};
  const [courses, setCourses] = useState();

  const getCoursesQuery = isAuthenticated ? GET_USER_COURSES : GET_COURSES;
  const [
    getCourses,
    {
      loading: isLoadingCourses,
      error: isErrorLoadingCourses,
      data: rawCourses,
    },
  ] = useLazyQuery(getCoursesQuery);

  useEffect(() => {
    // TODO: Check that result is being cached and not requesting every time.
    getCourses();
  }, []);

  useEffect(() => {
    // FIXME: This is all just very hardcoded and should be very temporary
    if (rawCourses?.courses.length > 0) {
      const coursesWithModification = rawCourses?.courses?.map((courseData) => {
        const { id } = courseData;
        return {
          ...courseData,
          ...addPricingInfo(id),
          logoBackgroundColor: getLogoBackgroundColor(id),
        };
      });

      const ids = {};
      const coursesToSet = coursesWithModification
        .concat(hardcodedCourses)
        .filter((c) => {
          const seenBefore = ids[c.id];
          ids[c.id] = true;
          return !seenBefore;
        });

      setCourses(coursesToSet);
    }
  }, [rawCourses?.courses]);

  // TODO: Maybe collect all error and loading states into one
  const combinedValues = useMemo(
    () => ({
      isLoadingCourses,
      isErrorLoadingCourses,
      courses,
      currentCourse: currentCourse?.course,
      isLoadingCurrentCourse: currentCourse?.isLoading,
      isErrorCurrentCourse: currentCourse?.error,
    }),
    [isLoadingCourses, isErrorLoadingCourses, courses, currentCourse]
  );

  return <Context.Provider value={combinedValues}>{children}</Context.Provider>;
}

export default function course() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("course must be used within the CourseContext Provider");
  }

  return context;
}

CourseContext.propTypes = {
  children: PropTypes.node.isRequired,
  currentCourse: PropTypes.object,
};
