import React from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import { useOAuthLogin } from "../../hooks";
import { authentication } from "../../context";
import { Loading } from "../../components";
import analytics from "../../utils/analyticsTracker";

function OAuthCallback({ provider }) {
  const navigate = useNavigate();
  const { logIn, logOut } = authentication();

  const loginSuccessCallback = (data, postLoginPath) => {
    const { loginOauth } = data;
    logIn(loginOauth.authToken, loginOauth.refreshToken);
    if (loginOauth.accountCreated) {
      analytics.signup();
    } else {
      analytics.login();
    }

    const redirectTo = postLoginPath || "/courses";

    return navigate(redirectTo, { replace: true });
  };

  const loginErrorCallback = (err) => {
    // TODO: Should provide error context to the logged in user
    Sentry.captureException(err);
    logOut();
    analytics.resetSession();
    return navigate("/error");
  };

  const { loading } = useOAuthLogin({
    provider: provider,
    successCallback: loginSuccessCallback,
    errorCallback: loginErrorCallback,
  });

  if (loading) {
    return <Loading />;
  }
}

export default OAuthCallback;

OAuthCallback.propTypes = {
  provider: PropTypes.oneOf(["google"]).isRequired,
};
