/* eslint-disable import/prefer-default-export */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { authentication, theme } from "../../context";
import { useSlideAnimation } from "../../hooks/useAnimation";
import { Icon } from "../index";
import AccountSection from "../accountSection";

export function Panel({
  children,
  close,
  headerText,
  homeLinkOverride,
  showHomeLink,
  showAccountDropdown,
  isRight,
  largePadding,
  testId,
  isDarkMode,
}) {
  const isDarkTheme = theme()[0] === "dark";
  // eslint-disable-next-line no-param-reassign
  isDarkMode = isDarkMode !== undefined ? isDarkMode : isDarkTheme;
  const { isAuthenticated } = authentication();
  const [animation, switchAnimation] = useSlideAnimation(
    close,
    isRight ? "slideRightToLeft" : "slideLeftToRight"
  );

  const closeWithAnimation = () => {
    switchAnimation();
  };

  const navigate = useNavigate();
  const goToHome = () => {
    closeWithAnimation();
    navigate("/");
  };

  const homeLink = () =>
    homeLinkOverride ? (
      homeLinkOverride()
    ) : (
      <Icon
        iconName="qLogo"
        styleType={isDarkMode ? "primary" : "secondary"}
        ariaLabel="go to home"
        onClick={goToHome}
        size="large"
      />
    );

  return (
    <>
      <button
        type="button"
        className="bg-custom-black opacity-75 fixed top-0 left-0 h-screen w-screen z-50"
        onClick={closeWithAnimation}
        aria-label="close the panel"
      />
      <div
        data-testid={testId}
        className={classNames(
          "fixed top-0 flex flex-col w-11/12 md:w-3/5 lg:w-2/6 pb-6 z-50 overflow-y-auto max-h-screen min-h-screen",
          isRight ? "right-0" : "left-0",
          isDarkMode ? "dark" : "light",
          animation,
          largePadding ? "px-8 py-8" : "px-6 py-6"
        )}
      >
        <div
          className={classNames(
            "flex w-full items-center pb-4 mb-4",
            showHomeLink ? "justify-between" : "justify-end"
          )}
        >
          {showHomeLink && homeLink()}
          <Icon
            iconName="close"
            fill="fill-custom-gray-300"
            ariaLabel="close the panel"
            onClick={closeWithAnimation}
            size="large"
          />
        </div>
        {headerText && (
          <div className="border-b border-custom-blue-slate mb-4">
            <h3 className={classNames("text-headlines-h3 mb-4")}>
              {headerText}
            </h3>
          </div>
        )}
        {children}
        {showAccountDropdown && (
          <div
            className={classNames(
              "fixed flex p-6 w-[inherit] gap-x-3",
              isRight ? "bottom-0 right-0" : "bottom-0 left-0",
              {
                [isDarkMode ? "bg-editor-base03" : "bg-editor-base3"]:
                  isAuthenticated,
              }
            )}
          >
            <AccountSection
              menuPosition="top"
              isFullWidth
              logoutOnClick={closeWithAnimation}
              isDarkMode={isDarkMode}
            />
          </div>
        )}
      </div>
    </>
  );
}

Panel.propTypes = {
  children: PropTypes.any,
  close: PropTypes.func,
  headerText: PropTypes.string,
  isRight: PropTypes.bool,
  homeLinkOverride: PropTypes.func,
  showHomeLink: PropTypes.bool,
  showAccountDropdown: PropTypes.bool,
  largePadding: PropTypes.bool,
  testId: PropTypes.string,
  isDarkMode: PropTypes.bool,
};

Panel.defaultProps = {
  showHomeLink: false,
  showAccountDropdown: false,
};
