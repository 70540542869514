/* eslint-disable no-underscore-dangle */
// The underlying amplitude dependency is a singleton, so just making these
// functions like we do is probably all we really can do here. But to
// differentiate between private internal functions and ones we\'re exporting,
// we decided to use the underscore instead of an export b/c the underscore
// convention is pretty common, and the linter hates when we export the public
// functions and put them on the analytics object. It hates that much much more
// than it hates the "underscore-dangle". Ultimately, there's just not any obvious
// better way to do it, so for now we'll allow it :shrug:

import * as amplitude from "@amplitude/marketing-analytics-browser";
import { ANALYTICS } from "../queries";
import { getAuthAndRefreshTokens } from "../context";

// currently, we talk to the backend, which talks to mailchimp on our behalf
const backend = {
  client: null, // have to set it while configuring it to avoid circular dependencies
  track(eventName, properties) {
    if (getAuthAndRefreshTokens().authToken) {
      this.client.mutate({
        mutation: ANALYTICS,
        variables: { eventName, properties },
      });
    }
  },
};

let isEnabled = false;
let amplitudeConfigured = false;
let isVerbose = false;
function enable() {
  isEnabled = true;
}
function disable() {
  isEnabled = false;
}

function _ifEnabled(service, fnName, ...args) {
  let log = "";
  if (!isEnabled) {
    log += "[Analytics not enabled]\n";
  } else if (service === amplitude && !amplitudeConfigured) {
    log += "[Amplitude not configured]\n";
  } else {
    service[fnName](...args);
  }

  if (isVerbose) {
    if (service === amplitude) {
      log += "amplitude";
    } else if (service === backend) {
      log += "backend";
    }
    log += `: ${fnName}(`;
    log += args.map((arg) => JSON.stringify(arg, null, 2)).join(", ");
    log += `)`;
    console.log(log);
  }
}

function configureBackendClient(apolloClient) {
  backend.client = apolloClient;
}

// Session
function configureAmplitude(apiKey) {
  amplitude.init(apiKey);
  amplitudeConfigured = true;
}

function setUserEmail(email) {
  _ifEnabled(amplitude, "setUserId", email);
}

function resetSession() {
  _ifEnabled(amplitude, "reset");
}

function verbose(bool) {
  isVerbose = bool;
}

// Events
function _mailchimpifyProps(obj, path = [], props = {}) {
  if (typeof obj === "string") {
    // eslint-disable-next-line no-param-reassign
    props[path.join(".")] = obj;
  } else if (typeof obj === "number" || typeof obj === "boolean") {
    // eslint-disable-next-line no-param-reassign
    props[path.join(".")] = obj.toString();
  } else if (Array.isArray(obj)) {
    // noop, no obvious way to send this to mailchimp
  } else if (typeof obj === "object") {
    Object.keys(obj).forEach((key) =>
      _mailchimpifyProps(obj[key], [...path, key], props)
    );
  } else if (typeof obj === "undefined") {
    // noop, if there is no value, don't set it on the props
  } else {
    console.log("UNKNOWN MAILCHIMP PROP TYPE: ", { obj });
  }
  return props;
}
function _track(eventName, properties = {}) {
  _ifEnabled(amplitude, "track", eventName, properties);

  // maybe the argument transformation should happen in the backend, but we were
  // initially trying to talk to mailchimp from the frontend, so this stuff
  // already exists here, and we haven't tried to move it to the backend yet.
  _ifEnabled(
    backend,
    "track",
    eventName.replace(/\W+/g, "-"), // mailchimp doesn't like spaces in event names
    JSON.stringify(_mailchimpifyProps(properties)) // mailchimp doesn't like nested properties
  );
}

function login() {
  _track("User Sign In");
}

function logout() {
  _track("User Sign Out");
  resetSession();
}

function signup() {
  _track("User Create Account");
}

function visitRoute(host, path) {
  _track("Visit Route", { host, path });
}

function previewCourse(courseId) {
  _track("Preview Course", { courseId });
}

function clickedBuyNow(courseId, originPage) {
  _track("Clicked Buy Now", { courseId, originPage });
}

function runCode(courseId, lessonId, success, resultType) {
  _track("Run Code", {
    courseId,
    lessonId,
    success,
    resultType,
  });
}

// There's enough properties that I don't want to take them as ordinals, but I
// also want this analytics code controlling what properties get sent to amplitude,
// not the callsites scattered throughout the code. So, going to explicitly specify
// which props we receive, even though we're just passing them straight on through.
// (caller passes these props b/c that's what this fn requires, amplitude receives
// these props b/c that's what this fn sends, so the caller doesn't have to know
// about amplitude).
function attemptQuizQuestion({
  courseId,
  lessonId,
  questionIndex,
  questionText,
  success,
}) {
  _track("Attempt Quiz Question", {
    courseId,
    lessonId,
    questionIndex,
    success,
    questionText,
  });
}

function completePurchase(courseId, success) {
  _track("Complete Purchase", { courseId, success });
}

function completeCourse(courseId) {
  _track("Completed Course", { courseId });
}

const analytics = {
  enable,
  disable,
  completeCourse,
  configureAmplitude,
  configureBackendClient,
  setUserEmail,
  signup,
  login,
  logout,
  visitRoute,
  clickedBuyNow,
  previewCourse,
  completePurchase,
  runCode,
  resetSession,
  attemptQuizQuestion,
  verbose,
};

export default analytics;
