import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_CHECKOUT_SESSION, CHECK_COURSE_UNLOCKED } from "../../queries";
import { Error, Loading } from "../../components";
import analytics from "../../utils/analyticsTracker";

function useCourseUnlocked(unlockedCallback, lockedCallback) {
  const navigate = useNavigate();
  const params = useParams();

  const [checkCourseUnlocked, { data, loading, error, called }] = useMutation(
    CHECK_COURSE_UNLOCKED
  );

  const course = data?.checkCourseUnlocked;
  const result = useMemo(
    () => ({
      course,
      loading: loading || !called,
      error,
      navigate,
      paths: {
        currentLesson: `/course/${course?.id}/lesson/${course?.currentLessonId}`,
        payment: `/course/${course?.id}/payment`,
        toStripe: `/course/${course?.id}/payment/make`,
        fromStripe: `/course/${course?.id}/payment/made`,
      },
    }),
    [course, loading, called, error, navigate]
  );

  // immediately check if the course is unlocked (backend will sync with Stripe)
  useEffect(() => {
    checkCourseUnlocked({ variables: { id: params.courseId } });
  }, []);

  // once we know if it is unlocked, go to current lesson or invoke callback
  useEffect(() => {
    if (!course) {
      // noop
    } else if (course.isUnlocked) {
      unlockedCallback(result);
    } else {
      lockedCallback(result);
    }
  }, [course, result]);

  // b/c we immediately invoke, the mutation in the effect,
  // consider it to be loading if we haven't called it yet.
  return result;
}

export function ToStripe() {
  const [createCheckoutSession, { data, error: checkoutSessionError }] =
    useMutation(CREATE_CHECKOUT_SESSION);

  // Create a Stripe "checkout session" (roughly equivalent to a bill for this course)
  //
  // > A Checkout Session represents your customer's session as they pay for
  // > one-time purchases or subscriptions through Checkout or Payment Links.
  // -- https://stripe.com/docs/api/checkout/sessions
  const { error: courseError } = useCourseUnlocked(
    ({ navigate, paths }) => {
      navigate(paths.currentLesson, { replace: true });
    },
    ({ course, paths }) => {
      const redirectUrl = `${window.location.origin}${paths.fromStripe}`;
      createCheckoutSession({
        variables: {
          successRedirectUrl: redirectUrl,
          cancelRedirectUrl: redirectUrl,
          courseId: course.id,
          quantity: 1,
        },
      });
    }
  );

  // Once we have created a checkout session, send them to Stripe to pay it
  useEffect(() => {
    if (!data) return;
    const { url } = data.createCheckoutSession;
    window.location.replace(url);
  }, [data]);

  // Handle error
  const error = courseError || checkoutSessionError;
  if (error) return <Error errorDetails={error} />;

  // Handle loading
  return <Loading />;
}

export function FromStripe() {
  const { error } = useCourseUnlocked(
    ({ navigate, paths, course }) => {
      analytics.completePurchase(course.id, true);
      navigate(paths.currentLesson, { replace: true });
    },
    ({ navigate, paths, course }) => {
      analytics.completePurchase(course.id, false);
      navigate(paths.payment, { replace: true });
    }
  );

  // Handle error
  if (error) return <Error errorDetails={error} />;

  // Handle loading
  return <Loading />;
}
