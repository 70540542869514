import React from "react";
import PropTypes from "prop-types";
import { Markdown, Error } from "..";

export default function QbContent({ contentType, content, themeOverride }) {
  if (contentType === "MARKDOWN") {
    return <Markdown content={content} themeOverride={themeOverride} />;
  }

  if (import.meta.env.DEV)
    console.error(`Unsupported content type: ${contentType}`);
  return <Error />;
}

QbContent.propTypes = {
  contentType: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  themeOverride: PropTypes.string,
};

QbContent.defaultProps = {
  themeOverride: undefined,
};
