import React from "react";
import PropTypes from "prop-types";

export default function ProgressBar({ percentComplete }) {
  return (
    <div
      className="h-1.5 rounded-xl mt-0.5 bg-custom-green-dark"
      data-testid="progressBar"
    >
      <div
        style={{ width: `${percentComplete}%` }}
        className="bg-primary-yellow h-full rounded-[inherit]"
      />
    </div>
  );
}

ProgressBar.propTypes = {
  percentComplete: PropTypes.number,
};
