import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default function BaseHeader({
  isMenuOpen,
  menu,
  headerStyle,
  startContent,
  centeredContent,
  endContent,
}) {
  const hasStartContent = startContent && !!startContent();
  const hasCenteredContent = centeredContent && !!centeredContent();
  const hasEndContent = endContent && !!endContent();

  return (
    <>
      <div
        className={classNames(
          "flex min-h-[3rem] lg:min-h-[4.5rem] items-center z-10",
          headerStyle
        )}
        data-testid="headerContainer"
      >
        <header className="flex w-full z-10">
          <div className="flex w-full items-center justify-center">
            {hasStartContent && (
              <div className="flex items-center space-x-4 md:space-x-16 lg:space-x-20 flex-1">
                {startContent()}
              </div>
            )}
            {hasCenteredContent && (
              <div
                className="flex flex-1 place-content-center"
                data-testid="centerHeaderContainer"
              >
                {centeredContent()}
              </div>
            )}
            {hasEndContent && (
              <div className="flex place-content-end flex-1">
                {endContent()}
              </div>
            )}
          </div>
        </header>
      </div>
      {isMenuOpen && menu()}
    </>
  );
}

BaseHeader.propTypes = {
  isMenuOpen: PropTypes.bool,
  // TODO: Is required if menuOnClick and useMenuState
  menu: PropTypes.func,
  headerStyle: PropTypes.string,
  startContent: PropTypes.func,
  // Use this if content should be centered (e.g. payment page's "Trial Complete", or quiz page's "Lesson 4/27")
  centeredContent: PropTypes.func,
  endContent: PropTypes.func,
};
