import {
  DISCORD_AUTH_URL,
  DISCORD_LINKED_ROLES_CONFIG,
  DISCORD_OAUTH_LOGIN_CONFIG,
  OAUTH_PROVIDER_DISCORD_NAME,
} from "./discord";
import {
  GOOGLE_AUTH_URL,
  GOOGLE_OAUTH_LOGIN_CONFIG,
  OAUTH_PROVIDER_GOOGLE_NAME,
} from "./google";

export const OAUTH_PROVIDERS = {
  [OAUTH_PROVIDER_GOOGLE_NAME]: {
    url: GOOGLE_AUTH_URL,
    loginConfig: GOOGLE_OAUTH_LOGIN_CONFIG,
  },
  [OAUTH_PROVIDER_DISCORD_NAME]: {
    url: DISCORD_AUTH_URL,
    loginConfig: DISCORD_OAUTH_LOGIN_CONFIG, // If we ever decide to support login via Discord OAuth
    linkedRolesConfig: DISCORD_LINKED_ROLES_CONFIG,
  },
};

export { OAUTH_PROVIDER_DISCORD_NAME, OAUTH_PROVIDER_GOOGLE_NAME };
