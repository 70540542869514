import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Icon } from "../../index";

export default function DropdownButton({
  text,
  onClick,
  menuPosition,
  isFullWidth,
  isIconOnly,
  isDarkMode,
  iconName,
  additionalClasses,
}) {
  const styleType = isDarkMode ? "primary" : "secondary";
  const icon = {
    top: "chevronUp",
    bottom: "chevron",
  };

  return (
    <button
      className={classNames(
        "flex items-center gap-2 button transparent border-none justify-between",
        styleType,
        { "w-full": isFullWidth },
        additionalClasses
      )}
      aria-label="dropdown menu button"
      type="button"
      onClick={onClick}
      onBlur={(e) => {
        if (typeof e.relatedTarget?.href !== "string") {
          onClick();
        }
      }}
    >
      <div className="flex gap-2 w-full">
        {iconName && <Icon iconName={iconName} styleType={styleType} />}
        {!isIconOnly && <p>{text}</p>}
      </div>
      <Icon styleType={styleType} iconName={icon[menuPosition]} />
    </button>
  );
}

DropdownButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  menuPosition: PropTypes.oneOf(["top", "bottom"]),
  isFullWidth: PropTypes.bool,
  isIconOnly: PropTypes.bool,
  isDarkMode: PropTypes.bool.isRequired,
  iconName: PropTypes.string,
  additionalClasses: PropTypes.string,
};

DropdownButton.defaultProps = {
  menuPosition: "bottom",
  isFullWidth: false,
  isIconOnly: false,
};
