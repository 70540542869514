import PropTypes from "prop-types";
import React, { createContext, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const Context = createContext();

export function LessonContext({ lessonValue, children }) {
  const navigate = useNavigate();

  const navigateToNextLesson = useCallback(() => {
    navigate(
      `/course/${lessonValue.courseId}/lesson/${lessonValue.nextLessonId}`
    );
  }, [lessonValue]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <Context.Provider value={{ lessonValue, navigateToNextLesson }}>
      {children}
    </Context.Provider>
  );
}

export default function lesson() {
  const context = useContext(Context);
  if (!Context && context === undefined) {
    throw new Error("Lesson must be used within a Provider");
  }
  return context;
}

LessonContext.propTypes = {
  lessonValue: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};
