import React, { useState } from "react";
import PropTypes from "prop-types";
import { authentication } from "../../context";
import { DropdownButton, Popover, PopoverMenuItem } from "../index";
import analytics from "../../utils/analyticsTracker";

export default function AccountDropdown({
  menuPosition,
  isFullWidth,
  logoutOnClick,
  isIconOnly,
  isDarkMode,
}) {
  const { user, logOut } = authentication();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // FIXME: clicking outside of widget does not close menu

  const buttonText = user.preferredName || user.email;
  const styleType = isDarkMode ? "primary" : "secondary";

  const LOG_OUT = "Log Out";
  const SUPPORT = "Support";

  const handleLogout = () => {
    logOut();
    analytics.logout();
    logoutOnClick();
  };

  const button = () => (
    <DropdownButton
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      text={buttonText}
      styleType={styleType}
      menuPosition={menuPosition}
      isFullWidth={isFullWidth}
      isIconOnly={isIconOnly}
      isDarkMode={isDarkMode}
      iconName="account"
      additionalClasses="font-normal"
    />
  );

  const items = () => (
    <>
      <PopoverMenuItem
        href="/support"
        iconName="questionMark"
        text={SUPPORT}
        testid="support"
        isDarkMode={isDarkMode}
      />
      <PopoverMenuItem
        onClick={handleLogout}
        href="/"
        iconName="logOut"
        text={LOG_OUT}
        testid="logout"
        isDarkMode={isDarkMode}
      />
    </>
  );

  return (
    <Popover
      button={button}
      items={items}
      isMenuOpen={isMenuOpen}
      testId="accountDropdown"
      menuPosition={menuPosition}
      isFullWidth={isFullWidth}
    />
  );
}

AccountDropdown.propTypes = {
  menuPosition: PropTypes.oneOf(["top", "bottom"]),
  isFullWidth: PropTypes.bool,
  isIconOnly: PropTypes.bool,
  logoutOnClick: PropTypes.func,
  isDarkMode: PropTypes.bool.isRequired,
};

AccountDropdown.defaultProps = {
  menuPosition: "bottom",
  isFullWidth: false,
  logoutOnClick: () => {},
};
