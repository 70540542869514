import React from "react";

export default function TaglineSection() {
  const TAGLINE_TEXT =
    "The Web3 ecosystem has never been easier to access as a user, but still presents a serious challenge when it comes to developing smart contracts and deeply understanding blockchain technologies.";
  const TAGLINE_TEXT_2 =
    "Here at Quickbeam, take the time to learn and grow your skills in a new world, a new paradigm, and a new approach to software development.";
  return (
    <div className="flex flex-col text-center bg-editor-base03 gap-2 py-20 px-4 lg:px-[300px] z-10">
      <h2 className="h2">Let&apos;s Dive In Together</h2>
      <p className="text-custom-green-light text-body-1 pb-2">{TAGLINE_TEXT}</p>
      <p className="text-custom-green-light text-body-1">{TAGLINE_TEXT_2}</p>
    </div>
  );
}
