import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useArticle } from "../../hooks";
import { theme } from "../../context";
import { Loading, Error, QbContent, Link } from "..";

function Wrapper({ children, isPreview }) {
  if (isPreview) {
    return (
      <li
        className={classNames(
          "flex flex-col text-custom-gray-200 flex justify-start"
        )}
        data-testid="articlePreview"
      >
        {children}
      </li>
    );
  }

  return children;
}

Wrapper.propTypes = {
  children: PropTypes.any.isRequired,
  isPreview: PropTypes.bool.isRequired,
};

function TitleWrapper({ children, isPreview, path }) {
  if (isPreview) {
    return <a href={path}>{children}</a>;
  }

  return children;
}

TitleWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  isPreview: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
};

function Article({ path, themeOverride, isPreview }) {
  const {
    isLoading,
    error,
    article: { title, content, contentType, preview },
  } = useArticle(path);
  const isDarkMode = themeOverride === "dark" && theme()[0] === "dark";

  const READ_MORE = "Read more";

  const fullPath = `/resources/articles/${path}`;

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error errorDetails={error} />;
  }

  return (
    <Wrapper isPreview={isPreview}>
      <TitleWrapper isPreview={isPreview} path={fullPath}>
        <h2
          className={classNames(
            "text-headlines-h2-mobile md:text-headlines-h2 bg-transparent",
            isDarkMode ? "dark" : "light"
          )}
          data-testid="internalArticle"
        >
          {title}
        </h2>
      </TitleWrapper>
      <hr
        className={classNames("my-10 w-full", {
          "border-custom-gray-300": isDarkMode,
          "border-custom-gray-100": !isDarkMode,
        })}
      />
      <QbContent
        contentType={contentType}
        content={isPreview ? preview : content}
        themeOverride={isDarkMode ? "dark" : "light"}
      />
      {isPreview && (
        <Link
          href={fullPath}
          text={READ_MORE}
          iconName="chevronRight"
          isIconFirst={false}
          customLinkColor="primary-green"
          size="small"
          additionalClasses="whitespace-nowrap self-start mt-4"
          testid="readMoreLink"
          reloadDocument
        />
      )}
    </Wrapper>
  );
}

export default Article;

Article.propTypes = {
  path: PropTypes.string.isRequired,
  themeOverride: PropTypes.oneOf(["dark", "light"]), // bc theme is only a construct in lessons, the pages need to determine their own theme
  isPreview: PropTypes.bool,
};

Article.defaultProps = {
  isPreview: false,
  themeOverride: "dark",
};
