import React from "react";
import PropTypes from "prop-types";
import { Markdown, Accordion } from "../index";
import { getSectionByKey } from "../utils";

export default function Instructions({ sections }) {
  const INSTRUCTIONS = "Instructions";
  const TITLES = {
    browser: "Installing and setting up the MetaMask browser extension",
    mobile: "Installing the MetaMask mobile app",
    create: "Create a new wallet",
    import: "Importing an existing wallet",
  };

  const getSectionsContents = () => {
    const contentsBySection = {};

    Object.keys(TITLES).forEach((key) => {
      contentsBySection[key] = getSectionByKey(sections, key)
        ?.split(/\n(.*)/s)
        .slice(1, -1)
        .join();
    });

    return contentsBySection;
  };

  const markdownInstructions = (key) => (
    <Markdown content={getSectionsContents()[`${key}`]} />
  );

  const instructionsContent = () =>
    Object.entries(TITLES).map(([key, title]) => ({
      title: title,
      content: [
        {
          type: "markdown",
          value: markdownInstructions(key),
        },
      ],
    }));

  return (
    <div className="flex flex-col p-2">
      <h3 className="text-headlines-h3 pb-4 border-b border-b-custom-gray-300">
        {INSTRUCTIONS}
      </h3>
      <Accordion items={instructionsContent()} />
    </div>
  );
}

Instructions.propTypes = {
  sections: PropTypes.array.isRequired,
};
