import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useQuery, useMutation } from "@apollo/client";
import { GET_LESSON, COMPLETE_WALLET_SETUP } from "../../queries";
import {
  Loading,
  Error,
  ProgressMenuHeader,
  Header,
  Link,
  MetaMaskOverview,
  MetaMaskInstructions,
  MetaMaskConnection,
} from "../../components";
import { wallet, theme, course as CourseContext } from "../../context";
import { getSectionByKey } from "../../components/utils";
import analytics from "../../utils/analyticsTracker";

export default function Wallet() {
  const params = useParams();
  const location = useLocation();
  const { isConnected } = wallet();
  const isDarkMode = theme()?.at(0) ? theme()?.at(0) === "dark" : true;
  const {
    currentCourse: course,
    isLoadingCurrentCourse,
    isErrorCurrentCourse,
  } = CourseContext();

  const { lessonId, nextLessonId, prevLessonId, courseId } =
    location?.state || {};

  const {
    loading: isLessonLoading,
    data: lessonData,
    error: lessonError,
  } = useQuery(GET_LESSON, {
    // FIXME: version is currently required.
    // FIXME: remove when can query for wallet data in own right
    variables: { id: lessonId || "lesson_w7tui", version: "0.0.1" },
  });
  const [completeWalletSetup] = useMutation(COMPLETE_WALLET_SETUP, {
    refetchQueries: ["Course"],
    onCompleted: (data) => {
      const isCourseNowComplete =
        data.completeWalletSetup.course.percentComplete === 100;

      if (!course?.areNormalLessonsComplete && isCourseNowComplete) {
        analytics.completeCourse(data.completeWalletSetup.course.id);
      }
    },
  });

  useEffect(() => {
    if (lessonId && isConnected && lessonData) {
      // FIXME: only send when lesson is not yet completed
      completeWalletSetup({ variables: { lessonId, version: "0.0.1" } });
    }
  }, [completeWalletSetup, isConnected, lessonData, lessonId]);

  if (lessonError) {
    return <Error />;
  }

  if (isLessonLoading) {
    return <Loading />;
  }

  if (params.provider === "MetaMask") {
    return (
      <>
        {lessonId ? (
          <ProgressMenuHeader
            currentLessonTitle={lessonData.lesson.title}
            headerStyle="px-4 lg:px-6"
            course={course}
            isLoading={isLoadingCurrentCourse}
            error={isErrorCurrentCourse}
            lessonMetaData={location?.state}
            isCenteredNavigator
            canNavigateForward={isConnected && !!nextLessonId}
          />
        ) : (
          <Header isDarkMode={isDarkMode} />
        )}
        <main
          className={classNames(
            "min-h-screen flex flex-col pb-6 px-4 lg:px-6",
            isDarkMode ? "dark" : "light"
          )}
        >
          <MetaMaskOverview
            title={lessonData.lesson.title}
            overview={getSectionByKey(lessonData?.lesson?.sections, "overview")}
          >
            {!isConnected && (
              <>
                <MetaMaskConnection
                  connection={getSectionByKey(
                    lessonData?.lesson?.sections,
                    "connect"
                  )}
                />
                <MetaMaskInstructions
                  sections={lessonData?.lesson?.sections[0].sections}
                />
              </>
            )}
          </MetaMaskOverview>
          {courseId && (
            <div
              className={classNames(
                "fixed bottom-0 left-0 flex w-full px-4 pb-4 pt-8 gap-4 justify-between",
                isDarkMode
                  ? "bg-gradient-bg-fade-dark md:bg-none"
                  : "bg-gradient-bg-fade-light md:bg-none"
              )}
            >
              {prevLessonId && (
                <Link
                  href={`/course/${courseId}/lesson/${prevLessonId}`}
                  asButton
                  styleType="outline"
                  text="Back"
                  iconName="arrowLeft"
                  testid="back"
                  additionalClasses="bg-transparent"
                />
              )}
              {isConnected && nextLessonId && (
                <Link
                  href={`/course/${courseId}/lesson/${nextLessonId}`}
                  asButton
                  styleType="secondary"
                  text="Continue"
                  iconName="arrowRight"
                  isIconFirst={false}
                  testid="continue"
                />
              )}
            </div>
          )}
        </main>
      </>
    );
  }
  return <Error message="Wallet provider not found" />;
}
