import React from "react";
import {
  Header,
  FooterSection,
  Link,
  GradientBorder,
  Accordion,
  Loading,
  Error,
  CourseList,
} from "../../components";
import { useCourses } from "../../hooks";

export default function AuthenticatedHomePage() {
  const { loading, error, courses } = useCourses();

  const hasUnlockedAnyCourse = courses?.find((c) => c.isUnlocked);

  const userCourses = courses?.filter(
    (courseData) => courseData.isUnlocked || courseData.percentComplete > 0
  );

  const MY_COURSES = "My Courses";
  const START_COURSES = "Find a course to start your learning journey";
  const FIND_COURSES = "Find courses to continue your learning journey";
  const VIEW_ALL_COURSES = "View All Courses";
  const PREMIUM_FEATURES = "Premium Features";
  const PREMIUM_FEATURES_DESC =
    "Your purchase of a course has unlocked premium help from Quickbeam staff and our community";
  const PREMIUM_FEATURES_PREVIEW_DESC =
    "Purchase a course to unlock premium help from Quickbeam staff and our community";
  const PREMIUM_DISCORD = "Premium Discord";
  const PREMIUM_DISCORD_DESC =
    "Link your Quickbeam account to Discord and get access to private channels in our Discord server.";
  const PREVIEW_PREMIUM_DISCORD_DESC =
    "Purchase a course to get access to private channels in the Quickbeam Discord."; // TODO: update Discord premium feature and preview premium feature copy when premium channels are available
  const MENTOR_SESSION = "One-on-one Mentor Session";
  const MENTOR_SESSION_DESC =
    "Each purchase of a course unlocks 1 hour private mentoring sessions with a seasoned Quickbeam instructor.";
  const PREVIEW_MENTOR_SESSION_DESC =
    "Purchase a course to unlock 1 hour private mentoring sessions with a seasoned Quickbeam instructor.";
  const WEEKLY_OFFICE_HOURS = "Weekly Office Hours";
  const WEEKLY_OFFICE_HOURS_DESC =
    "Every week you have the option to attend a real time question and answer session with a Quickbeam instructor.";
  const WEEKLY_OFFICE_HOURS_DESC_2 =
    "Office hours are held via a private Zoom meeting every Friday at 4pm (Central Time Zone).";
  const VIEW_PREMIUM_DISCORD = "View Premium Discord";
  const VIEW_PREMIUM_DISCORD_HREF = import.meta.env
    .VITE_DISCORD_FREE_SUPPORT_CHANNEL_URL;
  const SCHEDULE_SESSION = "Schedule Session";
  const SCHEDULE_SESSION_HREF = import.meta.env.VITE_MENTOR_SESSION_URL;
  const ZOOM_LINK = "Zoom link";
  const ZOOM_LINK_HREF = "";

  const premiumFeatures = [
    {
      title: MENTOR_SESSION,
      content: [
        {
          type: "text",
          value: MENTOR_SESSION_DESC,
        },
        {
          type: "link",
          value: { text: SCHEDULE_SESSION, href: SCHEDULE_SESSION_HREF },
        },
      ],
    },
    {
      title: PREMIUM_DISCORD,
      content: [
        {
          type: "text",
          value: PREMIUM_DISCORD_DESC,
        },
        {
          type: "link",
          value: {
            text: VIEW_PREMIUM_DISCORD,
            href: VIEW_PREMIUM_DISCORD_HREF,
          },
        },
      ],
    },

    // Add back in later
    // {
    //   title: WEEKLY_OFFICE_HOURS,
    //   content: [
    //     {
    //       type: "text",
    //       value: WEEKLY_OFFICE_HOURS_DESC,
    //     },
    //     {
    //       type: "text",
    //       value: WEEKLY_OFFICE_HOURS_DESC_2,
    //     },
    //     {
    //       type: "link",
    //       value: { text: ZOOM_LINK, href: ZOOM_LINK_HREF },
    //     },
    //   ],
    // },
  ];

  const previewPremiumFeatures = [
    {
      title: MENTOR_SESSION,
      content: [
        {
          type: "text",
          value: PREVIEW_MENTOR_SESSION_DESC,
        },
      ],
    },
    {
      title: PREMIUM_DISCORD,
      content: [
        {
          type: "text",
          value: PREMIUM_DISCORD_DESC, // TODO: switch to preview premium features copy when premium channels are available
        },
      ],
    },

    // Add back in later
    // {
    //   title: WEEKLY_OFFICE_HOURS,
    //   content: [
    //     {
    //       type: "text",
    //       value: WEEKLY_OFFICE_HOURS_DESC,
    //     },
    //     {
    //       type: "text",
    //       value: WEEKLY_OFFICE_HOURS_DESC_2,
    //     },
    //     {
    //       type: "link",
    //       value: { text: ZOOM_LINK, href: ZOOM_LINK_HREF },
    //     },
    //   ],
    // },
  ];

  const getCourses = () => {
    if (loading) {
      return <Loading />;
    }

    if (error) {
      return <Error errorDetails={error} />;
    }

    return (
      <CourseList
        courses={userCourses}
        additionalClasses="pb-4 gap-4 md:pb-6 md:gap-6"
      />
    );
  };

  return (
    <div className="flex flex-col min-h-screen justify-between bg-custom-cream">
      <div className="bg-gradient-white-orange-reverse-light">
        <Header isDarkMode={false} loginButtonStyle="secondary" />
        <div className="flex flex-col justify-center max-w-[1440px] self-center px-4 pt-4 pb-20 md:grid md:grid-cols-3 md:gap-16 md:px-10 md:pt-[7.5rem] md:pb-[7.5rem] lg:gap-14 xl:px-40">
          <section className="pb-10 col-span-2">
            <h2 className="text-headlines-h2-mobile text-custom-black font-bold pb-6 md:text-headlines-h2">
              {MY_COURSES}
            </h2>
            {getCourses()}
            <GradientBorder
              backgroundColor="bg-custom-green-slate"
              gradientBorderClass="bg-gradient-green-light"
              rounded="rounded-lg"
              additionalClassesForInner="flex-col md:flex-row md:items-center md:justify-between p-4 gap-6"
            >
              <p data-testid="viewCoursesMsg">
                {userCourses?.length ? FIND_COURSES : START_COURSES}
              </p>
              {/* ^ needs to be calculated from user courses */}
              <Link
                asButton
                href="/courses"
                text={VIEW_ALL_COURSES}
                styleType="secondary"
                size="small"
                additionalClasses="w-fit whitespace-nowrap"
                testid="viewAllCourses"
              />
            </GradientBorder>
          </section>
          <section className="text-custom-black col-span-1">
            <h2 className="text-headlines-h2-mobile font-bold md:text-headlines-h3">
              {PREMIUM_FEATURES}
            </h2>
            <h4
              className="border-b border-b-custom-gray-100 text-custom-gray-400 pb-4"
              data-testid="premiumFeaturesMsg"
            >
              {hasUnlockedAnyCourse
                ? PREMIUM_FEATURES_DESC
                : PREMIUM_FEATURES_PREVIEW_DESC}
            </h4>
            <Accordion
              items={
                hasUnlockedAnyCourse ? premiumFeatures : previewPremiumFeatures
              }
              themeOverride="light"
            />
          </section>
        </div>
      </div>
      <div className="bg-custom-green-dark flex justify-center">
        <div className="w-full max-w-[1440px]">
          <FooterSection />
        </div>
      </div>
    </div>
  );
}
