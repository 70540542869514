import React, { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useLocalStorage } from "../hooks";

const AUTH_TOKEN_KEY = "accTok";
const REFRESH_TOKEN_KEY = "refTok";

const getUser = (authToken) => {
  if (!authToken) return null;

  const parsedToken = JSON.parse(window.atob(authToken.split(".")[1]));
  return {
    id: parsedToken.id,
    email: parsedToken.email,
    fullName: parsedToken.full_name,
    preferredName: parsedToken.full_name, // We don't yet have a preferred name, but we want this to be the interface, even if the value isn't yet exactly what it should be
  };
};

export const Context = createContext();

export function AuthContext({ children }) {
  const [authToken, setLocalAuthToken] = useLocalStorage(AUTH_TOKEN_KEY, null);
  const [refreshToken, setLocalRefreshToken] = useLocalStorage(
    REFRESH_TOKEN_KEY,
    null
  );

  const user = getUser(authToken);

  const logIn = (newAuthToken, newRefreshToken) => {
    setLocalAuthToken(newAuthToken);
    setLocalRefreshToken(newRefreshToken);
  };

  const logOut = () => {
    setLocalAuthToken(null);
    setLocalRefreshToken(null);
  };

  const handlers = {
    logIn,
    logOut,
  };

  const combinedValues = useMemo(
    () => ({
      user,
      authToken,
      refreshToken,
      handlers,
    }),
    [user, authToken, refreshToken]
  );

  return <Context.Provider value={combinedValues}>{children}</Context.Provider>;
}

export const authentication = () => {
  const values = useContext(Context);

  if (values === null) {
    throw new Error("not used in the AuthProvider");
  }

  const { user, handlers } = values;

  return {
    user: user,
    isAuthenticated: !!user,
    logIn: handlers.logIn,
    logOut: handlers.logOut,
  };
};

export const getAuthAndRefreshTokens = () => ({
  authToken: JSON.parse(localStorage.getItem(AUTH_TOKEN_KEY) || null),
  refreshToken: JSON.parse(localStorage.getItem(REFRESH_TOKEN_KEY) || null),
});

// "dangerous" because it directly sets the local storage as a side effect, but there are cases we need this outside of React
// The proper way is to use logIn() from the context provider.
export const dangerousLogIn = (newAuthToken, newRefreshToken) => {
  // stringify values since useLocalStorage expects value to be a JSON string
  localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(newAuthToken));
  localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(newRefreshToken));
};

// "dangerous" because it directly sets the local storage as a side effect, but there are cases we need this outside of React
// The proper way is to use logOut() from the context provider.
export const dangerousLogOut = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

AuthContext.propTypes = {
  children: PropTypes.node.isRequired,
};
