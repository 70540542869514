import React, { useState } from "react";
import PropTypes from "prop-types";
import { DropdownButton, Link, Popover, PopoverMenuItem } from "../index";

export default function HeaderLinks({ isDarkMode }) {
  const COURSES_LINK = "Courses";
  const ABOUT_US = "About Us";
  const COMMUNITY = "Community";
  const RESOURCES = "Resources";
  const MANIFESTO = "Manifesto";
  const LEARNING_ENVIRONMENT = "Learning Environment";
  const TEAM_LINK = "The Team";

  const [isMenuOpen, setIsMenuOpen] = useState(false); // FIXME: clicking outside of widget does not close menu
  const linkColor = isDarkMode ? "custom-white" : "primary-green";

  const discordSupportPath = import.meta.env
    .VITE_DISCORD_FREE_SUPPORT_CHANNEL_URL;

  const aboutDropdownItems = () => (
    <>
      <PopoverMenuItem
        href="/about"
        onClick={() => setIsMenuOpen(false)}
        text={ABOUT_US}
        testid="aboutUsLink"
        isDarkMode={isDarkMode}
      />
      <PopoverMenuItem
        href="/team"
        onClick={() => setIsMenuOpen(false)}
        text={TEAM_LINK}
        testid="teamLink"
        isDarkMode={isDarkMode}
      />
      <PopoverMenuItem
        href="/manifesto"
        onClick={() => setIsMenuOpen(false)}
        text={MANIFESTO}
        testid="manifestoLink"
        isDarkMode={isDarkMode}
      />
      <PopoverMenuItem
        href="/learning-environment"
        onClick={() => setIsMenuOpen(false)}
        text={LEARNING_ENVIRONMENT}
        testid="learningEnvironmentLink"
        isDarkMode={isDarkMode}
      />
    </>
  );

  const button = () => (
    <DropdownButton
      text={ABOUT_US}
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      isDarkMode={isDarkMode}
      isFullWidth
      additionalClasses="uppercase whitespace-nowrap"
    />
  );

  return (
    <>
      <Link
        href="/courses"
        text={COURSES_LINK}
        customLinkColor={linkColor}
        size="small"
        additionalClasses="uppercase font-bold"
      />
      <Link
        href="/resources"
        text={RESOURCES}
        customLinkColor={linkColor}
        size="small"
        additionalClasses="uppercase font-bold"
      />
      <Popover
        button={button}
        items={aboutDropdownItems}
        isMenuOpen={isMenuOpen}
        testId="aboutDropdown"
        menuPosition="bottom"
      />
      <Link
        href={discordSupportPath}
        text={COMMUNITY}
        customLinkColor={linkColor}
        size="small"
        additionalClasses="uppercase font-bold"
      />
    </>
  );
}

HeaderLinks.propTypes = {
  isDarkMode: PropTypes.bool.isRequired,
};
