import { gql } from "@apollo/client";

const LOG_IN_OAUTH = gql`
  mutation loginOauth(
    $code: String!
    $clientId: String!
    $redirectUri: String!
  ) {
    loginOauth(code: $code, clientId: $clientId, redirectUri: $redirectUri) {
      authToken
      refreshToken
      accountCreated
    }
  }
`;

const CONNECT_DISCORD_ACCOUNT = gql`
  mutation connectDiscordAccount(
    $code: String!
    $clientId: String!
    $redirectUri: String!
  ) {
    connectDiscordAccount(
      code: $code
      clientId: $clientId
      redirectUri: $redirectUri
    )
  }
`;

const REFRESH_AUTH_TOKEN = gql`
  mutation refreshAuthToken($refreshToken: String!, $authToken: String!) {
    refreshAuthToken(refreshToken: $refreshToken, authToken: $authToken)
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { LOG_IN_OAUTH, REFRESH_AUTH_TOKEN, CONNECT_DISCORD_ACCOUNT };
