import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link as RouterLink } from "react-router-dom";
import BaseHeader from "./BaseHeader";
import HeaderLinks from "./HeaderLinks";
import AccountSection from "../accountSection";
import SiteMenu from "./SiteMenu";
import Icon from "../icon";
import { ReactComponent as Logo } from "../../assets/svgs/logo.svg";

export default function Header({
  isDarkMode,
  noSidePadding,
  noTopPadding,
  logoOnly,
  centeredContentText,
  loginButtonStyle,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const defaultXPadding = "px-4 lg:px-12";

  const siteMenu = () => (
    <SiteMenu setIsMenuOpen={setIsMenuOpen} isDarkMode={isDarkMode} />
  );
  const logo = () => (
    <RouterLink to="/" data-testid="quickbeamFullLogo">
      <Logo
        className={classNames(
          "h-6",
          isDarkMode ? "fill-custom-white" : "fill-primary-green"
        )}
      />
    </RouterLink>
  );

  const startContent = () =>
    logoOnly ? (
      logo()
    ) : (
      <>
        <div className="flex">
          <Icon
            iconName="hamburger"
            fill={classNames(
              isDarkMode ? "fill-custom-white" : "fill-primary-green"
            )}
            ariaLabel="open the menu"
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
            additionalClasses={classNames("mr-2 lg:mr-4 lg:hidden")}
          />
          {logo()}
        </div>
        <nav className="flex items-center space-x-4 md:space-x-8 lg:space-x-20 hidden lg:inline-flex">
          <HeaderLinks isDarkMode={isDarkMode} />
        </nav>
      </>
    );

  const centeredContent = () => {
    if (logoOnly || !centeredContentText) {
      return null;
    }
    return <p>{centeredContentText}</p>;
  };

  const endContent = () => (
    <div className="hidden lg:inline-flex">
      <AccountSection
        loginButtonStyle={loginButtonStyle}
        isDarkMode={isDarkMode}
      />
    </div>
  );

  return (
    <BaseHeader
      startContent={startContent}
      centeredContent={centeredContent}
      endContent={logoOnly ? null : endContent}
      menu={logoOnly ? null : siteMenu}
      isMenuOpen={isMenuOpen}
      headerStyle={classNames(
        { [defaultXPadding]: !noSidePadding },
        { "min-h-0 lg:min-h-0": noTopPadding }
      )}
    />
  );
}

Header.propTypes = {
  noSidePadding: PropTypes.bool,
  noTopPadding: PropTypes.bool,
  logoOnly: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  centeredContentText: PropTypes.string,
  loginButtonStyle: PropTypes.oneOf([
    "primary",
    "secondary",
    "outline",
    "outline-white",
    "gray",
    "none",
  ]),
};

Header.defaultProps = {
  noSidePadding: false,
  noTopPadding: false,
  logoOnly: false,
  isDarkMode: true,
  centeredContentText: null,
};
