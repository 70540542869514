import React from "react";
import PropTypes from "prop-types";
import { authentication } from "../../context";
import { AccountDropdown } from "../accountDropdown";
import SignInSignUpButtons from "./SignInSignUpButtons";

export default function AccountSection({
  menuPosition,
  isFullWidth,
  logoutOnClick,
  loginButtonStyle,
  isIconOnly,
  isDarkMode,
}) {
  const { isAuthenticated } = authentication();

  return isAuthenticated ? (
    <AccountDropdown
      menuPosition={menuPosition}
      isFullWidth={isFullWidth}
      logoutOnClick={logoutOnClick}
      isIconOnly={isIconOnly}
      isDarkMode={isDarkMode}
    />
  ) : (
    <SignInSignUpButtons loginButtonStyle={loginButtonStyle} />
  );
}

AccountSection.propTypes = {
  menuPosition: PropTypes.oneOf(["top", "bottom"]),
  isFullWidth: PropTypes.bool,
  isIconOnly: PropTypes.bool,
  logoutOnClick: PropTypes.func,
  loginButtonStyle: PropTypes.string,
  isDarkMode: PropTypes.bool.isRequired,
};

AccountSection.defaultProps = {
  logoutOnClick: () => {},
};
