import React from "react";
import { Link } from "../../components";
import { ReactComponent as TeamsImage } from "../../assets/svgs/landingPage/teams.svg";

const TAKE_YOUR_BUSINESS_TO_WEB3 = "Take your business to Web3";
const QUICKBEAM_FOR_TEAMS = "Quickbeam for teams";
const TEAMS_TAGLINE =
  "Need to embrace Blockchain technologies on your team? Let us help you bridge the gap internally. From in-browser learning modules to in-person cohort bootcamps we'll walk the learning path with your team.";
const SCHEDULE_CALL_CTA = "Schedule a Call";

export default function TeamsSection() {
  return (
    <div
      id="quickbeam-for-teams"
      className="self-center grid gap-9 grid-cols-1 md:grid-cols-2 px-5 md:px-20 py-20 md:py-28 items-center w-full max-w-[1440px]"
    >
      <div className="relative">
        <p className="md:hidden mb-2 uppercase text-center text-transparent bg-clip-text text-body-2-condensed font-bold bg-gradient-green-light">
          {TAKE_YOUR_BUSINESS_TO_WEB3}
        </p>
        <TeamsImage className="opacity-30 md:opacity-100" />
        <h2 className="block md:hidden absolute font-bold text-custom-cream text-5xl sm:text-6xl leading-tight text-center mx-auto left-0 right-0 [top:43%] max-w-sm">
          {QUICKBEAM_FOR_TEAMS}
        </h2>
      </div>

      <div className="text-center md:text-left flex flex-col items-center md:items-start max-w-[550px]">
        <p className="hidden md:block mb-2 uppercase text-center text-transparent bg-clip-text text-body-2-condensed font-bold bg-gradient-green-light">
          {TAKE_YOUR_BUSINESS_TO_WEB3}
        </p>
        <h2 className="hidden md:block mb-3.5 md:text-5xl lg:text-7xl font-bold text-custom-cream">
          {QUICKBEAM_FOR_TEAMS}
        </h2>
        <p className="mb-10 text-custom-green-light text-body-1">
          {TEAMS_TAGLINE}
        </p>
        <Link
          href="https://calendly.com/heathercorallo/quickbeam-for-teams"
          asButton
          text={SCHEDULE_CALL_CTA}
        />
      </div>
    </div>
  );
}
