import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "../../components";

export default function CourseIncludesSection({
  headerText,
  items,
  containerStyles,
}) {
  const courseIncludesContent = items.map((item) => (
    <div className="flex gap-4 mb-6" key={item.text}>
      <Icon
        iconName={item.iconName}
        size="large"
        additionalClasses="shrink-0"
        styleType="primary"
      />
      <p className="text-body-2-condensed md:text-body-2">{item.text}</p>
    </div>
  ));

  return (
    <div
      className={classNames(
        "bg-primary-green text-custom-cream py-12 px-4",
        containerStyles
      )}
    >
      <p className="text-headlines-h4-mobile font-bold uppercase mb-6 md:text-headlines-h4">
        {headerText}
      </p>
      {courseIncludesContent}
    </div>
  );
}

CourseIncludesSection.propTypes = {
  headerText: PropTypes.string,
  items: PropTypes.array,
  containerStyles: PropTypes.string,
};
