import React from "react";
import Link from "../link";

export default function CommunityIcons() {
  const discordSupportPath = import.meta.env
    .VITE_DISCORD_FREE_SUPPORT_CHANNEL_URL;

  return (
    <div className="flex gap-1" data-testid="communityIcons">
      <Link
        href={discordSupportPath}
        isIconOnly
        iconName="discord"
        styleType="gray"
        ariaLabel="Discord icon"
      />
      <Link
        href="https://twitter.com/QuickbeamPBC"
        isIconOnly
        iconName="twitter"
        styleType="gray"
        ariaLabel="Twitter icon"
      />
    </div>
  );
}
