import React, { createContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useNavigator } from "../hooks";

export const Context = createContext();

export function NavigatorContext({ children }) {
  const { browserName, isMobile } = useNavigator();
  const values = useMemo(
    () => ({ browserName, isMobile }),
    [browserName, isMobile]
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
}

export default function navigator() {
  const context = React.useContext(Context);
  if (!Context && context === undefined) {
    throw new Error("useNavigator must be used within a Provider");
  }
  return context;
}

NavigatorContext.propTypes = {
  children: PropTypes.any.isRequired,
};
