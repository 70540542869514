// because different browsers parse URLs with unknown protocols differently:
// https://redsquirreltech.slack.com/archives/C03K5KS595M/p1669652562612129
import Url from "url-parse";

export default function internalLink(urlString) {
  const url = new Url(urlString, window.location.href);

  // for some reason, it comes in with the colon on the end of it. IDK why, and
  // IDK if it will always be there, so we'll remove it if it is there. Some
  // more info about it that I didn't read: https://github.com/nodejs/node/pull/1237
  let type = url.protocol;
  if (type.slice(-1) === ":") type = type.slice(0, -1);

  switch (type) {
    case "article":
      return {
        type,
        path: url.host + url.pathname,
      };
    case "jargon":
      return {
        type,
        path: url.host + url.pathname,
      };
    default:
      return urlString;
  }
}
