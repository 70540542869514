/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import { useDeepDive } from "../../context";
import { Article, Icon, Jargon } from "..";
import internalLink from "./internalLink";

function isCustomElement({ element }) {
  let bool = false;
  bool =
    element.key?.includes("waiting-list") || element.key?.includes("avatar");
  return bool;
}

function ModifiedH1({ children, ...props }) {
  return (
    <>
      <h1 className={classNames("pb-0 h1", props.additionalClasses)}>
        {children}
      </h1>
      <hr
        className={classNames("my-10 w-full", {
          "border-custom-gray-300": props.isDarkMode,
          "border-custom-gray-100": !props.isDarkMode,
        })}
      />
    </>
  );
}

function ModifiedH2({ children, ...props }) {
  return (
    <h2 className={classNames("pb-0 h2", props.additionalClasses)}>
      {children}
    </h2>
  );
}

function ModifiedH3({ children, ...props }) {
  return (
    <h3 className={classNames("mb-2 mt-11 h3", props.additionalClasses)}>
      {children}
    </h3>
  );
}

function ModifiedH4({ children }) {
  return <h4 className="h4">{children}</h4>;
}

function ModifiedH5({ children }) {
  return <h5 className="text-headlines-h3 mt-2">{children}</h5>;
}

function ModifiedHR() {
  return <hr className="border-custom-gray-300 my-10" />;
}

function ModifiedA({ children, href }) {
  const deepDive = useDeepDive();
  const link = internalLink(href);

  function staticContentLink({ onClick }) {
    return (
      <button
        type="button"
        className="underline !m-0"
        onClick={onClick}
        data-testid="internalLink"
      >
        {children}
      </button>
    );
  }

  switch (link?.type) {
    case "article":
      return staticContentLink({
        children,
        onClick: () => deepDive.open(<Article path={link.path} />),
      });

    case "jargon":
      return staticContentLink({
        children,
        onClick: () => deepDive.open(<Jargon path={link.path} />),
      });

    default:
      return (
        <a
          data-testid="defaultMarkdownLink"
          className="text-primary-green cursor-pointer"
          href={href}
          target={href.includes("http") ? "_blank" : undefined}
          rel="noreferrer"
        >
          {children}
        </a>
      );
  }
}

function ModifiedLi({ children, ...props }) {
  if (props.additionalClasses === "list-star") {
    return (
      <div className="flex">
        <div className="flex justify-center align-center relative w-6 mt-2">
          <span className="absolute w-6 h-6 rounded-full bg-gradient-orange-yellow blur-md" />
          {/* TODO: Try to add gradient either as clip path or mask */}
          <Icon
            iconName="star"
            fill="fill-[#FF320A]"
            ariaLabel="star icon"
            size="small"
          />
        </div>
        <li
          className={classNames("!m-0 pl-4 list-none", props.additionalClasses)}
        >
          {children}
        </li>
      </div>
    );
  }
  return <li className="!my-0">{children}</li>;
}

function ModifiedP({ children, ...props }) {
  // Our custom components come wrapped in p tags, and this will remove that.
  if (children?.length > 0 && isCustomElement({ element: children[0] })) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  // There are rare occasions, like the quiz question, where the markdown is not designated,
  // so it comes in as a paragraph, but should be a different type, so we will catch, correct, and return it here.
  if (props.additionalClasses === "h2") {
    return ModifiedH2({ children });
  }

  return (
    <p className={classNames("body-2", props.additionalClasses)}>{children}</p>
  );
}

function ModifiedUl({ children }) {
  return <ul className="!my-0">{children}</ul>;
}

export {
  ModifiedA,
  ModifiedH1,
  ModifiedH2,
  ModifiedH3,
  ModifiedH4,
  ModifiedH5,
  ModifiedHR,
  ModifiedLi,
  ModifiedP,
  ModifiedUl,
};
