import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export default function GradientBorder({
  children,
  gradientBorderClass,
  backgroundColor,
  rounded,
  testid,
  additionalClassesForOuter,
  additionalClassesForInner,
}) {
  return (
    <div
      className={classNames(
        gradientBorderClass,
        "p-px",
        rounded,
        additionalClassesForOuter
      )}
    >
      <div
        className={classNames(
          backgroundColor,
          rounded,
          "flex justify-center gap-2",
          additionalClassesForInner
        )}
        data-testid={testid}
      >
        {children}
      </div>
    </div>
  );
}

GradientBorder.propTypes = {
  children: PropTypes.any,
  gradientBorderClass: PropTypes.string,
  backgroundColor: PropTypes.string,
  rounded: PropTypes.oneOf([
    "rounded-none",
    "rounded",
    "rounded-sm",
    "rounded-md",
    "rounded-lg",
    "rounded-full",
    "rounded-t-[3.5rem]",
  ]),
  testid: PropTypes.string,
  // These are mostly for the widget
  additionalClassesForOuter: PropTypes.string,
  additionalClassesForInner: PropTypes.string,
};

GradientBorder.defaultProps = {
  additionalClassesForInner: "px-4 py-2",
};
