export const INTERMEDIATE_COURSE_TITLE =
  "Intermediate Solidity and Ethers: Smart Contract Design";
export const INTERMEDIATE_MARKETING_COURSE_DESCRIPTION =
  "Take the next step in your Ethereum development journey. Building on our Introduction to Solidity and Ethers course, you will begin learning techniques used every day by leading smart contract developers.";
export const INTERMEDIATE_EXTENDED_COURSE_DESCRIPTION_1 =
  "Leverage your initial understanding of blockchain development to bolster your Solidity and Ethers knowledge. You already know the basics of decentralized applications and smart contracts on the Ethereum blockchain, and now it’s time to reach the next level of seniority.";
export const INTERMEDIATE_EXTENDED_COURSE_DESCRIPTION_2 =
  "This course is designed to progress you toward becoming an expert in Ethereum and Solidity development. You'll get introduced to the industry-standard OpenZeppelin smart contract ecosystem and read real-time blockchain data updates by using web sockets to subscribe to Ethereum events -- all while developing the beginnings of a basic prediction market.";
export const INTERMEDIATE_EXTENDED_COURSE_DESCRIPTION_3 =
  "Don't miss out on this opportunity to become a blockchain developer. Enroll in our Intermediate Solidity and Ethers course now and continue building the decentralized future.";
export const INTERMEDIATE_PREREQUISITE = "Prerequisite:";
export const INTERMEDIATE_PREREQUISITE_DESC =
  "Introduction to Solidity and Ethers course, or equivalent experience with basic Solidity data types, mappings, structs, events, functions, as well as experience with Ethers and Node.js. Understanding the Ethereum transaction lifecycle and the role of gas in the protocol.";
export const INTERMEDIATE_COURSE_NUM_BREAKDOWN =
  "7 Units - 28 Lessons (6-12 hours to complete)";
const UNIT1 = "Unit 1 - Interfaces and Modifiers";
const UNIT1_DESC = "TBD";
const UNIT2 = "Unit 2 - OpenZeppelin, Imports, Overflows, and Using Libraries";
const UNIT2_DESC = "TBD";
const UNIT3 = "Unit 3 - External Functions and Arrays";
const UNIT3_DESC = "TBD";
const UNIT4 = "Unit 4 - Enums and Ethers Utilities";
const UNIT4_DESC = "TBD";
const UNIT5 = "Unit 5 - Sending Ether to a Smart Contract";
const UNIT5_DESC = "TBD";
const UNIT6 = "Unit 6 - Events via Web Sockets";
const UNIT6_DESC = "TBD";
const WHO_IS_THIS_COURSE_FOR = "Who is this course for?";
const WHO_IS_THIS_COURSE_FOR_ANS =
  "Software developers interested in the Ethereum ecosystem.";
const COURSE_FOR_BEGINNERS = "Is this course suitable for beginners?";
const COURSE_FOR_BEGINNERS_ANS =
  "Our course is suitable for software developers at the beginning of their career. Additionally, this course expects learners to have a firm grasp on the basics of Solidity and Ethers. We recommend that learners take our Introduction to Solidity and Ethers course before advancing to the Intermediate course.";
const CAREER_OPPORTUNITIES_PREP =
  "What type of career opportunities would this course prepare me for?";
const CAREER_OPPORTUNITIES_PREP_ANS =
  "Using this course as a foundation, you could rapidly work through our more advanced courses that prepare you for career opportunities such as Ethereum Developer, Blockchain Developer, and Smart Contract Developer across many business sectors.";
const HOW_LONG_COURSE = "How long will this course take to complete?";
const HOW_LONG_COURSE_ANS =
  "The duration will vary based on your prior experience and how much focus you dedicate to it, but expect to spend at least 6-12 hours of time, likely across several days or weeks.";

export const intermediateSoliditySyllabus = [
  {
    title: UNIT1,
    content: [
      {
        type: "text",
        value: UNIT1_DESC,
      },
    ],
  },
  {
    title: UNIT2,
    content: [
      {
        type: "text",
        value: UNIT2_DESC,
      },
    ],
  },
  {
    title: UNIT3,
    content: [
      {
        type: "text",
        value: UNIT3_DESC,
      },
    ],
  },
  {
    title: UNIT4,
    content: [
      {
        type: "text",
        value: UNIT4_DESC,
      },
    ],
  },
  {
    title: UNIT5,
    content: [
      {
        type: "text",
        value: UNIT5_DESC,
      },
    ],
  },
  {
    title: UNIT6,
    content: [
      {
        type: "text",
        value: UNIT6_DESC,
      },
    ],
  },
];

export const intermediateFaqs = [
  {
    title: WHO_IS_THIS_COURSE_FOR,
    content: [
      {
        type: "text",
        value: WHO_IS_THIS_COURSE_FOR_ANS,
      },
    ],
  },
  {
    title: COURSE_FOR_BEGINNERS,
    content: [
      {
        type: "text",
        value: COURSE_FOR_BEGINNERS_ANS,
      },
    ],
  },
  {
    title: CAREER_OPPORTUNITIES_PREP,
    content: [
      {
        type: "text",
        value: CAREER_OPPORTUNITIES_PREP_ANS,
      },
    ],
  },
  {
    title: HOW_LONG_COURSE,
    content: [
      {
        type: "text",
        value: HOW_LONG_COURSE_ANS,
      },
    ],
  },
];
