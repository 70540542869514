import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

export default function PopoverMenu({ show, children, testId, position }) {
  // if displaying menu on top, position the bottom to 100%; if displaying on bottom, position the top to 100%
  const positions = {
    top: "bottom-full",
    bottom: "top-full",
  };
  const margins = {
    top: "mb-1",
    bottom: "mt-1",
  };
  return (
    show && (
      <div
        data-testid={testId}
        className={classNames(
          "flex flex-col border border-primary-green rounded-sm absolute p-4 gap-3 light",
          positions[position],
          margins[position]
        )}
      >
        {children}
      </div>
    )
  );
}

PopoverMenu.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.any,
  testId: PropTypes.string,
  position: PropTypes.oneOf(["top", "bottom"]),
};

PopoverMenu.defaultProps = {
  position: "top",
};
