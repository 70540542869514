// MDN: https://developer.mozilla.org/en-US/docs/Web/API/Navigator
// Just a really simple and ripped straight from MDN example that only returns browser for now.
// We could continue with this to know more about the user, but we might also opt to use
// a service, which we could possibly plug into this hook later.

function getBrowserName(userAgent) {
  // The order matters here, and this may report false positives for unlisted browsers.
  let isMobile = false;

  if (userAgent.includes("iPhone") || userAgent.includes("Android")) {
    isMobile = true;
  }

  if (userAgent.includes("Firefox")) {
    // "Mozilla/5.0 (X11; Linux i686; rv:104.0) Gecko/20100101 Firefox/104.0"
    return { browserName: "Mozilla Firefox", isMobile };
  }
  if (userAgent.includes("SamsungBrowser")) {
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
    return { browserName: "Samsung Internet", isMobile };
  }
  if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_5_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
    return { browserName: "Opera", isMobile };
  }
  if (userAgent.includes("Trident")) {
    // "Mozilla/4.0 (compatible; MSIE 7.0; Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; .NET CLR 2.0.50727; .NET CLR 3.0.30729; .NET CLR 3.5.30729)"
    return { browserName: "Microsoft Internet Explorer", isMobile };
  }
  if (userAgent.includes("Edge")) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    return { browserName: "Microsoft Edge (Legacy)", isMobile };
  }
  if (userAgent.includes("Edg")) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 Edg/104.0.1293.70"
    return { browserName: "Microsoft Edge (Chromium)", isMobile };
  }
  if (userAgent.includes("Chrome")) {
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
    return { browserName: "Google Chrome or Chromium", isMobile };
  }
  if (userAgent.includes("Safari")) {
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1"
    return { browserName: "Apple Safari", isMobile };
  }
  return { browserName: "unknown", isMobile };
}
const useNavigator = () => {
  const { browserName, isMobile } = getBrowserName(navigator.userAgent);
  return { browserName, isMobile };
};

export default useNavigator;
