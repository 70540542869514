import { BASE_OAUTH_CONFIG } from "./base";

export const OAUTH_PROVIDER_GOOGLE_NAME = "google";

export const GOOGLE_AUTH_URL = "https://accounts.google.com/o/oauth2/v2/auth";

export const GOOGLE_OAUTH_LOGIN_CONFIG = {
  ...BASE_OAUTH_CONFIG,
  client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
  redirect_uri: import.meta.env.VITE_GOOGLE_REDIRECT_URI,
  scope: "email openid profile",
};
