/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { SubscribeForm } from "..";

export default function WaitingList({ "course-id": courseId }) {
  return <SubscribeForm formType="courseInterest" courseId={courseId} />;
}

WaitingList.propTypes = {
  "course-id": PropTypes.string.isRequired,
};
