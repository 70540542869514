import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { CodeMirror, Button, Icon, Link, Loading } from "../index";
import { lesson, theme, navigator } from "../../context";

export default function Editor({
  useFiles,
  useActiveTabState,
  useFullScreenState,
  run,
  reset,
  isOutputOpen,
  isOutputSuccessful,
  isEvaluating,
}) {
  const files = useFiles();
  const [isFullScreenMode, setFullScreenMode] = useFullScreenState;
  const [activeTab, setActiveTab] = useActiveTabState;
  const [currentTheme, setTheme] = theme();
  const {
    lessonValue: { prevLessonId, nextLessonId, courseId },
    navigateToNextLesson,
  } = lesson() || {};
  const { isMobile } = navigator();

  const isDarkTheme = currentTheme === "dark";
  const showMobileStyles = isMobile || window.innerWidth < 640;

  const doRun = () => {
    const filesToSubmit = Object.fromEntries(
      files.map((f) => [f.path, f.body])
    );
    run(filesToSubmit);
  };

  const GET_HELP = "Get Help";

  return (
    <div
      aria-expanded={isFullScreenMode}
      className={classNames(
        "top-0",
        isDarkTheme ? "bg-editor-base03" : "bg-editor-base3",
        isOutputOpen ? "h-[60vh]" : "h-full",
        { "w-screen": isFullScreenMode },
        { relative: isOutputOpen }
      )}
    >
      <div
        className={classNames(
          "flex items-center justify-between pr-4",
          isDarkTheme ? "bg-black" : "bg-editor-base2"
        )}
      >
        <div>
          {files?.map((file) => (
            <button
              onClick={() => {
                setActiveTab(file.path);
              }}
              type="submit"
              className={classNames(
                "py-2 px-4",
                {
                  "text-white bg-editor-base03":
                    isDarkTheme && activeTab === file.path,
                },
                {
                  "text-gray-400 bg-editor-base3":
                    !isDarkTheme && activeTab === file.path,
                }
              )}
              aria-current={activeTab === file.path}
              aria-label={`focus ${file.path}`}
              key={file.path}
              data-testid={file.path.split(".")[0]}
            >
              {file.path}
            </button>
          ))}
        </div>
        <Icon
          onClick={() => {
            setFullScreenMode(!isFullScreenMode);
          }}
          ariaLabel="full screen"
          iconName={isFullScreenMode ? "collapse" : "expand"}
          fill="fill-custom-gray-400"
        />
      </div>
      {isEvaluating && (
        <>
          <span
            className={classNames(
              "flex absolute opacity-50 z-20 w-full",
              isFullScreenMode
                ? "top-0 h-full"
                : "h-1/2 top-1/2 md:w-1/2 md:h-full md:top-0 ",
              isDarkTheme ? "bg-editor-base03" : "bg-editor-base3"
            )}
          />
          <div
            className={classNames(
              "absolute z-50 -translate-x-1/2 md:top-1/2 left-1/2 -translate-y-1/2 opacity-100",
              isFullScreenMode ? "top-1/2" : "top-3/4 md:left-3/4"
            )}
          >
            <Loading />
          </div>
        </>
      )}
      {files?.map((file) => (
        <div
          key={file.path}
          className={classNames(
            activeTab === file.path ? "visible block" : "invisible hidden"
          )}
        >
          <CodeMirror
            key={file.path}
            filePath={file.path}
            language={file.language.toLowerCase()}
            isFullScreenMode={isFullScreenMode}
            useCodeState={[file.body, file.updateBody]}
            isReadonly={file.readonly}
            isDarkTheme={isDarkTheme}
            isOutputOpen={isOutputOpen}
            isEvaluating={isEvaluating}
          />
          <div
            className={classNames(
              "absolute right-0 bottom-0 z-10 flex justify-between p-3 w-full",
              isDarkTheme
                ? "bg-gradient-code-fade-dark border-y-custom-gray-400"
                : "bg-gradient-code-fade-light border-b-custom-gray-100",
              isOutputOpen ? "w-full border-b-solid border-b" : "md:w-1/2",
              { "md:w-full": isFullScreenMode }
            )}
          >
            <div className="flex items-center gap-4">
              <Button
                onClick={() => {
                  setTheme(isDarkTheme ? "light" : "dark");
                }}
                ariaLabel="toggle light/dark mode"
                isIconOnly
                iconName="themeToggle"
                styleType="gray"
                additionalClasses={
                  isDarkTheme ? "bg-custom-green-dark" : "bg-custom-cream"
                }
              />
              <Link
                asButton
                href={import.meta.env.VITE_DISCORD_FREE_SUPPORT_CHANNEL_URL}
                text={GET_HELP}
                ariaLabel="navigate to Discord"
                iconName="discord"
                styleType="gray"
                isIconOnly={window.innerWidth < 1217}
                additionalClasses={classNames(
                  "border border-custom-gray-400 font-normal whitespace-nowrap",
                  isDarkTheme ? "bg-custom-green-dark" : "bg-custom-cream"
                )}
              />
            </div>
            <div className="flex gap-3.5">
              {prevLessonId && (
                <Link
                  href={`/course/${courseId}/lesson/${prevLessonId}`}
                  asButton
                  styleType="outline"
                  text="Back"
                  iconName="arrowLeft"
                  testid="back"
                  isIconOnly={showMobileStyles}
                  ariaLabel="back"
                  additionalClasses={
                    isDarkTheme ? "bg-custom-green-dark" : "bg-custom-cream"
                  }
                />
              )}
              {nextLessonId && isOutputSuccessful && (
                <Button
                  onClick={navigateToNextLesson}
                  styleType="secondary"
                  iconName="arrowRight"
                  label="Continue"
                  isIconFirst={false}
                  testId="continue"
                  isIconOnly={showMobileStyles}
                  ariaLabel="continue"
                />
              )}
              <Button
                onClick={doRun}
                isDisabled={file.readonly}
                ariaLabel={`run code for ${file.path}`}
                disabled={file.readonly}
                iconName="play"
                label="Run"
                id="runCode"
                isIconOnly={showMobileStyles}
              />
            </div>
          </div>
          {/* <button
              onClick={reset}
              type="submit"
              className={classNames("bg-editor-base03 opacity-75 p-4 rounded", {
                "opacity-75": file.readonly,
              })}
              aria-label={`reset code for ${file.path}`}
              disabled={file.readonly}
            >
              <Reset className="fill-white" />
            </button>
            */}
        </div>
      ))}
    </div>
  );
}

Editor.propTypes = {
  useFiles: PropTypes.func.isRequired,
  useActiveTabState: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func])
  ).isRequired,
  useFullScreenState: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
  ).isRequired,
  run: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  isOutputOpen: PropTypes.bool.isRequired,
  isOutputSuccessful: PropTypes.bool,
  isEvaluating: PropTypes.bool.isRequired,
};
