import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Button, Link } from "../../components";
import { OAUTH_PROVIDER_GOOGLE_NAME } from "../../utils/auth/oauthConfigs";
import { redirectToOAuthProviderForLogin } from "../../utils/auth/oauthUtil";

function Login({ isSignUp }) {
  const { state } = useLocation();

  const onLoginButtonClick = (oauthProvider) => {
    redirectToOAuthProviderForLogin(oauthProvider, {
      postLoginPath: state?.postLoginPath || "/",
    });
  };

  const title = isSignUp ? "Create Account" : "Sign In";
  const description = isSignUp
    ? "Hey there! Welcome to Quickbeam. Link your Google account in just a few seconds, and then you'll have access to our ever-growing educational lesson content."
    : "Welcome back! Sign in to pick right back up where you left off.";
  const label = isSignUp ? "Sign up with Google" : "Sign in with Google";

  return (
    <section className="flex flex-col items-center min-h-screen mt-9 z-10 md:mt-24 md:w-full ">
      <div className="flex flex-col items-center gap-6 text-center mx-5 md:mx-0 md:w-2/5">
        <h2 className="text xl md:twoxl md:pb-1 gradient-light-green">
          {title}
        </h2>
        <hr className="border border-custom-gray-300 w-full" />
        <p>{description}</p>
        <Button
          onClick={() => onLoginButtonClick(OAUTH_PROVIDER_GOOGLE_NAME)}
          label={label}
          styleType="auth"
          iconName="googleLogo"
          isIconFirst
          isFullWidth
          ariaLabel={label}
          testId="google-login"
        />
        <hr className="border border-custom-gray-300 w-full" />
        <div>
          By continuing, you agree to Quickbeam&apos;s{" "}
          <Link
            href="/terms"
            text="Terms of Service"
            size="small"
            additionalClasses="inline-block"
            customLinkColor="custom-white"
            isUnderlined
          />{" "}
          &{" "}
          <Link
            href="/privacy"
            text="Privacy Policy"
            size="small"
            additionalClasses="inline-block"
            customLinkColor="custom-white"
            isUnderlined
          />
        </div>
      </div>
    </section>
  );
}

export default Login;

Login.propTypes = {
  isSignUp: PropTypes.bool,
};

Login.defaultProps = {
  isSignUp: false,
};
