/* eslint-disable react/no-children-prop */
import React from "react";
import PropTypes from "prop-types";
import {
  cancelPageText,
  ccpa,
  gdpr,
  privacyPolicy,
  termsOfUse,
} from "../../assets/markdown/legalText";
import { SupportPageLayout } from "../../components";

export default function LegalPage({ type }) {
  let content;
  switch (type) {
    case "privacy": {
      content = privacyPolicy;
      break;
    }
    case "terms": {
      content = termsOfUse;
      break;
    }
    case "cancel": {
      content = cancelPageText;
      break;
    }
    case "ccpa": {
      content = ccpa;
      break;
    }
    case "gdpr": {
      content = gdpr;
      break;
    }
    case "codeOfConduct": {
      content = "";
      break;
    }
    default:
      content = "";
  }

  return <SupportPageLayout content={content} testid={`legal-${type}`} />;
}

LegalPage.propTypes = {
  type: PropTypes.oneOf([
    "privacy",
    "terms",
    "cancel",
    "ccpa",
    "codeOfConduct",
    "gdpr",
  ]).isRequired,
};
