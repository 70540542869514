import React from "react";
import PropTypes from "prop-types";
import { AvailableIcons, Link } from "../../index";

export default function PopoverMenuItem({
  href,
  iconName,
  text,
  onClick,
  testid,
  isDarkMode,
}) {
  return (
    <Link
      onClick={onClick}
      href={href}
      iconName={iconName}
      text={text}
      size="small"
      additionalClasses="leading-6"
      customLinkColor={!isDarkMode ? "custom-green-slate" : "primary-green"}
      testid={testid}
    />
  );
}

PopoverMenuItem.propTypes = {
  href: PropTypes.string,
  iconName: PropTypes.oneOf(AvailableIcons),
  text: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
  isDarkMode: PropTypes.bool,
};
