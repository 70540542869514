import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { parseSearchParamsString } from "../utils/urlUtils";
import { OAuthStateManager } from "../utils/auth/sessionStateUtil";

const useOAuthCodeExchange = ({
  mutation,
  clientId,
  redirectUri,
  successCallback,
  errorCallback,
}) => {
  const { search: queryParams } = useLocation();
  const [mutate, { loading }] = useMutation(mutation, {
    onCompleted: (data) => {
      successCallback(data);
      OAuthStateManager.finishFor(redirectUri);
    },
    onError: errorCallback,
  });

  useEffect(() => {
    const queryObject = parseSearchParamsString(queryParams);
    const savedState = OAuthStateManager.getFor(redirectUri);

    if (queryObject?.code && queryObject.state === savedState) {
      mutate({
        variables: {
          code: queryObject.code,
          clientId: clientId,
          redirectUri: redirectUri,
        },
      });
    } else OAuthStateManager.finishFor(redirectUri);
  }, [queryParams]);

  return {
    loading,
  };
};

export default useOAuthCodeExchange;
