import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { theme } from "../../context";
import { Icon } from "../index";

export default function RunTestChip({ status }) {
  const currentTheme = theme()[0];
  const isDarkTheme = currentTheme === "dark";

  const message = () => {
    if (status === "TESTS_PASS") {
      return "passed";
    }

    if (status === "JAVASCRIPT_ERROR") {
      return "javascript failed";
    }

    if (status === "SOLIDITY_COMPILATION_ERROR") {
      return "solidity compilation failed";
    }

    if (status === "TESTS_FAIL") {
      return "not passed";
    }

    return "not yet implemented";
  };

  const colorThemes = () => {
    if (status === "TESTS_PASS") {
      return isDarkTheme
        ? "bg-secondary-green-400 text-custom-white"
        : "bg-secondary-green-100 text-custom-green-slate";
    }

    return isDarkTheme ? "text-custom-white" : "text-custom-gray-400";
  };

  return (
    <div
      className={classNames(
        "flex flex-row self-center gap-1 px-3 py-0.5 items-center rounded-sm",
        { "border border-primary-yellow": status !== "TESTS_PASS" },
        colorThemes()
      )}
      data-testid="chip"
    >
      {status === "TESTS_PASS" && (
        <Icon
          iconName="check"
          ariaLabel="test status" // FIXME: I think we'll have a description from the API response that can be used as the ariaLabel
          fill={classNames(
            isDarkTheme ? "fill-custom-white" : "fill-custom-green-slate"
          )}
        />
      )}
      <p className={classNames("uppercase text-xxs font-bold")}>{message()}</p>
    </div>
  );
}

RunTestChip.propTypes = {
  status: PropTypes.string.isRequired,
};
