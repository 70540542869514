import React, { useEffect, useRef, useMemo } from "react";
import { useFormikContext } from "formik";
import debounce from "just-debounce-it";
import PropTypes from "prop-types";

// Formik example: https://github.com/jaredpalmer/formik/blob/e51f09a318cba216a1ba3932da0906202df0b979/examples/DebouncedAutoSave.js
export default function AutoSave({ debounceMs, message }) {
  // Prevent it from submitting when it renders the form the first time,
  // we only want to submit it when the user enters a value.
  const firstRender = useRef(true);

  const { submitForm, values, isSubmitting } = useFormikContext();

  const debouncedSubmit = useMemo(
    () => debounce(() => submitForm(), debounceMs),
    [debounceMs, submitForm]
  );

  useEffect(() => {
    if (firstRender.current) firstRender.current = false;
    else debouncedSubmit();
  }, [debouncedSubmit, values]);

  return isSubmitting && message ? <p>{message}</p> : null;
}

AutoSave.propTypes = {
  debounceMs: PropTypes.number,
  message: PropTypes.string,
};

AutoSave.defaultProps = {
  debounceMs: 300,
};
