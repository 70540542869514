/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { useEthers } from "../hooks";

export const Context = createContext();

export function WalletContext({ children }) {
  const {
    isMetaMaskInstalled,
    connect,
    isConnecting,
    isConnected,
    accounts,
    error,
    mintToEth,
    transaction,
    isMinting,
    isMintSuccessful,
    getReceipt,
  } = useEthers();

  // If/when another protocol is introduced, maybe these become predicated with "eth" or something...
  return (
    <Context.Provider
      value={{
        isMetaMaskInstalled,
        connect,
        isConnecting,
        isConnected,
        accounts,
        error,
        mintToEth,
        transaction,
        isMinting,
        isMintSuccessful,
        getReceipt,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default function wallet() {
  const context = useContext(Context);
  if (!Context && context === undefined) {
    throw new Error("wallet must be used within a Provider");
  }
  return context;
}

WalletContext.propTypes = {
  children: PropTypes.any.isRequired,
};
