import React from "react";
import PropTypes from "prop-types";
import { Link } from "../index";

const SIGN_UP_TEXT = "Join for Free";
const SIGN_IN_TEXT = "Log In";

export default function SignInSignUpButtons({ loginButtonStyle }) {
  return (
    <div className="flex items-center gap-4">
      <Link
        href="/signup"
        asButton
        text={SIGN_UP_TEXT}
        styleType="primary"
        testid="headerSignupButton"
        additionalClasses="w-fit whitespace-nowrap"
      />
      <Link
        href="/login"
        asButton
        text={SIGN_IN_TEXT}
        styleType={loginButtonStyle}
        testid="headerSigninButton"
        additionalClasses="w-fit whitespace-nowrap"
      />
    </div>
  );
}

SignInSignUpButtons.propTypes = {
  loginButtonStyle: PropTypes.string,
};

SignInSignUpButtons.defaultProps = {
  loginButtonStyle: "outline",
};
