// queue
import {
  queueConfusedx1,
  queueConfusedx2,
} from "../../assets/pngs/queueConfused";
import {
  queueCongratulatory3x1,
  queueCongratulatory3x2,
  queueCongratulatoryCirclex1,
  queueCongratulatoryCirclex2,
} from "../../assets/pngs/queueCongratulatory";
import {
  queueHelpful2Circlex1,
  queueHelpful2Circlex2,
} from "../../assets/pngs/queueHelpful";
import {
  queueHelpful2Roundedx1,
  queueHelpful2Roundedx2,
} from "../../assets/pngs/queueHelpful2";
import {
  queueWelcomingCirclex1,
  queueWelcomingCirclex2,
  queueWelcomingx1,
  queueWelcomingx2,
} from "../../assets/pngs/queueWelcoming";

// luna
import { lunaHelpful2x1, lunaHelpful2x2 } from "../../assets/pngs/lunaHelpful";
import {
  lunaWelcomingRoundedx1,
  lunaWelcomingRoundedx2,
  lunaWelcomingx1,
  lunaWelcomingx2,
} from "../../assets/pngs/lunaWelcoming";

// venture
import {
  ventureCongratulatory3x1,
  ventureCongratulatory3x2,
  ventureCongratulatory3Roundedx1,
  ventureCongratulatory3Roundedx2,
} from "../../assets/pngs/ventureCongratulatory3";
import {
  ventureHelpful2x1,
  ventureHelpful2x2,
} from "../../assets/pngs/ventureHelpful";
import {
  ventureWelcomingx1,
  ventureWelcomingx2,
} from "../../assets/pngs/ventureWelcoming";

const images = {
  queueConfused: {
    x1: { img: queueConfusedx1, sizeW: "200w", sizePx: "200px" },
    x2: { img: queueConfusedx2, sizeW: "400w", sizePx: "400px" },
    defaultAlt: "Confused Queue",
  },
  queueCongratulatoryCircle: {
    x1: { img: queueCongratulatoryCirclex1, sizeW: "200w", sizePx: "200px" },
    x2: { img: queueCongratulatoryCirclex2, sizeW: "400w", sizePx: "400px" },
    defaultAlt: "Congratulatory Queue",
  },
  queueHelpful2Circle: {
    x1: { img: queueHelpful2Circlex1, sizeW: "200w", sizePx: "200px" },
    x2: { img: queueHelpful2Circlex2, sizeW: "400w", sizePx: "400px" },
    defaultAlt: "Helpful Queue",
  },
  queueHelpful2Rounded: {
    x1: { img: queueHelpful2Roundedx1, sizeW: "200w", sizePx: "200px" },
    x2: { img: queueHelpful2Roundedx2, sizeW: "400w", sizePx: "400px" },
    defaultAlt: "Helpful Queue",
  },
  queueWelcoming: {
    x1: { img: queueWelcomingx1, sizeW: "200w", sizePx: "200px" },
    x2: { img: queueWelcomingx2, sizeW: "400w", sizePx: "400px" },
    defaultAlt: "Welcoming Queue",
  },
  queueWelcomingCircle: {
    x1: { img: queueWelcomingCirclex1, sizeW: "200w", sizePx: "200px" },
    x2: { img: queueWelcomingCirclex2, sizeW: "400w", sizePx: "400px" },
    defaultAlt: "Welcoming Queue",
  },
  queueCongratulatory3: {
    x1: { img: queueCongratulatory3x1, sizeW: "200w", sizePx: "200px" },
    x2: { img: queueCongratulatory3x2, sizeW: "400w", sizePx: "400px" },
    defaultAlt: "Congratulatory Queue",
  },
  lunaHelpful2: {
    x1: { img: lunaHelpful2x1, sizeW: "200w", sizePx: "200px" },
    x2: { img: lunaHelpful2x2, sizeW: "400w", sizePx: "400px" },
    defaultAlt: "Helpful Luna",
  },
  lunaWelcoming: {
    x1: { img: lunaWelcomingx1, sizeW: "200w", sizePx: "200px" },
    x2: { img: lunaWelcomingx2, sizeW: "400w", sizePx: "400px" },
    defaultAlt: "Welcoming Luna",
  },
  lunaWelcomingRounded: {
    x1: { img: lunaWelcomingRoundedx1, sizeW: "200w", sizePx: "200px" },
    x2: { img: lunaWelcomingRoundedx2, sizeW: "400w", sizePx: "400px" },
    defaultAlt: "Welcoming Luna",
  },
  ventureWelcoming: {
    x1: { img: ventureWelcomingx1, sizeW: "200w", sizePx: "200px" },
    x2: { img: ventureWelcomingx2, sizeW: "400w", sizePx: "400px" },
    defaultAlt: "Welcoming Venture",
  },
  ventureHelpful2: {
    x1: { img: ventureHelpful2x1, sizeW: "200w", sizePx: "200px" },
    x2: { img: ventureHelpful2x2, sizeW: "400w", sizePx: "400px" },
    defaultAlt: "Helpful Venture",
  },
  ventureCongratulatory3: {
    x1: {
      img: ventureCongratulatory3x1,
      sizeW: "200w",
      sizePx: "200px",
    },
    x2: {
      img: ventureCongratulatory3x2,
      sizeW: "400w",
      sizePx: "400px",
    },
    defaultAlt: "Congratulatory Venture",
  },
  ventureCongratulatory3Rounded: {
    x1: {
      img: ventureCongratulatory3Roundedx1,
      sizeW: "200w",
      sizePx: "200px",
    },
    x2: {
      img: ventureCongratulatory3Roundedx2,
      sizeW: "400w",
      sizePx: "400px",
    },
    defaultAlt: "Congratulatory Venture",
  },
};

export default images;
