import { useRef, useState } from "react";

// Since the data structures and keys we store in localStorage can change over
// time, we've added an option to pass a set of migrations that can be used
// to move old representations over to new ones. For example, we initially stored
// code that the user was editing in a key like `course1-lesson1-files` and it
// had a structure like `{"file1": "body1", "file2", "body2"}`. Then later, we
// changed it to have a key like `course1-lesson1-file-file1` and a value like
// `{"body": "body1", "updatedAt": "2023-03-08T04:02:42.655Z"}`, and another
// similar key / value for file2. The migrations array allows us to declare
// the old keys, and functions to convert them into the new representations,
// and old data will be sequentially updated by this hook. Without it, we would
// need to do a bunch of checks of the data structure and then call the setters
// in `useEffect` hooks and that sort of thing. After a few changes, it would
// get pretty unwieldy.
//
// Basically, it's like db migrations for localStorage, since we use localStorage
// as a type of db :)
//
// Migrations is an array of arrays. This is so that we preserve the order to
// run them in. The inner array should contain a key to migrate, and a function
// that returns what to migrate it to. The return value should be an array of
// arrays, the inner array being key/value pairs. Migrating a key implies that
// it will no longer be in localStorage (unless the new key matches the old key)
function migrate(migrations, get, set, remove) {
  migrations.forEach(([oldKey, migration]) => {
    if (oldKey in localStorage) {
      const oldValue = get(oldKey);
      remove(oldKey); // do this first so that the migration can use the same key if it wants to
      migration(oldValue).forEach(([newKey, newValue]) =>
        set(newKey, newValue)
      );
    }
  });
}

export const useLocalStorage = (key, initialValue, migrations = []) => {
  // NOTE: might be better to allow caller to provide the serialize/deserialize
  // functions since JSON only understands a limited set of types. Eg:
  //   $ node -e '
  //     const d = new Date()
  //     console.log(typeof d)
  //     console.log(typeof JSON.parse(JSON.stringify(d)))
  //   '
  //   object
  //   string
  const serialize = (obj) => JSON.stringify(obj);
  const deserialize = (str) => JSON.parse(str);
  const set = (k, v) => window.localStorage.setItem(k, serialize(v));
  const get = (k) => deserialize(window.localStorage.getItem(k));
  const remove = (k) => window.localStorage.removeItem(k);

  migrate(migrations, get, set, remove);

  // loosely based on https://usehooks.com/useLocalStorage/
  const prevKeyRef = useRef();

  const [storedValue, setStoredValue] = useState(() => {
    prevKeyRef.current = key;
    let deserializedValue;
    if (key in localStorage) {
      deserializedValue = get(key);
    } else {
      deserializedValue = initialValue;
    }
    if (deserializedValue !== null) {
      set(key, deserializedValue);
    }
    return deserializedValue;
  });

  if (prevKeyRef.current !== key) {
    throw new Error(
      `useLocalStorage key should not change. Previous key: ${prevKeyRef.current}. Current key: ${key}`
    );
  }

  const setValue = (value) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    if (value === null) {
      remove(key);
    } else {
      set(key, valueToStore);
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
