/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import classNames from "classnames";
import { LabelWrapper } from "./index";
import { Icon } from "../index";

export default function Select({ name, label, titleOption, options }) {
  const [field, meta] = useField({ as: "select", name });

  const { value } = meta;

  const color = value ? "custom-gray-400" : "custom-gray-200";

  return (
    <LabelWrapper label={label} name={name}>
      <select
        className={classNames(
          "border border-custom-gray-200 rounded-sm py-2 px-4 bg-editor-base3 appearance-none",
          `text-${color}`
        )}
        {...field}
      >
        {titleOption && <option value="">{titleOption}</option>}
        {options.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
      <Icon
        fill={`fill-${color}`}
        iconName="chevron"
        additionalClasses="absolute bottom-2 right-4 pointer-events-none"
      />
      {meta?.touched && meta?.error && (
        <p className="text-primary-red">{meta?.error}</p>
      )}
    </LabelWrapper>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  titleOption: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};
