import React from "react";
import PropTypes from "prop-types";

function Loading({ width, height, viewBox, preserveAspectRatio }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: "auto",
        background: "0 0",
        display: "block",
        shapeRendering: "auto",
      }}
      width={width}
      height={height}
      viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.vw} ${viewBox.vh}`}
      preserveAspectRatio={preserveAspectRatio}
      data-testid="loading"
    >
      <rect x={47} y={27} rx={3} ry={3} width={6} height={6} fill="#15b586">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.25s"
          begin="-1.0416666666666665s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={47}
        y={27}
        rx={3}
        ry={3}
        width={6}
        height={6}
        fill="#916fef"
        transform="rotate(60 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.25s"
          begin="-0.8333333333333333s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={47}
        y={27}
        rx={3}
        ry={3}
        width={6}
        height={6}
        fill="#eac130"
        transform="rotate(120 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.25s"
          begin="-0.6249999999999999s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={47}
        y={27}
        rx={3}
        ry={3}
        width={6}
        height={6}
        fill="#15b586"
        transform="rotate(180 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.25s"
          begin="-0.41666666666666663s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={47}
        y={27}
        rx={3}
        ry={3}
        width={6}
        height={6}
        fill="#ff4635"
        transform="rotate(240 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.25s"
          begin="-0.20833333333333331s"
          repeatCount="indefinite"
        />
      </rect>
      <rect
        x={47}
        y={27}
        rx={3}
        ry={3}
        width={6}
        height={6}
        fill="#fbd6d3"
        transform="rotate(300 50 50)"
      >
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="1.25s"
          begin="0s"
          repeatCount="indefinite"
        />
      </rect>
    </svg>
  );
}

Loading.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  preserveAspectRatio: PropTypes.string,
  viewBox: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    vh: PropTypes.number,
    vw: PropTypes.number,
  }),
};

Loading.defaultProps = {
  width: 200,
  height: 200,
  preserveAspectRatio: "xMidYMid",
  viewBox: {
    x: 0,
    y: 0,
    vw: 100,
    vh: 100,
  },
};

export default Loading;
