import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import jsonp from "jsonp";
import toQueryString from "to-querystring";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";

import { Icon, Button, Input } from "..";

// Temporary mapping here for courses and mailchimp
// Adding these as a field to the hardcoded courses didn't make sense TBH
const mailchimpCoursesMappings = {
  walletBasics: { name: "group[33974][1]", value: "1", id: "group_1" },
  intermediateSolidity: {
    name: "group[33974][2]",
    value: "1",
    id: "group_2",
  },
  advancedSolidity: { name: "group[33974][4]", value: "1", id: "group_4" },
};

export default function SubscribeForm({
  additionalClasses,
  submitText,
  formType,
  courseId,
}) {
  const SIGN_UP_THANKS_AND_FOLLOW =
    "Thanks for signing up! To get more connected, follow us on Twitter";
  const INVALID_EMAIL_WARNING = "Invalid email address";
  const MAILING_LIST_LABEL = "Join our mailing list";
  const EMAIL_SUBMISSION_ERROR =
    "Sorry, there was a problem submitting your email. Please try again later.";

  const REACT_APP_MAILCHIMP_URL =
    "https://xyz.us11.list-manage.com/subscribe/post?";
  const REACT_APP_MAILCHIMP_U = "cb010b07b5b70f61bb4dde337";
  const REACT_APP_MAILCHIMP_ID = "04f94683d5";
  const MAILING_LIST_SIGN_UP = "mailingListSignUp";
  const COURSE_INTEREST = "courseInterest";
  const mailchimpFields = mailchimpCoursesMappings[courseId] || {};

  const url = REACT_APP_MAILCHIMP_URL;
  const credentials = {
    u: REACT_APP_MAILCHIMP_U,
    id: REACT_APP_MAILCHIMP_ID,
  };

  const SubscribeFormSchema = Yup.object().shape({
    email: Yup.string().email(INVALID_EMAIL_WARNING).required("Required"),
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  if (isSubmitted) {
    return (
      <div
        className={classNames(
          "flex flex-col w-full sm:w-5/6 sm:flex-row bg-primary-green rounded-sm p-4 justify-center items-center",
          additionalClasses
        )}
      >
        <div className="flex w-8 sm:w-14 mb-4 sm:mb-0 sm:mr-4 align-center justify-center items-center">
          <Icon iconName="twitter" fill="fill-white" ariaLabel="Twitter icon" />
        </div>
        <p className="text-custom-cream">
          {SIGN_UP_THANKS_AND_FOLLOW}
          <a
            href="https://twitter.com/QuickbeamPBC"
            target="_blank"
            className="underline pl-2"
            rel="noreferrer"
          >
            @QuickbeamPBC
          </a>
        </p>
      </div>
    );
  }

  return (
    <div className={classNames("flex flex-col md:w-full", additionalClasses)}>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={SubscribeFormSchema}
        onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
          resetForm();
          const params = {
            ...credentials,
            EMAIL: values.email,
          };
          if (formType === COURSE_INTEREST) {
            params[mailchimpFields.name] = mailchimpFields.value;
          }
          const query = toQueryString(params);
          const endpoint = `${url.replace("/post?", "/post-json?")}${query}`;

          try {
            await jsonp(endpoint, { param: "c" }, (err, data) => {
              if (!err) {
                if (data.result === "error") {
                  setStatus({
                    error: data.msg,
                  });
                } else {
                  resetForm();
                  setIsSubmitted(true);
                }
              } else setStatus({ error: EMAIL_SUBMISSION_ERROR });
            });
          } catch (error) {
            setStatus({ error: EMAIL_SUBMISSION_ERROR });
            Sentry.captureException(error);
            if (import.meta.env.DEV)
              console.log("error submitting email: ", error);
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting, status }) => (
          <Form className="flex flex-col">
            <div
              className="flex flex-col md:flex-row justify-between gap-2 items-start"
              data-testid="emailCapture"
            >
              <Input
                name="email"
                placeholder="Email"
                label={
                  formType === MAILING_LIST_SIGN_UP
                    ? MAILING_LIST_LABEL
                    : undefined
                }
                isLabelUpperCase
                size="small"
              />
              {formType === COURSE_INTEREST && (
                <Field
                  className="hidden"
                  type="checkbox"
                  name={mailchimpFields.name}
                  value={mailchimpFields.value}
                  id={mailchimpFields.id}
                  data-testid="courseInterestField"
                />
              )}
              <Button
                label={submitText}
                isDisabled={isSubmitting}
                onClick={() => {}}
                rounded="rounded"
                size="small"
                additionalClasses={classNames(
                  {
                    "mt-0 md:mt-8": formType === MAILING_LIST_SIGN_UP,
                  },
                  "whitespace-nowrap"
                )} // to account for the label
              />
            </div>
            {status && status.error && (
              <p className="text-primary-red text-headlines-h4 pt-2">
                {status.error}
              </p>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

SubscribeForm.propTypes = {
  // Note: Careful! Any classes already defined with tailwind will not necessarily be overridden
  // this is mostly meant to allow flexibility to add positioning, padding, etc without adding extra divs
  additionalClasses: PropTypes.string,
  submitText: PropTypes.string,
  formType: PropTypes.string,
  courseId: PropTypes.string,
};

SubscribeForm.defaultProps = {
  additionalClasses: "",
  submitText: "Submit",
  formType: "mailingListSignUp",
  courseId: "",
};
