import React from "react";
import { Header, FooterSection, Link } from "../../components";
import { ReactComponent as AccessibleImage } from "../../assets/svgs/landingPage/accessible.svg";
import { ReactComponent as AssessmentsImage } from "../../assets/svgs/landingPage/assessments.svg";
import { ReactComponent as CommunityImage } from "../../assets/svgs/landingPage/community.svg";
import { ReactComponent as DigitalWalletImage } from "../../assets/svgs/digital-wallet.svg";
import { ReactComponent as Hexagon } from "../../assets/svgs/hexagon.svg";

export default function AboutUsPage() {
  const TECHNICAL_EDUCATION = "Technical education that puts humans first.";
  const TECHNICAL_EDUCATION_DESC =
    "At Quickbeam, we help early and mid-level engineers take the next step in their journey learning advanced concepts in Web2 and Web3 technologies.";
  const OUR_MISSION = "Our Mission";
  const MISSION_TITLE = "We believe in unleashing latent human potential.";
  const MISSION_DESC_1 =
    "Quickbeam’s mission is to unleash demand for the crypto-curious, and to dramatically impact the future of the Web3 ecosystem by creating the largest group of globally diverse builders.";
  const MISSION_DESC_2 =
    "While we’re chain agnostic, Ethereum’s creator Vitalik Buterin’s vision holds tightly to ours, “Ultimately, the goal of crypto is not to play games with million-dollar pictures of monkeys, it’s to do things that accomplish meaningful effects in the real world.” We agree. The possibilities are endless with the adoption of blockchain and decentralized ledger technology.";
  const MISSION_DESC_3 =
    "We will connect the largest and most inclusive technical learning community on the planet with economic freedom.";
  const OUR_VALUES = "Our Values";
  const ALWAYS_BE_LEARNING = "Always Be Learning";
  const ALWAYS_BE_LEARNING_DESC =
    "Everything we do is in service of education. We ask ourselves every day, “Are we making it easier for someone to learn something new, or are we making it harder?” As a Public Benefit Corporation it is our core commitment to provide opportunities for learning and professional development to everyone within the community, regardless of their background.";
  const ECONOMIC_FREEDOM = "Economic Freedom For Everyone";
  const ECONOMIC_FREEDOM_DESC =
    "Radically accessible learning opportunities should lead to greater economic freedom in the world. We will lead, open doors and pass the mic to those who are passionate about building healthy economic opportunities for the under funded.";
  const RADICAL_ACCESSIBILITY = "Radical Accessibility";
  const RADICAL_ACCESSIBILITY_DESC_1 =
    "If we want to meet our mission and help people understand complex concepts that solve complex problems, we need to make those paths obvious, simple, and direct. We also need to help learners find their Zone of Proximal Development within Quickbeam’s courses. That is what enables radical accessibility.";
  const RADICAL_ACCESSIBILITY_DESC_2 =
    "Quickbeam is dedicated to making learning paths, courses, community and additional services available and easily accessible to everyone, regardless of any barriers that might prevent them from accessing or using them.";
  const RADICAL_ACCESSIBILITY_DESC_3 =
    "We actively partner with industry leaders and technical organizations to create affordable programming and to remove physical, technological, or societal barriers that prevent under-represented or marginalized individuals from unleashing their potential.";
  const COMMUNITY = "Community";
  const COMMUNITY_DESC =
    "The relationship between learners and Quickbeam should be continuous, with learning on both sides; us learning from you, you learning from us. We believe that by fostering a sense of belonging, collaboration, and mutual support among learners we can all thrive and build beautiful things.";
  const MEET_THE_TEAM_LINK = "Meet the team";

  return (
    <div className="flex flex-col">
      <div className="bg-gradient-rad-green-dark">
        <Header isDarkMode />
        <section className="flex flex-col items-center px-5 py-16 text-center md:py-[11%] lg:py-[8%] md:grid md:grid-cols-12">
          <div className="flex flex-col items-center gap-6 col-span-8 col-start-3">
            <Hexagon className="w-20 md:w-32 lg:w-40" />
            <h2 className="text-headlines-h2-mobile md:text-headlines-h2 font-bold">
              {TECHNICAL_EDUCATION}
            </h2>
            <p className="text-body-1-mobile md:text-body-1 text-custom-green-light">
              {TECHNICAL_EDUCATION_DESC}
            </p>
          </div>
        </section>
      </div>
      <section className="flex flex-col items-center px-5 py-20 bg-custom-green-dark text-center md:pt-32 md:pb-40 md:grid md:grid-cols-12 md:items-start">
        <h4 className="uppercase text-shadow text-transparent bg-clip-text text-header-small font-bold bg-gradient-green-light-condensed mb-2 md:col-span-3">
          {OUR_MISSION}
        </h4>
        <div className="md:col-span-6">
          <h2 className="text-headlines-h2-mobile font-bold mb-2 md:text-headlines-h2 mb-8">
            {MISSION_TITLE}
          </h2>
          <p className="text-body-1 text-custom-green-light mb-6">
            {MISSION_DESC_1}
          </p>
          <p className="text-body-1 text-custom-green-light mb-6">
            {MISSION_DESC_2}
          </p>
          <p className="text-body-1 text-custom-green-light mb-6">
            {MISSION_DESC_3}
          </p>
        </div>
      </section>
      <section className="flex flex-col items-center px-5 py-20 text-custom-white md:grid md:grid-cols-12 md:px-20 md:py-32 md:items-start">
        <h4 className="uppercase text-shadow text-transparent bg-clip-text text-header-small font-bold bg-gradient-green-light-condensed mb-6 md:col-span-3">
          {OUR_VALUES}
        </h4>
        <div className="md:col-span-6">
          <div className="flex mb-16 gap-5">
            <AssessmentsImage className="w-20 min-w-[5rem] h-20" />
            <div>
              <h2 className="text-headlines-h2-mobile font-bold mb-5 mt-5 md:text-headlines-h2">
                {ALWAYS_BE_LEARNING}
              </h2>
              <p className="text-body-1">{ALWAYS_BE_LEARNING_DESC}</p>
            </div>
          </div>
          <div className="flex mb-16 gap-5">
            <DigitalWalletImage className="w-20 min-w-[5rem] h-20" />
            <div>
              <h2 className="text-headlines-h2-mobile font-bold mb-5 mt-5 md:text-headlines-h2">
                {ECONOMIC_FREEDOM}
              </h2>
              <p className="text-body-1">{ECONOMIC_FREEDOM_DESC}</p>
            </div>
          </div>
          <div className="flex mb-16 gap-5">
            <AccessibleImage className="w-20 min-w-[5rem] h-20" />
            <div>
              <h2 className="text-headlines-h2-mobile font-bold mb-5 mt-5 md:text-headlines-h2">
                {RADICAL_ACCESSIBILITY}
              </h2>
              <p className="text-body-1 mb-6">{RADICAL_ACCESSIBILITY_DESC_1}</p>
              <p className="text-body-1 mb-6">{RADICAL_ACCESSIBILITY_DESC_2}</p>
              <p className="text-body-1">{RADICAL_ACCESSIBILITY_DESC_3}</p>
            </div>
          </div>
          <div className="flex gap-5">
            <CommunityImage className="w-20 min-w-[5rem] h-20" />
            <div>
              <h2 className="text-headlines-h2-mobile font-bold mb-5 mt-5 md:text-headlines-h2">
                {COMMUNITY}
              </h2>
              <p className="text-body-1">{COMMUNITY_DESC}</p>
              <Link
                additionalClasses="mt-4"
                href="/team"
                text={MEET_THE_TEAM_LINK}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="bg-custom-green-dark">
        <FooterSection />
      </div>
    </div>
  );
}
