// Languages available https://codemirror.net/5/mode/

import { rust } from "@codemirror/lang-rust";
import { solidity } from "@replit/codemirror-lang-solidity";
import { javascript } from "@codemirror/lang-javascript";

const languageExtensions = {
  solidity: solidity,
  rust: rust(),
  javascript: javascript({ jsx: true }),
};

export default languageExtensions;
