import React from "react";
import { useParams } from "react-router-dom";
import {
  Article,
  Header,
  Link,
  Loading,
  Error,
  FooterSection,
} from "../../components";
import { useArticle } from "../../hooks";

export default function ArticlePage() {
  const params = useParams();
  const { tag, topic } = params;
  const path = `${tag}/${topic}`;

  const {
    isLoading,
    error,
    article: { title },
  } = useArticle(path); // FIXME: this call is only used to grab the article title for the breadcrumb even though the same call is also made in article. GraphQL caching means it's not a big deal, but still annoying to call the same thing twice on the same page

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error errorDetails={error} />;
  }

  return (
    <>
      <div className="bg-gradient-white-orange-reverse-light">
        <div className="flex flex-col min-h-screen">
          <Header isDarkMode={false} loginButtonStyle="secondary" />
          <div className="px-4 md:px-8 lg:px-24 mt-9">
            <div className="text-custom-black text-link-sm md:text-link-md">
              <Link
                href="/resources/articles"
                text="Articles"
                additionalClasses="inline-block text-link-sm md:text-link-md"
                customLinkColor="primary-green"
                testid="articleBreadcrumb"
              />
              {" / "}
              <p className="inline">{title}</p>
            </div>
          </div>
          <div className="flex flex-col md:items-center pt-4 px-4 mb-60 w-full md:w-1/2 self-center">
            <Article path={path} themeOverride="light" />
          </div>
        </div>
      </div>
      <div className="bg-custom-green-dark flex justify-center">
        <div className="w-full">
          <FooterSection />
        </div>
      </div>
    </>
  );
}
