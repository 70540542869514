import React from "react";
import { useParams } from "react-router-dom";

import IntroductionToSolidityAndEthers from "../../assets/nfts/IntroductionToSolidityAndEthers.png";

export default function Nft() {
  const params = useParams();
  const { courseId } = params;

  const availableNfts = {
    course_wlu8y: IntroductionToSolidityAndEthers,
  };

  const src = availableNfts[courseId];

  return (
    <div className="flex justify-center h-screen items-center">
      <img src={src} alt="NFT" className="max-h-full" />
    </div>
  );
}
