import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Loading, Error, ProgressMenuPanel, Link, Button } from "../index";
import { lesson as lessonContext } from "../../context";
import BaseHeader from "./BaseHeader";
import AccountSection from "../accountSection";

function isNextLessonUnlocked(crntLesson, course) {
  return course?.lessons?.find((l) => l.id === crntLesson?.nextLessonId)
    ?.isUnlocked;
}

export default function ProgressMenuHeader({
  currentLessonTitle,
  headerStyle,
  course,
  overrideCenter,
  isCenteredNavigator,
  isLoading,
  error,
  canNavigateForward,
  lessonMetaData,
  hideNavigator,
  changeColor,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const lesson = lessonContext()?.lessonValue;

  const LESSONS = "Lessons";

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    console.error("No currentCourse");
    return <Error />;
  }

  if (canNavigateForward === undefined) {
    // eslint-disable-next-line no-param-reassign
    canNavigateForward = isNextLessonUnlocked(lesson, course);
  }

  const menu = { name: course?.displayName, type: "course" };
  menu.options = course?.lessons?.map((lsn) => ({
    text: lsn.title,
    path: `/course/${course?.id}/lesson/${lsn.id}`,
    isDisabled: !lsn.isUnlocked,
  }));

  const progressMenu = () => (
    <ProgressMenuPanel
      menu={menu}
      setIsMenuOpen={setIsMenuOpen}
      currentMenuItem={currentLessonTitle}
    />
  );

  const lessonNavigator = () => {
    const { ordinal, prevLessonId, nextLessonId } = lessonMetaData || lesson;

    const lessonNum = `${ordinal + 1}/${course.numLessons}`;
    return (
      <div
        className="hidden md:flex items-center gap-3 mr-3"
        data-testid="headerNavigator"
      >
        <Link
          asButton
          href={`/course/${course?.id}/lesson/${prevLessonId}`}
          isDisabled={!prevLessonId}
          disabledIconStyleClasses={
            changeColor
              ? "fill-custom-gray-200"
              : "fill-secondary-green-500 border-secondary-green-500 bg-transparent"
          }
          isIconOnly
          iconName="arrowLeft"
          styleType={changeColor ? "outline-white" : "outline"}
          size="small"
          ariaLabel="go back one lesson"
          testid="backNavigator"
        />
        <p
          className={classNames(
            "whitespace-nowrap",
            changeColor ? `text-${changeColor}` : "text-primary-green"
          )}
          data-testid="lessonCount"
        >{`Lesson ${lessonNum}`}</p>
        <Link
          asButton
          href={`/course/${course?.id}/lesson/${nextLessonId}`}
          isDisabled={!nextLessonId || !canNavigateForward}
          disabledIconStyleClasses={
            changeColor ? "fill-custom-gray-200" : "fill-secondary-green-500"
          }
          isIconOnly
          iconName="arrowRight"
          styleType={changeColor ? "outline-white" : "outline"}
          size="small"
          ariaLabel="go forward one lesson"
          testid="forwardNavigator"
        />
      </div>
    );
  };

  const startContent = () => (
    <div className="flex items-center gap-3">
      <Link
        iconName="qLogo"
        href="/"
        size="large"
        ariaLabel="home page"
        isIconOnly
        customLinkColor={changeColor || "primary-green"}
        testid="quickbeamLetterlogo"
      />
      <Button
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
        label={LESSONS}
        styleType={changeColor ? "outline-white" : "outline"}
        iconName="list"
        size="small"
        isIconFirst
        ariaLabel="open the menu"
      />
      {!isCenteredNavigator && !hideNavigator && lessonNavigator()}
    </div>
  );

  const centeredContent = () =>
    overrideCenter ? overrideCenter() : lessonNavigator();

  const endContent = () => (
    <div>
      <AccountSection
        isIconOnly={window.innerWidth < 1125}
        isDarkMode={!!changeColor}
      />
    </div>
  );

  return (
    <BaseHeader
      isMenuOpen={isMenuOpen}
      menu={progressMenu}
      key={menu}
      headerStyle={headerStyle}
      startContent={startContent}
      centeredContent={
        isCenteredNavigator || overrideCenter ? centeredContent : null
      }
      endContent={endContent}
    />
  );
}

ProgressMenuHeader.propTypes = {
  currentLessonTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  course: PropTypes.object,
  headerStyle: PropTypes.string, // Optional for additional styling purposes
  isCenteredNavigator: PropTypes.bool,
  canNavigateForward: PropTypes.bool,
  lessonMetaData: PropTypes.object,
  hideNavigator: PropTypes.bool,
  overrideCenter: PropTypes.func,
  changeColor: PropTypes.string,
};

ProgressMenuHeader.defaultProps = {
  course: null,
  error: null,
  headerStyle: "",
};
