/* eslint-disable import/prefer-default-export */
import React from "react";
import PropTypes from "prop-types";
import { Link, Panel } from "../index";
import { theme } from "../../context";

export function ProgressMenuPanel({ menu, setIsMenuOpen, currentMenuItem }) {
  const isDarkMode = theme().at(0) === "dark";

  const calculateLinkColor = (itemText) => {
    const isCurrentLink = currentMenuItem === itemText;
    if (isCurrentLink && isDarkMode) {
      return "custom-white";
    }
    if (isCurrentLink) {
      return "primary-green";
    }
    if (!isCurrentLink && isDarkMode) {
      return "primary-green";
    }
    return "custom-green-slate";
  };

  const homeLink = () => (
    <Link
      href="/"
      text="Home"
      onClick={() => setIsMenuOpen(false)}
      asButton
      styleType="outline"
      iconName="home"
      size="small"
      additionalClasses="w-fit"
    />
  );

  return (
    <Panel
      close={() => setIsMenuOpen(false)}
      headerText={menu?.name}
      isRight={false}
      homeLinkOverride={homeLink}
      showHomeLink
      showAccountDropdown
      testId="progressMenuSidePanel"
    >
      {menu?.options?.map((item, index) => (
        <Link
          key={item.text}
          href={item.path}
          text={`${index + 1}. ${item.text}`}
          customLinkColor={calculateLinkColor(item.text)}
          isDisabled={item.isDisabled}
          onClick={() => setIsMenuOpen(false)}
          additionalClasses={
            menu?.options?.length === index + 1 ? "mb-28" : null
          }
        />
      ))}
    </Panel>
  );
}

ProgressMenuPanel.propTypes = {
  menu: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        isDisabled: PropTypes.bool.isRequired,
      })
    ),
  }).isRequired,
  setIsMenuOpen: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentMenuItem: PropTypes.any.isRequired,
};
