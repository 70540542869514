import React from "react";
import { Link } from "../../components";

export default function ErrorPage() {
  return (
    <div className="flex w-full flex-col items-center mt-24">
      <h1 className="text-2xl">Oops!</h1>
      <h3 className="text-lg mt-4">Something went wrong.</h3>
      <Link additionalClasses="mt-4" href="/" text="Go back to Home" />
    </div>
  );
}
