import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { PopoverMenu } from "..";

export default function Popover({
  button,
  items,
  isMenuOpen,
  menuPosition,
  isFullWidth,
  testId,
}) {
  return (
    <div
      className={classNames("flex flex-col place-items-end relative", {
        "w-full": isFullWidth,
      })}
      data-testid={testId}
    >
      {button && button()}
      <PopoverMenu
        testId={`${testId}Menu`}
        show={isMenuOpen}
        position={menuPosition}
      >
        {items && items()}
      </PopoverMenu>
    </div>
  );
}

Popover.propTypes = {
  button: PropTypes.any,
  items: PropTypes.any,
  isMenuOpen: PropTypes.bool,
  menuPosition: PropTypes.oneOf(["top", "bottom"]),
  isFullWidth: PropTypes.bool,
  testId: PropTypes.string,
};
