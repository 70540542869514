const defaultTheme = require("tailwindcss/defaultTheme");
const typography = require("@tailwindcss/typography");
const plugin = require("tailwindcss/plugin");
const lineClamp = require("@tailwindcss/line-clamp");

module.exports = {
  content: ["./src/**/*.{js,jsx}"],
  theme: {
    extend: {
      animation: {
        "slide-from-left": "slideRight .5s linear",
        "slide-from-right": "slideLeft .5s linear",
        "slide-from-bottom": "slideUp .5s linear",
        "slide-from-top": "slideDown .5s linear",
        "slide-from-right-to-left": "slideRightToLeft .5s linear",
        "slide-from-right-to-right": "slideRightToRight .5s linear",
        "bg-oscillating": "backgroundOscillating 15s ease infinite",
      },
      backgroundImage: {
        // for element backgrounds (in design system)
        "gradient-green-light":
          "linear-gradient(226.22deg, #2AE4AE 7.46%, #FBF9F3 100.1%)",
        "gradient-green-dark":
          "linear-gradient(226.22deg, #2AE4AE 7.46%, #26494B 100.1%)",
        "gradient-purple-light":
          "linear-gradient(226.22deg, #916FEF 7.46%, #FBF9F3 100.1%)",
        "gradient-purple-dark":
          "linear-gradient(200deg, #916FEF 7.46%, #1D3C3E 100.1%)",
        "gradient-yellow-light":
          "linear-gradient(226.22deg, #EAC130 7.46%, #FBF9F3 100.1%)",
        "gradient-yellow-dark":
          "linear-gradient(221deg, #EAC130 7.46%%, #26494B 100.1%)",
        "gradient-red-light":
          "linear-gradient(226.22deg, #FF4635 7.46%, #FBF9F3 100.1%)",
        "gradient-red-dark":
          "linear-gradient(226.22deg, #FF4635 7.46%, #26494B 100.1%)",
        // for text
        "gradient-green-light-condensed":
          "linear-gradient(182deg, #2AE4AE 7.46%, #FBF9F3 100.1%)",
        // for text in the new designs -- still WIP
        "gradient-pink":
          "linear-gradient(45deg, #F44C8C 0%, #F54460 30%, #DC3615 80%)",
        "gradient-pink-red":
          "linear-gradient(180deg, #EA64A6 19.03%, #FF320A 93.38%)",
        "gradient-orange-yellow":
          "linear-gradient(348deg, #FFC46E 23.34%, #FF8249 52.48%, #FF590F 74.95%)",
        "gradient-red-orange":
          "linear-gradient(121deg, #A21E08 38.36%, #FFD46D 100%)",
        // for the code editor fade - base03 & cream
        "gradient-code-fade-dark":
          "linear-gradient(to top, #1D3031BF 50.43%, #1D303100 100%)",
        "gradient-code-fade-light":
          "linear-gradient(to top, #FBF9F3BF 50.43%, #FBF9F300 100%)",
        // for page backgrounds
        "gradient-rad-green-dark":
          "radial-gradient(50% 138.25% at 0% 0%, #1D3031 0%, #26494B 100%)",
        "gradient-rad-green-darker":
          "radial-gradient(50% 165.62% at 0% 0%, #1B2828 0%, #1E3C3E 100%)",
        // This is not in the design system and used values for white and a
        // lighter tint of the code orange to keep colors within the design
        // system family
        "gradient-white-orange-reverse-light":
          "linear-gradient(226.22deg, #FFFEFD 7.46%, #f2dfce 100.1%)",
        // for the background fade - slate & white
        "gradient-bg-fade-dark":
          "linear-gradient(to top, #26494B 70%, transparent 100%)",
        "gradient-bg-fade-light":
          "linear-gradient(to top, #FBF9F3 70%, transparent 100%)",
        "gradient-fade-to-white":
          "linear-gradient(to bottom, #ffffff00 0%, #FFFFFF 100%)",
        "gradient-radial-dark-green":
          "radial-gradient(#1B2828 0%, #1E3C3E 100%)",
      },
      boxShadow: {
        "md-purple": "6px 6px 0px #916FEF",
        "md-purple-2": "0px 6px 0px #916FEF",
        "dark-shadow": "0px 4px 36px 0px #142122",
        "two-shadows-purple":
          "0 6px 0px #916FEF, 0px 4px 60px rgba(228, 225, 216, 0.8)",
        // 0px 4px 60px rgba(228, 225, 216, 0.8)
      },
      /* offset-x | offset-y | blur-radius | spread-radius | color */
      colors: {
        custom: {
          green: {
            gray: "#91B7B9", // gray-green
            slate: "#26494B", // slate-green
            soft: "#F3FCF5", // soft green
            lighter: "#15BE8D", // lighter green
            light: "#A7CCCE", // light green
            dark: "#1D3031", // dark green
          },
          gray: {
            100: "#DCE4E4", // grey 100
            200: "#909C9C", // grey 200
            300: "#65898B", // grey 300
            400: "#4F5C5C", // grey 400
            "semi-opaque": "#1E1E1E8C",
          },
          blue: {
            slate: "#48696b", // slate-blue
          },
          black: "#142122", // black
          white: "#FFFEFD", // white
          cream: "#FBF9F3", // cream
        },
        primary: {
          green: "#15B586", // primary green 300
          purple: "#916FEF", // primary purple 300
          red: "#FF4635", // primary red 300
          yellow: "#EAC130", // primary yellow 300
          pink: "#FBD6D3", // primary pink 300
        },
        secondary: {
          green: {
            100: "#AEF5E1", // secondary green 100
            200: "#2AE4AE", // secondary green 200
            400: "#10956D", // secondary green 400
            500: "#0C6147", // secondary green 500
          },
          purple: {
            100: "#F5EFFF", // secondary purple 100
            200: "#C3AFF7", // secondary purple 200
            400: "#634ABF", // secondary purple 400
            500: "#35258E", // secondary purple 500
          },
          red: {
            100: "#FFC0AD", // secondary red 100
            200: "#FF8371", // secondary red 200
            400: "#BD2F27", // secondary red 400
            500: "#7A1718", // secondary red 500
            600: "#38000A", // secondary red 600
          },
          yellow: {
            100: "#FFF7A0", // secondary yellow 100
            200: "#F5DC68", // secondary yellow 200
            400: "#B89524", // secondary yellow 400
            500: "#876A18", // secondary yellow 500
          },
          pink: {
            100: "#FFF1EF", // secondary pink 100
          },
          gray: {
            400: "#4F5C5C",
          },
        },
        editor: {
          base03: "#1D3031", // darkest (dark green gray)
          base02: "#2B4142", // 2nd darkest (green gray)
          base01: "#576E70", // med (gray green)
          base00: "#637D7E", // med-light (gray green)
          base0: "#7B9395", //  softer (gray green)
          base1: "#99B7B9", // super soft (gray green)
          base2: "#F1EAD3", // cream
          base3: "#FBF9F3", // white
          yellow: "#C9AE4F", // yellow
          orange: "#D4965C", // orange
          red: "#D4665C", // red
          magenta: "#BE6CBB", // magenta
          violet: "#826CBE", // violet
          blue: "#078FCA", // blue
          cyan: "#26A2A9", // cyan
          green: "#15B586", // green
        },
      },
      fontFamily: {
        jagger: ["Jagger", ...defaultTheme.fontFamily.sans],
      },
      fontSize: {
        // slowly styles will align with design style guide,
        // which will be headlines-*, link-*, body-*, code-*.
        // we might still have some 1 off styles, especially in the landing page,
        // but would like to keep just design styles here and use tailwinds
        // custom attributes for those to avoid confusion like we've had
        "xxs-tight": ["14px", "28px"],
        xxs: ["14px", "36px"],
        xs: ["16px", "36px"],
        sm: ["18px", "36px"],
        md: ["20px", "36px"],
        lg: ["24px", "36px"],
        xl: ["28px", "40px"],
        "2xl": ["40px", "52px"],
        "5xl": ["48px", "68px"],
        "7xl": ["81px", "96px"],
        "8xl": ["96px", "106px"],
        "header-small": ["17px", "24px"],
        "header-large": ["36px", "48px"],
        "sub-header": ["16px, 28px"],
        // cleanup above this line
        "body-1": ["20px", "32px"],
        "body-1-mobile": ["18px", "32px"],
        "body-1-condensed": ["20px", "28px"], // same for bold
        "body-1-condensed-mobile": ["18px", "28px"], // same for bold
        "body-2": ["16px", "28px"], // same for mobile
        "body-2-condensed": ["16px", "24px"], // same for mobile
        "headlines-h1": ["64px", "80px"],
        "headlines-h2": ["40px", "52px"], // mobile h1
        "headlines-h2-mobile": ["28px", "40px"],
        "headlines-h3": ["20px", "30px"], // same size for mobile
        "headlines-h4": ["16px", "26px"],
        "headlines-h4-mobile": ["12px", "24px"],
        "headlines-h5": ["12px", "18px"],
        "headlines-h5-mobile": ["11px", "16px"],
        "link-lg": ["24px", "36px"],
        "link-md": ["20px", "36px"],
        "link-sm": ["16px", "36px"],
        "link-xs": ["12px", "24px"],
      },
      keyframes: {
        slideRight: {
          "0%": { transform: "translateX(-100%)" },
          "100%": { transform: "translateX(0)" },
        },
        slideLeft: {
          "0%": { transform: "translateX(0)" },
          "100%": { transform: "translateX(-100%)" },
        },
        slideUp: {
          "0%": { transform: "translateY(100%)" },
          "100%": { transform: "translateY(0%)" },
        },
        slideDown: {
          "0%": { transform: "translateY(0%)" },
          "100%": { transform: "translateY(100%)" },
        },
        slideRightToLeft: {
          "0%": { transform: "translateX(100%)" },
          "100%": { transform: "translateX(0)" },
        },
        slideRightToRight: {
          "0%": { transform: "translateX(0)" },
          "100%": { transform: "translateX(100%)" },
        },
        backgroundOscillating: {
          "0%": { "background-position": "0% 50%" },
          "50%": { "background-position": "100% 50%" },
          "100%": { "background-position": "0% 50%" },
        },
      },
      textShadow: {
        DEFAULT: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      },
      // https://tailwindcss.com/docs/typography-plugin#adding-custom-color-themes
      typography: ({ theme }) => ({
        dark: {
          css: {
            "--tw-prose-body": "#FFFEFD", // custom white
            "--tw-prose-headings": "#FFFEFD", // custom white
            "--tw-prose-lead": "#FFFEFD", // custom white
            "--tw-prose-links": "#FFFEFD", // custom white
            "--tw-prose-bold": "#FFFEFD", // custom white
            "--tw-prose-counters": "#FFFEFD", // custom white
            "--tw-prose-bullets": "#FFFEFD", // custom white
            "--tw-prose-hr": "#FFFEFD", // custom white
            "--tw-prose-quotes": "#FFFEFD", // custom white
            "--tw-prose-quote-borders": "#FFFEFD", // custom white
            "--tw-prose-captions": "#FFFEFD", // custom white
            "--tw-prose-code": "#1D3031", // base03
            "--tw-prose-pre-code": "#1D3031", // base03
            "--tw-prose-pre-bg": "#1D3031", // base03
            "--tw-prose-th-borders": "#FFFEFD", // custom white
            "--tw-prose-td-borders": "#FFFEFD", // custom white
          },
        },
        // FIXME:
        light: {
          css: {
            "--tw-prose-body": "#4F5C5C", // gray 400
            "--tw-prose-headings": "#26494B", // slate green
            "--tw-prose-lead": "#26494B", // slate green
            "--tw-prose-links": "#4F5C5C", // gray 400
            "--tw-prose-bold": "#4F5C5C", // gray 400
            "--tw-prose-counters": "#4F5C5C", // gray 400
            "--tw-prose-bullets": "#4F5C5C", // gray 400
            "--tw-prose-hr": "#4F5C5C", // gray 400
            "--tw-prose-quotes": "#4F5C5C", // gray 400
            "--tw-prose-quote-borders": "#4F5C5C", // gray 400
            "--tw-prose-captions": "#4F5C5C", // gray 400
            "--tw-prose-code": "#F1EAD3", // editor base 2 cream
            "--tw-prose-pre-code": "#F1EAD3", // editor 2 cream
            "--tw-prose-pre-bg": "#F1EAD3", // editor 2 cream
            "--tw-prose-th-borders": "#4F5C5C", // gray 400
            "--tw-prose-td-borders": "#4F5C5C", // gray 400
          },
        },
      }),
    },
  },
  plugins: [
    typography,
    lineClamp,
    plugin(({ matchUtilities, theme }) => {
      matchUtilities(
        {
          "text-shadow": (value) => ({
            textShadow: value,
          }),
        },
        { values: theme("textShadow") }
      );
    }),
  ],
};
