import { gql, useQuery } from "@apollo/client";

export default function useArticles(props = {}) {
  const additionalAttributes = props.additionalAttributes || [];
  const {
    loading: isLoading,
    error,
    data,
  } = useQuery(gql`
    query Articles {
      articles { title path ${additionalAttributes.join(" ")} }
    }
  `);

  return {
    isLoading,
    error,
    articles: isLoading || error ? {} : data.articles,
  };
}
