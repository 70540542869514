import useEditorFiles from "../useEditorFiles";

function normalizeSection(apiLesson, apiSection, userId) {
  // FIXME: we should remove `type` from the API, I changed this to use `__typename`
  // instead. So there is no need for the API to pass a `type` back (we did that
  // before we knew about `__typename`).
  //
  // To keep everything working, we're recreating the old type that came in through
  // the API. However, anything that directly looks at that type should instead
  // call predicates. eg instead of `section.type === "EDITOR"`, the
  // code should call `section.isEditor`, so then the way we implement that
  // becomes encapsulated here, and we can remove this `type` attribute entirely.
  const { __typename } = apiSection; // __typename comes from the GraphQL API, Apollo is configured to add it
  const section = { type: null }; // FIXME: remove `type` altogether
  switch (__typename) {
    case "MarkdownSection":
      section.content = apiSection.content;
      section.key = apiSection.key; // FIXME: if we can not expose this, that would be better, I haven't looked yet to see if it is used
      section.type = "MARKDOWN";
      break;
    case "EditorSection":
      section.actions = apiSection.actions; // FIXME: should probably not expose this in this way
      section.entryFilePath = apiSection.files.find((f) => !f.readonly)?.path;
      section.useEditorFiles = () =>
        useEditorFiles(apiLesson, apiSection, userId);
      section.type = "EDITOR";
      break;
    case "QuizSection":
      section.numQuestions = apiSection.numQuestions;
      section.questions = apiSection.questions; // FIXME: just going to pass this straight through for now, instead of figuring out what is using it and what should be exposed
      section.type = "QUIZ";
      break;
    case "WalletSection":
      section.provider = apiSection.provider;
      section.action = apiSection.action;
      section.title = apiSection.title;
      section.sections = apiSection.sections; // FIXME: this should probably be processed here, whatever is using it should probably not know about this structure
      section.type = "WALLETS";
      break;
    case "MintSection":
      section.protocol = apiSection.protocol;
      section.type = "MINT";
      break;
    default:
      console.error(`Unknown section type: ${__typename}`);
      break;
  }
  return section;
}

export default function normalizeLesson(apiLesson, userId) {
  if (!apiLesson) {
    return null;
  }
  return {
    id: apiLesson.id,
    version: apiLesson.version,
    ordinal: apiLesson.ordinal,
    title: apiLesson.title,
    paymentRequired: apiLesson.paymentRequired,

    courseId: apiLesson.courseId, // FIXME: not obvious we want to directly use IDs in the code
    prevLessonId: apiLesson.prevLessonId, // FIXME: not obvious we want to directly use IDs in the code
    nextLessonId: apiLesson.nextLessonId, // FIXME: not obvious we want to directly use IDs in the code

    sections: apiLesson.sections.map((s) =>
      normalizeSection(apiLesson, s, userId)
    ),
  };
}
