import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Loading } from "../../components";
import { useOAuthDiscordLinkedRoles } from "../../hooks";

function DiscordLinkedRoleCallback() {
  const navigate = useNavigate();
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const linkedRoleErrorCallback = (err) => {
    Sentry.captureException(err);
    return navigate("/error");
  };

  const { loading } = useOAuthDiscordLinkedRoles({
    successCallback: () => setShowSuccessMsg(true),
    errorCallback: linkedRoleErrorCallback,
  });

  if (loading) {
    return <Loading />;
  }

  return (
    showSuccessMsg && (
      <section className="flex flex-col items-center min-h-screen w-fit mt-9 z-10 md:mt-24 md:w-full ">
        <div className="flex flex-col items-center gap-6 text-center mx-5 md:mx-0 md:w-2/5">
          <p>
            Account connected! Close this window, and return back to Discord.
          </p>
        </div>
      </section>
    )
  );
}

export default DiscordLinkedRoleCallback;
