import React from "react";
import classNames from "classnames";
import { Widget, Button, Link, Error, Icon, GradientBorder } from "../index";
import { wallet, navigator } from "../../context";
import { ReactComponent as MetaMaskLogo } from "../../assets/svgs/meta-mask.svg";

export default function MetaMaskWidget() {
  const { isMetaMaskInstalled, connect, isConnecting, isConnected, accounts } =
    wallet();
  const { browserName } = navigator();

  const hasAccount = accounts?.length > 0;

  const CONNECT_WALLET = "Connect Wallet";
  const WALLET = "Wallet";
  const WALLET_SETUP = "Wallet Setup";
  const SAFARI_MESSAGE = `Unfortunately, MetaMask is not available for Safari. To continue, you
  will need to use one of the following supported browsers: Chrome,
  Firefox, Brave, Edge, Opera. Learn more at`;
  const SAFARI_WARNING = "MetaMask not supported on Safari";

  /* TODO: Save progress for Safari users to switch browsers for MetaMask.
   * This likely means adding signup/auth.
   */
  if (browserName === "Apple Safari") {
    return (
      <Widget title={WALLET_SETUP} testid="MetaMaskWidget">
        <GradientBorder
          gradientBorderClass="bg-gradient-yellow-light"
          backgroundColor="bg-editor-base03"
        >
          <Icon iconName="warning" fill="fill-primary-yellow" />
          <h4 className="text-[16px]">{SAFARI_WARNING}</h4>
        </GradientBorder>
        <h5 className="text-[14px] leading-5">
          {SAFARI_MESSAGE}{" "}
          <Link
            href="https://metamask.io/"
            text="metamask.io"
            size="xsmall-tight"
          />
        </h5>
      </Widget>
    );
  }

  if (!isMetaMaskInstalled) {
    return (
      <Widget title={WALLET_SETUP} testid="MetaMaskWidget">
        <Link
          asButton
          isFullWidth
          styleType="secondary"
          href="https://metamask.io/download/"
          text="Install MetaMask"
          iconName="linkTo"
          isIconFirst={false}
          testid="connectMetaMaskWarning"
        />
      </Widget>
    );
  }

  // TODO: We can make this a generic wallet connection by making a generic check for a web3 eth provider
  if (isMetaMaskInstalled && (!hasAccount || !isConnected)) {
    return (
      <Widget title={WALLET_SETUP} testid="MetaMaskWidget">
        <Button
          label={CONNECT_WALLET}
          isFullWidth
          onClick={connect}
          className={classNames("border", {
            "text-gray-300": isConnecting,
          })}
          // Always disable the connect button while request is pending: https://docs.metamask.io/guide/getting-started.html#basic-considerations
          disabled={isConnecting}
          iconName="wallet"
          testId="connectMetaMask"
        />
      </Widget>
    );
  }

  // TODO: If we want to make this a wallet widget, we can just show the account and wallet
  if (isMetaMaskInstalled && hasAccount) {
    return (
      <Widget
        title={WALLET}
        status={<div className="rounded-full bg-primary-green w-1.5 h-1.5" />}
        testid="MetaMaskWidget"
      >
        <div className="flex gap-2 items-center">
          <MetaMaskLogo className="h-6" />
          <h3 className="text-headlines-h3" data-testid="MetaMaskWidgetTitle">
            MetaMask
          </h3>
        </div>
        <h5
          className="text-[12px] leading-4 text-custom-gray-100"
          data-testid="metaMaskAccount"
        >
          {accounts[0]}
        </h5>
      </Widget>
    );
  }

  // FIXME: It might be possible that when a person goes to connect their wallet, that they will connect
  // A wallet that is not MetaMask, so they will have an account and can mint, but we aren't prepared
  // to handle that right now, so we will show them an error...

  return <Error />;
}
