import useEditorFileBody from "./useEditorFileBody";

export default function useEditorFiles(apiLesson, apiSection, userId) {
  return apiSection.files.map((file) => {
    const [body, updateBody, resetBody] = useEditorFileBody(
      apiLesson,
      file,
      userId
    );
    return {
      language: file.language,
      path: file.path,
      readonly: file.readonly,
      body,
      updateBody,
      resetBody,
    };
  });
}
