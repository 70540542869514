import React from "react";
import { useLocation } from "react-router-dom";
import { MintingContext, course as CourseContext } from "../../context";
import {
  Confetti,
  Loading,
  Error,
  MintWidget,
  MetaMaskWidget,
  ProgressMenuHeader,
  Image,
} from "../../components/index";

// TODO: Ideally, like MetaMask, we can get these values either through the course,
// or through another query for the context

export default function Mint() {
  const location = useLocation();
  const {
    currentCourse: course,
    isLoadingCurrentCourse,
    isErrorCurrentCourse,
  } = CourseContext();
  const { areNormalLessonsComplete, mintTransaction } = course || {};

  // TODO: Add tracking for page view if not from the lesson?
  const { title } = location?.state || {};

  const CONGRATULATIONS = "Congratulations";
  const COURSE_COMPLETION =
    "You can now initiate a blockchain transaction to mint your Quickbeam Course Completion token. You'll be able to use this token to prove you completed this course.";
  const TOKEN_MINTED =
    "You've already initiated your blockchain transaction to mint your Quickbeam Course Completion token. You can use this token to prove you completed this course.";
  const COURSE_NOT_COMPLETE =
    "Once you complete all lessons successfully, you will be able to initiate a blockchain transaction to mint your Quickbeam Course Completion token. You'll be able to use this token to prove you completed this course.";
  const KEEP_LEARNING = "Keep Learning!";
  const CONTACT_US_IF_MISTAKE =
    "If you believe there is a mistake, please contact us at ";

  if (isErrorCurrentCourse) {
    if (import.meta.env.DEV) console.error("Error getting current course.");
    return <Error />;
  }

  if (isLoadingCurrentCourse || !course) {
    return <Loading />;
  }

  const message = () => {
    if (!areNormalLessonsComplete) {
      return <p className="text medium text-center">{COURSE_NOT_COMPLETE}</p>;
    }

    // FIXME: This messaging shouldn't live long, so not going to bother unhardcoding and adding these
    // to the variables
    if (import.meta.env.VITE_FEATURE_FLAG_MINTING !== "false") {
      return (
        <p className="text medium text-center">
          The minting functionality for Quickbeam Course Completion (QCC) tokens
          is currently unavailable. As soon as it is available, we will contact
          you to receive your course completion token.
        </p>
      );
    }

    return (
      <>
        <p className="text medium text-center">
          {mintTransaction?.transactionHash ? TOKEN_MINTED : COURSE_COMPLETION}
        </p>
        <p className="text medium text-center">
          While Quickbeam is in beta, our{" "}
          <a
            className="underline"
            href="https://goerli.etherscan.io/address/0xBd90e82A0BC31D1A732D8c474566907960198868"
            target="_blank"
            rel="noreferrer"
          >
            QCC smart contract
          </a>{" "}
          is currently on the Goerli testnet. This means you will need to
          acquire GoerliETH in order to mint your token. You can find GoerliETH
          by visiting a Goerli Faucet. Once Quickbeam is out of beta, we will
          migrate our contract to mainnet and contact you to ensure you receive
          your token at your desired mainnet address.
        </p>
      </>
    );
  };

  const pageTitle = () => {
    if (areNormalLessonsComplete) return CONGRATULATIONS;
    return KEEP_LEARNING;
  };

  return (
    <main className="px-4 lg:px-6 pb-6">
      <ProgressMenuHeader
        currentLessonTitle={title}
        course={course}
        isLoading={isLoadingCurrentCourse}
        error={isErrorCurrentCourse}
        hideNavigator
      />
      <div className="flex w-full justify-center">
        {areNormalLessonsComplete && (
          <Confetti height={window.innerHeight * 0.75} />
        )}
        <div className="flex flex-col w-full lg:w-1/2 items-center my-6 lg:my-16 gap-4">
          <h1 className="text-2xl font-bold text-center">{pageTitle()}</h1>
          <Image
            name={
              areNormalLessonsComplete
                ? "queueCongratulatoryCircle"
                : "queueHelpful2Circle"
            }
          />
          {message()}
          <MetaMaskWidget />
          <MintingContext course={course}>
            <MintWidget />
          </MintingContext>
          {!areNormalLessonsComplete && (
            <p
              className="text xsmall text-center"
              data-testid="notCompletedCourseMessage"
            >
              {CONTACT_US_IF_MISTAKE}
              <a className="underline" href="mailto:hello@quickbeam.xyz">
                hello@quickbeam.xyz
              </a>
            </p>
          )}
        </div>
      </div>
    </main>
  );
}
