import React from "react";
import PropTypes from "prop-types";
import { wallet, navigator } from "../../context";
import { Markdown, MetaMaskWidget } from "../index";

export default function Overview({ children, overview, title }) {
  const { isMetaMaskInstalled } = wallet();
  // TODO: Move this into wallet context if only going to use for wallet?
  const { browserName } = navigator();

  const WALLET_OVERVIEW = "Wallet Overview";

  return (
    <div className="flex flex-col w-full lg:w-1/2 self-center gap-4 pt-4 pb-14">
      <h1 className="h2 w-full self-start pb-10 bottomBorderDivider my-10">
        {title}
      </h1>
      <h3 className="text-headlines-h3">{WALLET_OVERVIEW}</h3>
      <div className="flex flex-col gap-10">
        {isMetaMaskInstalled && overview && <Markdown content={overview} />}
        <MetaMaskWidget />
        {browserName !== "Apple Safari" && children}
      </div>
    </div>
  );
}

Overview.propTypes = {
  children: PropTypes.any,
  overview: PropTypes.string,
  title: PropTypes.string,
};

Overview.defaultProps = {
  title: "MetaMask",
  overview:
    "There are many types of wallets that can be used to allow you to interact with your Ethereum account. We are going to use one of the most popular, MetaMask. For more information about wallets you can dive in [here](article://blockchain/wallet).",
};
