import { gql } from "@apollo/client";

// prettier-ignore
const GET_LESSON = gql`
  query Lesson($id: ID!, $version: String!) {
    lesson(id: $id, version: $version) {
      id ordinal title nextLessonId prevLessonId courseId version paymentRequired
      sections {
        type
        ... on MarkdownSection {
          content key
        }
        ... on EditorSection {
          files { language initialCode currentCode updatedAt path readonly }
          actions { type displayName description }
        }
        ... on WalletSection {
          provider action title type
          sections { type content key }
        }
        ... on MintSection {
          protocol
        }
        ... on QuizSection {
          numQuestions
          questions {
            __typename
            ... on MultipleChoiceQuestion {
              question allowMultipleAnswers
              answers { correct answer }
            }
          }
        }
      }
    }
  }
`;

const RUN_TESTS = gql`
  mutation RunTests($lessonId: ID!, $files: FileMap!, $version: String!) {
    runTests(lessonId: $lessonId, files: $files, version: $version) {
      course {
        id
        currentLessonId
        percentComplete
      }
      type
      stdout
      stderr
      hint
    }
  }
`;

const SUBMIT_QUIZ = gql`
  mutation SubmitQuiz(
    $lessonId: ID!
    $version: String!
    $answers: PlaceholderJson!
  ) {
    submitQuiz(lessonId: $lessonId, version: $version, answers: $answers) {
      course {
        id
        currentLessonId
        percentComplete
      }
    }
  }
`;

const COMPLETE_WALLET_SETUP = gql`
  mutation CompleteWalletSetup($lessonId: ID!, $version: String!) {
    completeWalletSetup(lessonId: $lessonId, version: $version) {
      course {
        id
        currentLessonId
        percentComplete
      }
    }
  }
`;

const READ_CONTENT = gql`
  mutation ReadContent($lessonId: ID!, $version: String!) {
    readContent(lessonId: $lessonId, version: $version) {
      course {
        id
        currentLessonId
        percentComplete
      }
    }
  }
`;

export {
  GET_LESSON,
  RUN_TESTS,
  SUBMIT_QUIZ,
  COMPLETE_WALLET_SETUP,
  READ_CONTENT,
};
