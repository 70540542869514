import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useJargons } from "../../hooks";
import { Loading, Error, QbContent } from "..";
import { theme } from "../../context";

export default function Jargon({ path, themeOverride }) {
  const { isLoading, error, jargons } = useJargons();
  const isDarkMode = themeOverride === "dark" && theme()[0] === "dark";

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error errorDetails={error} />;
  }

  const jargon = jargons[path];

  if (!jargon) {
    if (import.meta.env.DEV) {
      console.error(
        `Could not find jargon with path ${path}, these are the known paths: ${Object.keys(
          jargons
        )}`
      );
    }
    return <Error />;
  }

  return (
    <>
      <h2
        className={classNames(
          "headlines-h2-mobile md:text-headlines-h2 bg-transparent",
          isDarkMode ? "dark" : "light"
        )}
        data-testid="jargon"
        id={path}
      >
        {jargon.term}
      </h2>
      <hr
        className={classNames("my-10 w-full", {
          "border-custom-gray-300": isDarkMode,
          "border-custom-gray-100": !isDarkMode,
        })}
      />
      <QbContent
        contentType={jargon.contentType}
        content={jargon.content}
        themeOverride={themeOverride}
      />
    </>
  );
}

Jargon.propTypes = {
  path: PropTypes.string.isRequired,
  themeOverride: PropTypes.oneOf(["dark", "light"]), // bc theme is only a construct in lessons, the pages need to determine their own theme
};

Jargon.defaultProps = {
  themeOverride: undefined,
};
