import classNames from "classnames";
import PropTypes from "prop-types";

export const Sizes = {
  DEFAULT: "DEFAULT",
  MD: "MD",
  LG: "LG",
  XXL: "XXL",
  XL_DYNAMIC: "XL_DYNAMIC",
};

export const Colors = {
  DEFAULT: "DEFAULT",
  GRAD_GREEN_LIGHT: "GRAD_GREEN_LIGHT",
};

export const defaultPropTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isBold: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(Sizes)),
  color: PropTypes.oneOf(Object.values(Colors)),
};

export const defaultProps = {
  size: Sizes.DEFAULT,
  color: Colors.DEFAULT,
};

const sizeClassNames = (size) => {
  if (!Object.values(Sizes).includes(size)) {
    console.error(`Invalid Text Component size: ${size}`);
  }

  const sizeClassNamesMap = {
    [Sizes.DEFAULT]: "",
    [Sizes.MD]: "text-md",
    [Sizes.LG]: "text-lg",
    [Sizes.XXL]: "text-2xl",
    [Sizes.XL_DYNAMIC]: "text-4xl sm:text-5xl lg:text-7xl xl:text-8xl",
  };

  return sizeClassNamesMap[size];
};

const colorClassNames = (color) => {
  if (!Object.values(Colors).includes(color)) {
    console.error(`Invalid Text Component color: ${color}`);
  }

  const colorClassNamesMap = {
    [Colors.DEFAULT]: "text-custom-cream",
    [Colors.GRAD_GREEN_LIGHT]:
      "text-transparent bg-clip-text bg-gradient-green-light",
  };

  return colorClassNamesMap[color];
};

export const componentClassNames = (size, color, isBold, className) =>
  classNames(
    sizeClassNames(size),
    colorClassNames(color),
    isBold ? "font-bold" : "",
    className
  );
