/* eslint-disable import/prefer-default-export */

export const getSectionByKey = (sections, key) => {
  const section = sections.filter((s) => {
    if (s?.key === key) {
      return s;
    }
    return null;
  });
  return section[0]?.content;
};

export function areSetsEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((e) => arr2.includes(e));
}
