import { useMutation } from "@apollo/client";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { lesson, theme, course as CourseContext } from "../../context";
import { RUN_TESTS } from "../../queries";
import analytics from "../../utils/analyticsTracker";
import { Editor, RunTestOutput, QbContent, ProgressMenuHeader } from "..";

export default function MarkdownAndEditor({
  hasCompletedLessonSuccessfullyBefore,
}) {
  const navigate = useNavigate();
  const {
    lessonValue: { courseId, sections, id, version, title },
  } = lesson() || {};
  const {
    currentCourse: course,
    isLoadingCurrentCourse,
    isErrorCurrentCourse,
  } = CourseContext();

  const isDarkMode = theme()?.at(0) === "dark";
  const [isFullScreenMode, setFullScreenMode] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [isOutputSuccessful, setIsOutputSuccessful] = useState(false);

  const markdownSection = sections.find((s) => s.type === "MARKDOWN");
  const editorSection = sections.find((s) => s.type === "EDITOR");
  const editorActions = editorSection?.actions;
  const activeFilePath = editorSection.entryFilePath;

  const [activeTab, setActiveTab] = useState(activeFilePath);
  const [
    runTests,
    // eslint-disable-next-line no-unused-vars
    {
      data: { runTests: evaluatedResult } = {},
      loading: isEvaling,
      error: evalError,
    },
  ] = useMutation(RUN_TESTS, {
    // eslint-disable-next-line no-shadow
    onCompleted: ({ runTests: evaluatedResult }) => {
      const isSuccessful = evaluatedResult?.type === "TESTS_PASS";

      setShowOutput(true);
      if (isSuccessful) {
        setIsOutputSuccessful(true);
      }

      if (window.innerWidth < 768) {
        scroll.scrollToBottom();
      }

      analytics.runCode(courseId, id, isSuccessful, evaluatedResult?.type);
    },
    onError: (err) => {
      console.error({ err });
      // onError was added so that the e2e tests don't complain about an unhandled promise rejection
      Sentry.captureException(err);
      return navigate("/error");
    },
  });

  const reset = () => {
    throw new Error("IMPLEMENT ME OR SMTH");
  };

  // FIXME: This (and tracking the state) will get a lot more complicated if we ever need to support editing & submitting multiple files.
  const run = (filesToSubmit) => {
    setShowOutput(false);

    // FIXME: in schema and not to be hardcoded, how to know which action/file
    if (editorActions[0]?.type === "RUN_TESTS") {
      runTests({
        variables: {
          lessonId: id,
          files: filesToSubmit,
          version: version,
        },
      });
    }
  };

  useEffect(() => {
    setShowOutput(false);
    setActiveTab(activeFilePath);
  }, [id]);

  useEffect(() => {
    setIsOutputSuccessful(hasCompletedLessonSuccessfullyBefore(id));
  }, [id]);

  return (
    <>
      {!isFullScreenMode && (
        <section
          className={classNames(
            "flex flex-col h-1/2 md:h-full md:w-1/2 px-6 pb-6 max-h-screen overflow-y-auto",
            isDarkMode ? "dark" : "light"
          )}
        >
          <ProgressMenuHeader
            currentLessonTitle={title}
            course={course}
            isLoading={isLoadingCurrentCourse || !course}
            error={isErrorCurrentCourse}
          />
          {/* Probably in the long run, this should be a content section,
            not a markdown section. Then the content section can pass the
            content type to QbContent, without restricting it to just markdown.
          */}
          <QbContent
            contentType="MARKDOWN"
            content={markdownSection?.content}
          />
        </section>
      )}
      <section
        className={classNames(
          "flex flex-col md:h-full",
          isFullScreenMode ? "h-full md:w-full" : "h-1/2 md:w-1/2"
        )}
      >
        <Editor
          useFiles={editorSection.useEditorFiles}
          useActiveTabState={[activeTab, setActiveTab]}
          useFullScreenState={[isFullScreenMode, setFullScreenMode]}
          run={run}
          reset={reset}
          isOutputOpen={showOutput}
          isOutputSuccessful={isOutputSuccessful}
          isEvaluating={isEvaling}
        />
        {showOutput && (evaluatedResult || evalError) && (
          <RunTestOutput
            evaluatedResult={evaluatedResult}
            setShowOutput={setShowOutput}
            error={evalError?.message}
          />
        )}
      </section>
    </>
  );
}

MarkdownAndEditor.propTypes = {
  hasCompletedLessonSuccessfullyBefore: PropTypes.func.isRequired,
};
