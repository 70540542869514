/* eslint-disable react/no-children-prop */
import React from "react";
import PropTypes from "prop-types";
import {
  manifesto,
  scholarships,
  learningEnvironment,
} from "../../assets/markdown/marketingText";
import { StaticPageLayout, Markdown } from "../../components";

const LEARNING_ENVIRONMENT = "The Quickbeam Learning Environment";
const MANIFESTO = "Manifesto";
const SCHOLARSHIP_PROGRAM = "Scholarship Program";
export default function MarketingPage({ type }) {
  let content;
  let title;
  let elementStyles = {};
  let mentorOption = "AllMentorsChevron";

  switch (type) {
    case "manifesto": {
      content = manifesto;
      title = MANIFESTO;
      elementStyles = {
        h2: ["bg-clip-text text-transparent bg-gradient-pink-red mb-14"],
        li: ["list-star"],
      };
      break;
    }
    case "scholarships": {
      content = scholarships;
      title = SCHOLARSHIP_PROGRAM;
      mentorOption = "lunaWelcoming";
      elementStyles = {
        h3: [
          "bg-clip-text text-transparent bg-gradient-orange-yellow",
          "bg-clip-text text-transparent bg-gradient-pink-red",
        ],
      };
      break;
    }
    case "learningEnvironment": {
      content = learningEnvironment;
      title = LEARNING_ENVIRONMENT;
      elementStyles = {
        h3: [
          "bg-clip-text text-transparent bg-gradient-orange-yellow",
          "bg-clip-text text-transparent bg-gradient-pink-red",
          "bg-clip-text text-transparent bg-gradient-red-orange",
        ],
      };
      break;
    }
    default:
      content = "";
      title = "";
  }

  return (
    <StaticPageLayout
      title={title}
      testid={`marketing-${type}`}
      mentorOption={mentorOption}
      children={
        <Markdown
          content={content}
          themeOverride="light"
          elementStyles={elementStyles}
        />
      }
    />
  );
}

MarketingPage.propTypes = {
  type: PropTypes.oneOf(["manifesto", "scholarships", "learningEnvironment"])
    .isRequired,
};
