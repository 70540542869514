import { gql } from "@apollo/client";

const COURSE_TOPLEVEL_INFO = gql`
  fragment CourseToplevelInfo on Course {
    id
    version
    availabilityStatus
    displayName
    description
    numLessons
    logo
    firstLessonId
  }
`;

const USER_COURSE_FIELDS = gql`
  fragment UserCourseFields on Course {
    currentLessonId
    percentComplete
    isUnlocked
    mintTransaction {
      transactionHash
    }
  }
`;

const LESSON_TOPLEVEL_INFO = gql`
  fragment LessonToplevelInfo on Lesson {
    id
    title
    ordinal
  }
`;

const GET_USER_COURSES = gql`
  ${COURSE_TOPLEVEL_INFO}
  ${USER_COURSE_FIELDS}
  query Courses {
    courses {
      ...CourseToplevelInfo
      ...UserCourseFields
    }
  }
`;

const GET_COURSES = gql`
  ${COURSE_TOPLEVEL_INFO}
  query Courses {
    courses {
      ...CourseToplevelInfo
    }
  }
`;

const GET_COURSES_WITH_LESSONS = gql`
  ${COURSE_TOPLEVEL_INFO}
  ${LESSON_TOPLEVEL_INFO}
  ${USER_COURSE_FIELDS}
  query Courses {
    courses {
      ...UserCourseFields
      ...CourseToplevelInfo
      lessons {
        ...LessonToplevelInfo
      }
    }
  }
`;

const GET_COURSE_AUTHENTICATED = gql`
  ${COURSE_TOPLEVEL_INFO}
  ${USER_COURSE_FIELDS}
  query Course($id: ID!, $version: String!) {
    course(id: $id, version: $version) {
      ...CourseToplevelInfo
      ...UserCourseFields
      lessons {
        id
        title
        paymentRequired
        prevLessonId
        ordinal
      }
    }
  }
`;

const GET_COURSE_UNAUTHENTICATED = gql`
  ${COURSE_TOPLEVEL_INFO}
  query Course($id: ID!, $version: String!) {
    course(id: $id, version: $version) {
      ...CourseToplevelInfo
      lessons {
        id
        title
        prevLessonId
        ordinal
      }
    }
  }
`;

const GET_COURSE_TOP_LEVEL = gql`
  ${COURSE_TOPLEVEL_INFO}
  query Course($id: ID!, $version: String!) {
    course(id: $id, version: $version) {
      ...CourseToplevelInfo
    }
  }
`;

const GET_COURSE_WITH_LESSONS = gql`
  ${COURSE_TOPLEVEL_INFO}
  ${LESSON_TOPLEVEL_INFO}
  query Course($id: ID!, $version: String!) {
    course(id: $id, version: $version) {
      ...CourseToplevelInfo
      lessons {
        ...LessonToplevelInfo
      }
    }
  }
`;

const CHECK_COURSE_UNLOCKED = gql`
  ${COURSE_TOPLEVEL_INFO}
  ${USER_COURSE_FIELDS}
  mutation CheckCourseUnlocked($id: ID!) {
    checkCourseUnlocked(id: $id) {
      ...CourseToplevelInfo
      ...UserCourseFields
    }
  }
`;

export {
  GET_COURSES,
  GET_USER_COURSES,
  GET_COURSES_WITH_LESSONS,
  GET_COURSE_WITH_LESSONS,
  GET_COURSE_TOP_LEVEL,
  GET_COURSE_AUTHENTICATED,
  GET_COURSE_UNAUTHENTICATED,
  CHECK_COURSE_UNLOCKED,
};
