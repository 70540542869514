import React from "react";
import { Icon } from "../../components";
import {
  COURSE_BENEFITS,
  GROW_YOUR_CAPABILITITES,
  GROW_YOUR_CAPABILITITES_DESC,
  NEW_CAREER_OPPORTUNITIES,
  NEW_CAREER_OPPORTUNITIES_DESC,
  TAKE_CONTROL,
  TAKE_CONTROL_DESC,
  PROOF_OF_KNOWLEDGE,
  PROOF_OF_KNOWLEDGE_DESC,
} from "./introSolidityDetails";

export default function CourseBenefitsSection() {
  return (
    <section className="px-4 py-12 bg-white text-black md:p-20">
      <h3 className="text-headlines-h2-mobile font-bold mb-6 md:text-center md:pb-12">
        {COURSE_BENEFITS}
      </h3>
      <div className="md:grid md:grid-cols-4 md:gap-9">
        <div className="md:pr-4">
          <Icon
            iconName="successPerson"
            size="xxlarge"
            fill="none"
            stroke="none"
            additionalClasses="mb-4 md:mb-6"
          />
          <h4 className="text-headlines-h4 font-bold uppercase mb-2">
            {GROW_YOUR_CAPABILITITES}
          </h4>
          <p className="mb-11 text-custom-gray-400 md:text-body-2">
            {GROW_YOUR_CAPABILITITES_DESC}
          </p>
        </div>
        <div className="md:pr-4">
          <Icon
            iconName="flagPerson"
            size="xxlarge"
            fill="none"
            stroke="none"
            additionalClasses="mb-4 md:mb-6"
          />
          <h4 className="text-headlines-h4 font-bold uppercase mb-2">
            {NEW_CAREER_OPPORTUNITIES}
          </h4>
          <p className="mb-11 text-custom-gray-400 md:text-body-2">
            {NEW_CAREER_OPPORTUNITIES_DESC}
          </p>
        </div>
        <div className="md:pr-4">
          <Icon
            iconName="unitedHands"
            size="xxlarge"
            fill="none"
            stroke="none"
            additionalClasses="mb-4 md:mb-6"
          />
          <h4 className="text-headlines-h4 font-bold uppercase mb-2">
            {TAKE_CONTROL}
          </h4>
          <p className="mb-11 text-custom-gray-400 md:text-body-2">
            {TAKE_CONTROL_DESC}
          </p>
        </div>
        <div className="md:pr-4">
          <Icon
            iconName="medal"
            size="xxlarge"
            fill="none"
            stroke="none"
            additionalClasses="mb-4 md:mb-6"
          />
          <h4 className="text-headlines-h4 font-bold uppercase mb-2">
            {PROOF_OF_KNOWLEDGE}
          </h4>
          <p className="text-custom-gray-400 md:text-body-2">
            {PROOF_OF_KNOWLEDGE_DESC}
          </p>
        </div>
      </div>
    </section>
  );
}
