import React from "react";
import PropTypes from "prop-types";
import { useArticles } from "../../hooks";
import { Article, Loading, Error } from "..";

export default function Articles({ themeOverride }) {
  const { isLoading, error, articles } = useArticles({
    additionalAttributes: ["content", "contentType", "preview"],
  });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    if (import.meta.env.DEV) console.error(error);
    return <Error errorDetails={error} />;
  }

  return (
    <section className="flex w-full justify-center">
      <div className="flex flex-col md:w-1/2 mt-20">
        <div className="flex justify-center">
          <ul className="flex flex-col gap-20">
            {articles.map((article) => (
              <Article
                path={article.path}
                key={article.title}
                themeOverride={themeOverride}
                isPreview
              />
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

Articles.propTypes = {
  themeOverride: PropTypes.oneOf(["light", "dark"]),
};

Articles.defaultProps = {
  themeOverride: "light",
};
