import { gql } from "@apollo/client";

const GET_MINT_VALUES = gql`
  query MintValues($courseId: ID!) {
    mintValues(courseId: $courseId) {
      contractAddress
      protocol
      network
      baseTransactionViewerUrl
    }
  }
`;

const GET_MINT_AUTHORIZATION = gql`
  query AuthorizeMinting($recipientAddress: String!, $courseId: ID!) {
    authorizeMinting(recipientAddress: $recipientAddress, courseId: $courseId) {
      authorization
      validUntilTimestamp
      tokenUri
    }
  }
`;

const SAVE_MINTING_TRANSACTION = gql`
  mutation SaveMintingTransactionHash(
    $transactionHash: String!
    $courseId: String!
    $tokenUri: String!
    $contractAddress: String!
  ) {
    saveMintingTransactionHash(
      transactionHash: $transactionHash
      courseId: $courseId
      tokenUri: $tokenUri
      contractAddress: $contractAddress
    ) {
      course {
        id
        currentLessonId
        percentComplete
      }
    }
  }
`;

export { GET_MINT_AUTHORIZATION, SAVE_MINTING_TRANSACTION, GET_MINT_VALUES };
