import { gql, useQuery } from "@apollo/client";

// NOTE: I'm not sure how this is configured exactly, but this will hit the cache
// after the first time, and continue to reuse the cache subsequently. So even
// though it appears that it will run every time you ask for a jargon, in reality,
// it will only run the first time you ask for any jargon.
export default function useJargons() {
  const {
    loading: isLoading,
    error,
    data,
  } = useQuery(
    gql`
      query Jargons {
        jargons {
          path
          contentType
          content
          term
        }
      }
    `,
    {}
  );

  if (isLoading || error) return { isLoading, error, jargons: {} };

  return {
    isLoading,
    error,
    jargons: Object.fromEntries(data.jargons.map((j) => [j.path, j])),
  };
}
