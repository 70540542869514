import { gql, useQuery } from "@apollo/client";

export default function useArticle(path) {
  const {
    loading: isLoading,
    error,
    data,
  } = useQuery(
    gql`
      query Article($path: String!) {
        article(path: $path) {
          path
          title
          contentType
          content
          preview
        }
      }
    `,
    { variables: { path } }
  );

  return {
    isLoading,
    error,
    article: isLoading || error ? {} : data.article,
  };
}
