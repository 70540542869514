import React from "react";
import PropTypes from "prop-types";
import { Accordion } from "../../components";
import { COURSE_SYLLABUS, soliditySyllabus } from "./introSolidityDetails";
import { intermediateSoliditySyllabus } from "./intermediateSolidityDetails";

export default function CourseSyllabusSection({ courseId }) {
  return (
    <section className="px-4 py-12 bg-custom-cream text-black w-full md:px-72 md:py-20">
      <h3 className="text-headlines-h2-mobile font-bold pb-2.5 border-b border-b-custom-gray-100 md:pb-6">
        {COURSE_SYLLABUS}
      </h3>
      <Accordion
        items={
          courseId === "course_mu0u8"
            ? intermediateSoliditySyllabus
            : soliditySyllabus
        }
        themeOverride="light"
      />
    </section>
  );
}

CourseSyllabusSection.propTypes = {
  courseId: PropTypes.string,
};
