import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

export const Context = createContext();

export function DeepDiveContext({ value, children }) {
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default function useDeepDive() {
  const deepDive = useContext(Context);
  if (deepDive === undefined) {
    throw new Error("DeepDive must be used within a Provider");
  }
  return deepDive;
}

DeepDiveContext.propTypes = {
  value: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};
