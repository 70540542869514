import { gql } from "@apollo/client";

const ANALYTICS = gql`
  mutation Analytics($eventName: String!, $properties: PlaceholderJson!) {
    analytics(eventName: $eventName, properties: $properties)
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { ANALYTICS };
