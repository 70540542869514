import React from "react";
import { Header, Link, FooterSection, Image } from "../../components";
import { ReactComponent as RedSquirrelImage } from "../../assets/svgs/red-squirrel.svg";
import danLeu from "../../assets/pngs/teamMembers/dan_leu.png";
import daveHoover from "../../assets/pngs/teamMembers/dave_hoover.png";
import elizabethEngelman from "../../assets/pngs/teamMembers/elizabeth_engelman.png";
import heatherCorallo from "../../assets/pngs/teamMembers/heather_corallo.png";
import jakeMyers from "../../assets/pngs/teamMembers/jake_myers.png";
import joshCheek from "../../assets/pngs/teamMembers/josh_cheek.png";
import marcusCohn from "../../assets/pngs/teamMembers/marcus_cohn.png";
import marissaBiesecker from "../../assets/pngs/teamMembers/marissa_biesecker.png";
import makisOtman from "../../assets/pngs/teamMembers/makis_otman.png";
import monicaSeing from "../../assets/pngs/teamMembers/monica_seing.png";
import mashaLifshin from "../../assets/pngs/teamMembers/masha_lifshin.png";
import philWeber from "../../assets/pngs/teamMembers/phil_weber.png";
import ryanPrinz from "../../assets/pngs/teamMembers/ryan_prinz.png";
import taylorGalloway from "../../assets/pngs/teamMembers/taylor_galloway.png";
import toluDuyile from "../../assets/pngs/teamMembers/tolu_duyile.png";
import { shuffleArray } from "../../utils/miscellaneousUtils";
import { ReactComponent as Hexagon } from "../../assets/svgs/hexagon.svg";

export default function TeamPage() {
  const THE_TEAM = "The Team";
  const THE_MENTORS = "The Mentors";
  const BOOKS = "Books";
  const WE_WROTE_BOOK = "We literally wrote the book(s).";
  const WE_WROTE_BOOK_DESC =
    "Our team has deep experience working in technical education and Web3 technology.";
  const HANDS_ON_SMART_CONTRACT =
    "Hands-On Smart Contract Development with Solidity and Ethereum";
  const HANDS_ON_SMART_CONTRACT_DESC =
    "A practical guide to learn the entire process for building smart contracts for Ethereum. Experienced engineers and beginners alike will quickly get up to speed with the fundamentals and quickly move into builder mode.";
  const HANDS_ON_SMART_CONTRACT_LINK =
    "https://www.amazon.com/Hands-Contract-Development-Solidity-Ethereum/dp/1492045268/ref=sr_1_1?keywords=Hands+on+smart+contract+development&amp;qid=1681913948&amp;s=books&amp;sr=1-1&_encoding=UTF8&tag=quickbeam2023-20&linkCode=ur2&linkId=ec9095ca13e29d77b0fc8954f301be78&camp=1789&creative=9325";
  const APPRENTICESHIP_PATTERNS = "Apprenticeship Patterns";
  const APPRENTICESHIP_PATTERNS_DESC =
    "With today's rapidly changing and ever-expanding technologies, being successful requires more than technical expertise. To grow professionally, you also need soft skills and effective learning techniques. Honing those skills is what this book is all about.";
  const APPRENTICESHIP_PATTERNS_LINK =
    "https://www.amazon.com/Apprenticeship-Patterns-Guidance-Aspiring-Craftsman/dp/0596518382/ref=sr_1_1?crid=8YSD3TQA1YHL&amp;keywords=apprenticeship+patterns+guidance+for+the+aspiring+software+craftsman&amp;qid=1681758364&amp;sprefix=apprenticeship+patter%252Caps%252C270&amp;sr=8-1&_encoding=UTF8&tag=quickbeam2023-20&linkCode=ur2&linkId=f22e019bdda8ddb3feceff30442af115&camp=1789&creative=9325";
  const BUY_IT_AMAZON = "Buy it on Amazon";
  const SQUIRRELS = "We're squirrels.";
  const SQUIRRELS_DESC =
    "Quickbeam is built by the team at Red Squirrel Technologies. We offer software engineering and educational consulting services.";
  const CONTACT_RED_SQUIRREL = "Contact Red Squirrel";
  const RED_SQUIRREL_LINK = "https://redsquirrel.com/";
  const LUNA = "Luna";
  const QUEUE = "Queue";
  const VENTURE = "Venture";

  const teamMembers = [
    {
      name: "Heather Corallo",
      nameColor: "text-primary-green",
      titles: "Co-Founder, Co-CEO",
      imgName: heatherCorallo,
    },
    {
      name: "Dave Hoover",
      nameColor: "text-primary-purple",
      titles: "Co-Founder, Co-CEO",
      imgName: daveHoover,
    },
    {
      name: "Masha Lifshin",
      nameColor: "text-primary-red",
      titles: "Tech Lead, Intructor",
      imgName: mashaLifshin,
    },
    {
      name: "Elizabeth Engelman",
      nameColor: "text-primary-yellow",
      titles: "Lead Blockchain Engineer",
      imgName: elizabethEngelman,
    },
    {
      name: "Josh Cheek",
      nameColor: "text-primary-green",
      titles: "Engineering Delight, Instructor",
      imgName: joshCheek,
    },
    {
      name: "Marissa Biesecker",
      nameColor: "text-primary-purple",
      titles: "Web3 Engineer, Instructor",
      imgName: marissaBiesecker,
    },
    {
      name: "Tolu Duyile",
      nameColor: "text-primary-red",
      titles: "Full-stack Engineer",
      imgName: toluDuyile,
    },
    {
      name: "Ryan Prinz",
      nameColor: "text-primary-yellow",
      titles: "Product Lead",
      imgName: ryanPrinz,
    },
    {
      name: "Dan Leu",
      nameColor: "text-primary-green",
      titles: "Design Lead",
      imgName: danLeu,
    },
    {
      name: "Phil Weber",
      nameColor: "text-primary-purple",
      titles: "UX Design Lead, Product Lead",
      imgName: philWeber,
    },
    {
      name: "Marcus Cohn",
      nameColor: "text-primary-red",
      titles: "Marketing Lead",
      imgName: marcusCohn,
    },
    {
      name: "Jake Myers",
      nameColor: "text-primary-yellow",
      titles: "Full-stack Engineer",
      imgName: jakeMyers,
    },
    {
      name: "Monica Seing",
      nameColor: "text-primary-green",
      titles: "Full-stack Engineer",
      imgName: monicaSeing,
    },
    {
      name: "Makis Otman",
      nameColor: "text-primary-purple",
      titles: "Full-stack Engineer",
      imgName: makisOtman,
    },
    {
      name: "Taylor Galloway",
      nameColor: "text-primary-red",
      titles: "Full-stack Engineer",
      imgName: taylorGalloway,
    },
  ];

  shuffleArray(teamMembers);

  const teamSection = teamMembers.map(
    ({ name, nameColor, titles, imgName }) => (
      <div key={name} className="flex gap-6 md:flex-col">
        <img src={imgName} alt={name} className="w-32 md:w-44" />
        <div className="flex flex-col">
          <h5 className={`${nameColor} text-headlines-h2-mobile`}>{name}</h5>
          <p className="text-xxs-tight text-custom-cream">{titles}</p>
        </div>
      </div>
    )
  );

  return (
    <div className="flex flex-col bg-gradient-rad-green-dark">
      <Header isDarkMode />
      <section className="flex flex-col items-center pt-20 gap-6">
        <Hexagon className="w-20 md:w-32 lg:w-40" />
      </section>
      <section className="flex flex-col px-5 py-20 md:px-20 md:pb-32">
        <div className="flex flex-col gap-6 mb-20 md:grid md:grid-cols-12 md:mb-32 md:gap-0">
          <h4 className="uppercase text-shadow text-center text-transparent bg-clip-text text-header-small font-bold bg-gradient-green-light-condensed whitespace-nowrap md:text-start md:col-span-3">
            {THE_TEAM}
          </h4>
          <div className="flex flex-col gap-6 col-span-3 md:grid md:grid-cols-3 md:gap-y-20 md:gap-x-8 md:col-span-9">
            {teamSection}
          </div>
        </div>
        <div className="flex flex-col md:grid md:grid-cols-12">
          <h4 className="uppercase text-shadow text-transparent bg-clip-text text-header-small font-bold bg-gradient-green-light-condensed mb-6 whitespace-nowrap text-center md:text-start md:col-span-3">
            {THE_MENTORS}
          </h4>
          <div className="grid grid-cols-3 gap-4 md:gap-8 md:col-span-9">
            <div className="flex flex-col">
              <Image name="lunaWelcomingRounded" additionalClasses="md:w-44" />
              <p className="mt-2 text-headlines-h3 text-primary-yellow">
                {LUNA}
              </p>
            </div>
            <div className="flex flex-col">
              <Image name="queueHelpful2Rounded" additionalClasses="md:w-44" />
              <p className="mt-2 text-headlines-h3 text-primary-red">{QUEUE}</p>
            </div>
            <div className="flex flex-col">
              <Image
                name="ventureCongratulatory3Rounded"
                additionalClasses="md:w-44"
              />
              <p className="mt-2 text-headlines-h3 text-primary-purple">
                {VENTURE}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col px-5 py-20 text-center md:py-32 md:px-20 md:grid md:grid-cols-12">
        <h4 className="uppercase text-shadow text-transparent bg-clip-text text-header-small font-bold bg-gradient-green-light-condensed mb-8 md:text-start md:col-span-3">
          {BOOKS}
        </h4>
        <div className="flex flex-col md:col-span-6">
          <h2 className="text-headlines-h2-mobile pb-2 font-bold md:text-headlines-h2 md:mb-6">
            {WE_WROTE_BOOK}
          </h2>
          <p className="text-body-1 text-custom-green-light mb-8 md:mb-16">
            {WE_WROTE_BOOK_DESC}
          </p>
          <div className="flex flex-col items-center rounded shadow-dark-shadow mb-12 bg-gradient-radial-dark-green py-12 px-8">
            <h3 className="text-headlines-h2-mobile mb-4">
              {HANDS_ON_SMART_CONTRACT}
            </h3>
            <p className="text-custom-green-light text-body-2 mb-8">
              {HANDS_ON_SMART_CONTRACT_DESC}
            </p>
            <Link
              href={HANDS_ON_SMART_CONTRACT_LINK}
              text={BUY_IT_AMAZON}
              iconName="linkTo"
              isIconFirst={false}
              additionalClasses="w-fit"
              asButton
            />
          </div>
          <div className="flex flex-col items-center rounded shadow-dark-shadow bg-gradient-radial-dark-green py-12 px-8">
            <h3 className="text-headlines-h2-mobile mb-4">
              {APPRENTICESHIP_PATTERNS}
            </h3>
            <p className="text-custom-green-light text-body-2 mb-8">
              {APPRENTICESHIP_PATTERNS_DESC}
            </p>
            <Link
              href={APPRENTICESHIP_PATTERNS_LINK}
              text={BUY_IT_AMAZON}
              iconName="linkTo"
              isIconFirst={false}
              additionalClasses="w-fit"
              asButton
            />
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center px-5 py-20 bg-custom-cream text-primary-red text-center md:grid md:grid-cols-12">
        <div className="flex flex-col items-center col-span-8 col-start-3">
          <RedSquirrelImage className="w-32" />
          <h2 className="font-bold text-headlines-h2 mt-6">{SQUIRRELS}</h2>
          <p className="text-body-1 mb-4">{SQUIRRELS_DESC}</p>
          <Link
            href={RED_SQUIRREL_LINK}
            text={CONTACT_RED_SQUIRREL}
            iconName="linkTo"
            isIconFirst={false}
            additionalClasses="w-fit bg-primary-red text-white"
            styleType="none"
            customLinkColor="custom-white"
            asButton
          />
        </div>
      </section>
      <div className="bg-custom-green-dark">
        <FooterSection />
      </div>
    </div>
  );
}
