import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useCourse } from "../../hooks";
import { Loading, Error } from "../../components/index";

export default function CurrentCourseRedirectPage() {
  const { courseId } = useParams();
  const { isLoading, error, course } = useCourse(courseId, "0.0.1");

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Error errorDetails={error} />;
  }

  const { id, currentLessonId } = course;

  const path = `/course/${id}/lesson/${currentLessonId}`;

  return <Navigate to={path} replace />;
}
