import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { QbContent, ProgressMenuHeader, Button } from "..";
import { READ_CONTENT } from "../../queries";

export default function LessonWithContentOnlySections({
  isDarkMode,
  course,
  lesson,
}) {
  // lesson nav copied from lesson context. We should push this into the lesson object somehow
  const navigate = useNavigate();
  const navigateToNextLesson = () =>
    navigate(`/course/${lesson.courseId}/lesson/${lesson.nextLessonId}`);

  const [completeLesson] = useMutation(READ_CONTENT, {
    onCompleted: () => {
      navigateToNextLesson();
    },
  });

  const containerStyles = classNames(
    "flex flex-col px-6 pb-6 w-full overflow-y-scroll",
    isDarkMode ? "dark" : "light"
  );

  const onContinue = () => {
    completeLesson({
      variables: { lessonId: lesson.id, version: "0.0.1" },
    });
  };

  return (
    <div className={containerStyles}>
      <ProgressMenuHeader
        currentLessonTitle={lesson.title}
        isCenteredNavigator={false}
        course={course}
        isLoading={false}
      />
      <div className="flex flex-col items-center py-8">
        {lesson.sections.map((section) => (
          <QbContent
            key={section.content}
            contentType="MARKDOWN"
            content={section.content}
          />
        ))}

        {!course?.areNormalLessonsComplete && (
          <Button
            onClick={onContinue}
            label="Continue"
            styleType="secondary"
            iconName="arrowRight"
            isIconFirst={false}
            testId="markdownContinue"
            isFullWidth={false}
          />
        )}
      </div>
    </div>
  );
}

LessonWithContentOnlySections.propTypes = {
  isDarkMode: PropTypes.bool.isRequired,
  course: PropTypes.object.isRequired,
  lesson: PropTypes.object.isRequired,
};
