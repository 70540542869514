/* eslint-disable import/prefer-default-export */

const TOKEN_EXP_PADDING_SECONDS = 20;
const TOKEN_EXP_PADDING_MILLISECONDS = TOKEN_EXP_PADDING_SECONDS * 1000;

const getTokenExpMilliseconds = (jwt) => {
  if (!jwt) return null;

  const jwtPayload = jwt.split(".")[1];
  if (!jwtPayload) return null;

  const decodedPayload = window.atob(jwtPayload);
  if (!decodedPayload) return null;

  const { exp } = JSON.parse(decodedPayload);
  if (!exp) return null;

  return exp * 1000;
};

export const shouldRefreshAuth = (authJwt) => {
  if (!authJwt) return false; // current auth-token neccessary for refresh

  const expInMilliseconds = getTokenExpMilliseconds(authJwt);
  if (!expInMilliseconds) return false; // don't refresh, since something is fishy with authToken shape

  return Date.now() >= expInMilliseconds - TOKEN_EXP_PADDING_MILLISECONDS;
};
