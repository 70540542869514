import React from "react";
import {
  Sizes,
  Colors,
  defaultPropTypes,
  defaultProps,
  componentClassNames,
} from "./sharedProperties";

export function H1({ children, className, isBold, size, color }) {
  return (
    <h1 className={componentClassNames(size, color, isBold, className)}>
      {children}
    </h1>
  );
}

H1.Sizes = Sizes;
H1.Colors = Colors;
H1.propTypes = defaultPropTypes;
H1.defaultProps = defaultProps;

export function H2({ children, className, isBold, size, color }) {
  return (
    <h2 className={componentClassNames(size, color, isBold, className)}>
      {children}
    </h2>
  );
}

H2.Sizes = Sizes;
H2.Colors = Colors;
H2.propTypes = defaultPropTypes;
H2.defaultProps = defaultProps;
