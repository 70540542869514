import React from "react";
import PropTypes from "prop-types";
import CommunityIcons from "../communityIcons";
import { Panel } from "../sidePanel";
import HeaderLinks from "./HeaderLinks";

export default function SiteMenu({ setIsMenuOpen, isDarkMode }) {
  return (
    <Panel
      close={() => setIsMenuOpen(false)}
      isRight={false}
      showAccountDropdown
      showHomeLink
      largePadding
      testId="siteMenuSidePanel"
      isDarkMode={isDarkMode}
    >
      <nav className="flex flex-col mt-4 space-y-12">
        <HeaderLinks
          onClick={() => setIsMenuOpen(false)}
          linkColor={isDarkMode ? "custom-white" : "primary-green"}
        />
        <CommunityIcons />
      </nav>
    </Panel>
  );
}

SiteMenu.propTypes = {
  setIsMenuOpen: PropTypes.func.isRequired,
  isDarkMode: PropTypes.bool.isRequired,
};
