import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Image } from "../index";

export default function AllMentorsChevron({ additionalClasses }) {
  return (
    <div
      className={classNames(
        "flex justify-center items-center",
        additionalClasses
      )}
    >
      <Image
        name="ventureHelpful2"
        additionalClasses="-rotate-[3.71deg] -scale-x-[0.78] scale-y-[0.78] lg:-scale-x-[0.97]	lg:scale-y-[0.97] translate-x-20"
      />
      <Image
        name="lunaHelpful2"
        additionalClasses="z-10 scale-100 lg:scale-110 mb-12 md:mb-24"
      />
      <Image
        name="queueCongratulatory3"
        additionalClasses="rotate-[3.71deg] scale-[0.80] lg:scale-[0.97] -translate-x-[4.5rem]"
      />
    </div>
  );
}

AllMentorsChevron.propTypes = {
  additionalClasses: PropTypes.string,
};
