// eslint-disable-next-line import/prefer-default-export
export const AvailableIcons = [
  "account",
  "arrowLeft",
  "arrowRight",
  "book",
  "cancel",
  "chatBubbles",
  "check",
  "checkInCircle",
  "chevron",
  "chevronUp",
  "chevronRight",
  "close",
  "coin",
  "collapse",
  "discord",
  "edit",
  "ellipse",
  "envelope",
  "expand",
  "flagPerson",
  "googleLogo",
  "hamburger",
  "home",
  "instructor",
  "leaf",
  "linkTo",
  "list",
  "logOut",
  "medal",
  "minus",
  "mobilePhone",
  "nft",
  "officeDoor",
  "pencil",
  "play",
  "plus",
  "qLogo",
  "questionMark",
  "quickbeamBook",
  "reset",
  "successPerson",
  "star",
  "themeToggle",
  "twitter",
  "unitedHands",
  "wallet",
  "warning",
  "xInCircle",
];
