import React, { useLayoutEffect, useState, useRef } from "react";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import {
  Accordion,
  Header,
  FooterSection,
  Icon,
  Link,
  Loading,
  Error,
} from "../../components";
import introSolidityLaptopLogo from "../../assets/pngs/intro_solidity_laptop_logo.png";
import { useCourse } from "../../hooks";
import CourseBenefitsSection from "./CourseBenefitsSection";
import {
  ALL_COURSES,
  PREREQUISITE,
  PREREQUISITE_DETAILS,
  COURSE_TITLE,
  COURSE_INCLUDES,
  MARKETING_COURSE_DESCRIPTION,
  COURSE_DESCRIPTION_LABEL,
  SHOW_ALL,
  SHOW_LESS,
  FREQUENTLY_ASKED_QUESTIONS,
  SCHOLARSHIP_PROGRAM,
  SCHOLARSHIP_PROGRAM_DESC,
  faqs,
  EXTENDED_COURSE_DESCRIPTION_1,
  EXTENDED_COURSE_DESCRIPTION_2,
  COURSE_NUM_BREAKDOWN,
  DISCORD_ACCESS,
  MENTOR_SESSION,
  COURSE_NFT,
  ASSESSMENT,
} from "./introSolidityDetails";
import {
  INTERMEDIATE_COURSE_TITLE,
  INTERMEDIATE_MARKETING_COURSE_DESCRIPTION,
  INTERMEDIATE_EXTENDED_COURSE_DESCRIPTION_1,
  INTERMEDIATE_EXTENDED_COURSE_DESCRIPTION_2,
  INTERMEDIATE_EXTENDED_COURSE_DESCRIPTION_3,
  INTERMEDIATE_PREREQUISITE,
  INTERMEDIATE_PREREQUISITE_DESC,
  INTERMEDIATE_COURSE_NUM_BREAKDOWN,
  intermediateFaqs,
} from "./intermediateSolidityDetails";
import analytics from "../../utils/analyticsTracker";
import CourseIncludesSection from "./CourseIncludesSection";
import CourseSyllabusSection from "./CourseSyllabusSection";

export default function CourseDetailsPage() {
  const [showAllDescription, setShowAllDescription] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [showActionBar, setShowActionBar] = useState(false);
  const [buttonContainerPosition, setButtonContainerPosition] = useState(0);
  const [transition, setTransition] = useState("md:opacity-0 md:z-0");

  const actionBarRef = useRef(null);
  const heroSectionActionButtonsRef = useRef(null);

  const params = useParams();
  const isProd = import.meta.env.prod;

  const { isLoading, error, course } = useCourse(params.courseId, "0.0.1");
  if (isProd && params.courseId !== "course_wlu8y") {
    // REMOVEME: when course is available
    return <Error />;
  }

  const isPublished = course?.availabilityStatus === "PUBLISHED";

  const BUY_NOW_CTA = "Buy Now";
  const START_COURSE = "Start Course";
  const CONTINUE_COURSE = "Continue Course";
  const PREVIEW_COURSE_CTA = "Preview Course";

  // FIXME: Remove when course information is no longer hardcoded here
  const isIntermediateCourse =
    params.courseId === "course_mu0u8" || params.courseId === "course_ln1gk";

  const courseIncludesContent = [
    {
      iconName: "book",
      text: isIntermediateCourse
        ? INTERMEDIATE_COURSE_NUM_BREAKDOWN
        : COURSE_NUM_BREAKDOWN,
    },
    {
      iconName: "discord",
      text: DISCORD_ACCESS,
    },
    {
      iconName: "instructor",
      text: MENTOR_SESSION,
    },
    {
      iconName: "nft",
      text: COURSE_NFT,
    },
    {
      iconName: "pencil",
      text: ASSESSMENT,
    },
  ];

  useLayoutEffect(() => {
    const updatePosition = () => {
      setScrollY(window.scrollY);
      setButtonContainerPosition(
        heroSectionActionButtonsRef?.current?.getBoundingClientRect().top
      );
    };
    window.addEventListener("scroll", updatePosition);

    updatePosition();

    window.scrollTo({
      // when navigating to /details from landing page, this ensures page starts at top
      top: 0,
      behavior: "instant",
    });

    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  useLayoutEffect(() => {
    const isButtonContainerTopVisible = buttonContainerPosition >= 0;

    if (!isButtonContainerTopVisible && !showActionBar) {
      setShowActionBar(true);
      setTransition(
        "md:transition-opacity md:duration-300 md:opacity-100 md:z-20"
      );
    } else if (isButtonContainerTopVisible && showActionBar) {
      setShowActionBar(false);
      setTransition(
        "md:transition-opacity md:duration-300 md:opacity-0 md:z-0"
      );
    }
  }, [scrollY]);

  if (error) {
    return <Error />;
  }

  if (isLoading) {
    return <Loading />;
  }

  // eslint-disable-next-line consistent-return
  const getCTA = () => {
    if (isPublished) {
      return (
        <Link
          href={`/course/${course.id}/payment/make`}
          onClick={() => analytics.clickedBuyNow(course.id, "course details")}
          testid="coursePaymentLink"
          text={BUY_NOW_CTA}
          asButton
        />
      );
    }
  };

  const getPreview = () => {
    const isFirstLesson = () =>
      course?.currentLessonId === course?.firstLessonId;
    const ctaWord = () => {
      if (course?.isUnlocked) {
        if (isFirstLesson()) {
          return START_COURSE;
        }
        return CONTINUE_COURSE;
      }

      return PREVIEW_COURSE_CTA;
    };

    return (
      <Link
        href={`/course/${params.courseId}/lesson/${course?.currentLessonId}`}
        text={ctaWord()}
        styleType="secondary"
        asButton
        testid="linkToCourseLesson"
      />
    );
  };

  const actionBar = () => (
    <div
      ref={actionBarRef}
      className={classNames(
        "fixed bottom-0 right-0 left-0 bg-white flex flex-row gap-2 p-2 z-20 md:bottom-auto md:top-0 md:items-center md:justify-between",
        transition
      )}
      data-testid="actionBar"
    >
      <h2 className="hidden md:block text-headlines-h2-mobile text-custom-green-slate">
        {isIntermediateCourse ? INTERMEDIATE_COURSE_TITLE : COURSE_TITLE}
      </h2>
      <div className="flex flex-row justify-between gap-2 w-full md:w-max">
        {getPreview()}
        {!course?.isUnlocked && isPublished && getCTA()}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col relative">
      <Header isDarkMode />
      {actionBar()}
      <section className="flex flex-col gap-6 px-4 pb-12 pt-6 md:grid md:grid-cols-2 md:pt-16 md:pb-24 md:px-20 md:gap-9">
        <div className="flex flex-col gap-6 md:gap-4">
          <Link
            href="/courses"
            text={ALL_COURSES}
            iconName="arrowLeft"
            size="small"
            testid="allCourses"
            isIconFirst
            isUnderlined
          />
          <h1 className="text-headlines-h2 font-bold md:text-headlines-h1">
            {isIntermediateCourse ? INTERMEDIATE_COURSE_TITLE : COURSE_TITLE}
          </h1>
          <p className="text-body-1-mobile font-thin text-custom-gray-100 md:text-body-1 md:-mt-2">
            {isIntermediateCourse
              ? INTERMEDIATE_MARKETING_COURSE_DESCRIPTION
              : MARKETING_COURSE_DESCRIPTION}
          </p>
          <div className="flex items-center gap-2">
            <p className="line-through text-custom-gray-200 body-1">
              $
              {isIntermediateCourse
                ? import.meta.env.VITE_INTERMEDIATE_COURSE_PRICE
                : import.meta.env.VITE_COURSE_PRICE}
            </p>
            <p className="text-primary-yellow body-1">
              $
              {isIntermediateCourse
                ? import.meta.env.VITE_INTERMEDIATE_COURSE_PRICE_DISCOUNTED
                : import.meta.env.VITE_COURSE_PRICE_DISCOUNTED}
            </p>
            <p className="body-2">
              {import.meta.env.VITE_COURSE_DISCOUNT_NAME}
            </p>
          </div>
          <div
            className="hidden md:flex md:flex-row md:gap-4"
            ref={heroSectionActionButtonsRef}
            data-testid="heroSectionActionButtons"
          >
            {getPreview()}
            {!course?.isUnlocked && isPublished && getCTA()}
          </div>
          {!course?.isUnlocked && isPublished && (
            <p className="text-xxs-tight text-custom-gray-100">
              Learn about our{" "}
              <a
                href="/scholarships"
                className="underline"
                data-testid="scholarshipLink"
              >
                Scholarship Program{" "}
              </a>
            </p>
          )}
        </div>
        <div className="flex items-center justify-center">
          <img
            src={introSolidityLaptopLogo}
            alt="laptop with solidity course displayed and solidity logo partially overlapping"
          />
        </div>
      </section>
      <section className="md:grid md:grid-cols-3">
        <CourseIncludesSection
          headerText={COURSE_INCLUDES}
          items={courseIncludesContent}
          containerStyles="md:order-2 md:pr-20 md:pl-9 md:py-20"
        />
        <div className="px-4 py-12 bg-white text-black md:order-1 md:col-span-2 md:pl-20 md:pr-14 md:py-20">
          {isIntermediateCourse ? (
            <h3 className="text-headlines-h2-mobile font-bold mb-2 md:mb-4">
              {INTERMEDIATE_PREREQUISITE}
            </h3>
          ) : (
            <h3 className="text-headlines-h2-mobile font-bold mb-2 md:mb-4">
              {PREREQUISITE}
            </h3>
          )}
          {isIntermediateCourse ? (
            <p className="relative text-body-1-mobile text-gray-400 mb-2 md:text-body-1">
              {INTERMEDIATE_PREREQUISITE_DESC}
            </p>
          ) : (
            <p className="relative text-body-1-mobile text-gray-400 mb-2 md:text-body-1">
              {PREREQUISITE_DETAILS}
            </p>
          )}
          <br />
          <h3 className="text-headlines-h2-mobile font-bold mb-2 md:mb-4">
            {COURSE_DESCRIPTION_LABEL}
          </h3>
          <div
            className={classNames(
              "relative text-body-1-mobile text-gray-400 mb-2 md:text-body-1",
              { "line-clamp-5 md:line-clamp-none": !showAllDescription }
            )}
          >
            <p>
              {isIntermediateCourse
                ? INTERMEDIATE_EXTENDED_COURSE_DESCRIPTION_1
                : EXTENDED_COURSE_DESCRIPTION_1}
            </p>
            <br />
            <p>
              {isIntermediateCourse
                ? INTERMEDIATE_EXTENDED_COURSE_DESCRIPTION_2
                : EXTENDED_COURSE_DESCRIPTION_2}
            </p>
            <br />
            {isIntermediateCourse && (
              <p>{INTERMEDIATE_EXTENDED_COURSE_DESCRIPTION_3}</p>
            )}
            {!showAllDescription && (
              <div
                className="absolute h-2/5 bottom-0 z-10 w-full bg-gradient-fade-to-white md:hidden"
                data-testid="descriptionBlocker"
              />
            )}
          </div>
          <button
            className="inline-flex items-center gap-1 text-custom-black cursor-pointer md:hidden"
            type="submit"
            onClick={() => setShowAllDescription(!showAllDescription)}
            data-testid="toggleDescription"
          >
            <Icon
              iconName={!showAllDescription ? "plus" : "minus"}
              fill="fill-black"
            />
            <p className="underline text-link-sm -translate-y-[2px]">
              {!showAllDescription ? SHOW_ALL : SHOW_LESS}
            </p>
          </button>
        </div>
      </section>
      <CourseSyllabusSection courseId={course.id} />
      <CourseBenefitsSection />
      <section className="px-4 py-12 bg-custom-cream text-black md:px-72 md:py-20">
        <h3 className="text-headlines-h3 font-bold pb-4 border-b border-b-custom-gray-100 md:pb-6 md:text-headlines-h2-mobile">
          {FREQUENTLY_ASKED_QUESTIONS}
        </h3>
        <Accordion
          items={course.id === "course_mu0u8" ? intermediateFaqs : faqs}
          themeOverride="light"
        />
      </section>
      <section className="px-4 py-20 bg-secondary-purple-400 md:px-48">
        <h4 className="uppercase text-center mb-4 text-transparent bg-clip-text text-header-small font-bold bg-gradient-green-light-condensed">
          {SCHOLARSHIP_PROGRAM}
        </h4>
        <p className="text-center text-body-1-mobile">
          {SCHOLARSHIP_PROGRAM_DESC}
          <a
            href="/scholarships"
            className="underline"
            data-testid="scholarshipLink"
          >
            here
          </a>
          .
        </p>
      </section>
      <FooterSection />
    </div>
  );
}
