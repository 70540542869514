/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-children-prop */
/* eslint import/no-cycle: [2, { maxDepth: 1 }] */
import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // fancy stuff like tables and checkboxes
import rehypeRaw from "rehype-raw"; // allows html in markdown
import classNames from "classnames";
import { theme } from "../../context";
import Avatar from "./Avatar";
import WaitingList from "./WaitList";
import Code from "./Code";
import {
  ModifiedA,
  ModifiedH1,
  ModifiedH2,
  ModifiedH3,
  ModifiedH4,
  ModifiedH5,
  ModifiedHR,
  ModifiedLi,
  ModifiedUl,
  ModifiedP,
} from "./modifiedHTML";

const getAdditionalClasses = ({
  element,
  index,
  siblingCount,
  numOfElementStyles,
}) =>
  element?.length > 1
    ? element[
        // This should roughly work for elements, some styles might
        // get missed or repeated, but it gives enough variance for now
        // it will be hard to give it the exact, since the indexing
        // and count is given based on the markdown as a whole
        // so if we follow good markdown semantics this should be good enough
        // and should never be larger than the amount of styles given
        Math.round((index / siblingCount) * numOfElementStyles)
      ]
    : element[0];

export default function Markdown({ content, themeOverride, elementStyles }) {
  let isDarkMode = theme()[0] === "dark";
  if (themeOverride) isDarkMode = themeOverride === "dark";
  const { code, h1, h2, h3, li, p } = elementStyles || ["light bg-transparent"];

  return (
    <ReactMarkdown
      className={classNames(
        "bg-transparent prose lg:prose-base",
        isDarkMode ? "prose-dark" : "prose-light"
      )}
      children={content}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      transformLinkUri={
        null /* tell them to not sanitize our article/jargon links https://github.com/remarkjs/react-markdown/tree/8.0.3#uritransformer */
      }
      includeElementIndex
      components={{
        a: ModifiedA,
        avatar: Avatar,
        code: ({ children, inline, className }) =>
          Code({
            children,
            inline,
            className,
            additionalClasses: code && code[0],
          }),
        h1: ({ children }) =>
          // There should not be more than 1 H1 in the markdown section.
          ModifiedH1(
            { children, additionalClasses: (h1 && h1[0]) || theme },
            theme
          ),
        h2: ({ children }) =>
          ModifiedH2({ children, additionalClasses: (h2 && h2[0]) || theme }),
        h3: ({ children, siblingCount, index }) =>
          ModifiedH3({
            children,
            additionalClasses: h3
              ? getAdditionalClasses({
                  element: h3,
                  index,
                  siblingCount,
                  numOfElementStyles: elementStyles.h3.length,
                })
              : theme,
          }),
        h4: ModifiedH4,
        h5: ModifiedH5,
        hr: ModifiedHR,
        li: ({ children }) =>
          ModifiedLi({ children, additionalClasses: (li && li[0]) || theme }),
        p: ({ children }) =>
          ModifiedP({ children, additionalClasses: (p && p[0]) || theme }),
        ul: ModifiedUl,
        "waiting-list": WaitingList,
      }}
    />
  );
}

Markdown.propTypes = {
  content: PropTypes.string.isRequired,
  themeOverride: PropTypes.string,
  // To control the individual element styles
  elementStyles: PropTypes.shape({
    h1: PropTypes.arrayOf(PropTypes.string),
    h2: PropTypes.arrayOf(PropTypes.string),
    h3: PropTypes.arrayOf(PropTypes.string),
    li: PropTypes.arrayOf(PropTypes.string),
    p: PropTypes.arrayOf(PropTypes.string),
  }),
};
