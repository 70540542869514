function lsKey1(apiLesson) {
  return `${apiLesson.courseId}-${apiLesson.ordinal}-files`;
}

function lsKey2(apiLesson, filePath) {
  return `${apiLesson.courseId}-${apiLesson.id}-file-${filePath}`;
}

function lsKey3(apiLesson, filePath, userId) {
  return `${userId}-${apiLesson.courseId}-${apiLesson.id}-file-${filePath}`;
}

function migrations(apiLesson, apiFile, userId) {
  return [
    [
      lsKey1(apiLesson),
      (lsFiles) =>
        Object.keys(lsFiles).map((path) => [
          lsKey2(apiLesson, path),
          { body: lsFiles[path], updatedAt: new Date() },
        ]),
    ],
    [
      lsKey2(apiLesson, apiFile.path),
      (entry) => [[lsKey3(apiLesson, apiFile.path, userId), entry]],
    ],
  ];
}

function getLatestFileBody(lsFile, apiFile) {
  const lsUpdatedAt = new Date(lsFile.updatedAt);
  const apiUpdatedAt = new Date(apiFile.updatedAt);
  const body = lsUpdatedAt > apiUpdatedAt ? lsFile.body : apiFile.currentCode;

  return body;
}

export { lsKey3 as lsFileKey, migrations, getLatestFileBody };
