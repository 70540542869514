import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { theme } from "../../context";
import { useSlideAnimation } from "../../hooks/useAnimation";
import { Hint, Icon, Markdown, RunTestChip } from "../index";

const convertToCodeStyleMarkdown = (text) => `\`\`\`\n${text}\n\`\`\``;

export default function RunTestOutput({
  evaluatedResult,
  setShowOutput,
  error,
}) {
  const { type, stdout, stderr, hint } = evaluatedResult || {};
  const currentTheme = theme()[0];
  const isDarkTheme = currentTheme === "dark";

  const [animation, switchAnimation] = useSlideAnimation(
    setShowOutput,
    "slideUpAndDown"
  );

  return (
    <div
      className={classNames(
        "flex flex-col h-[40vh]",
        isDarkTheme ? "bg-custom-black" : "bg-custom-white",
        animation
      )}
      data-testid="runTestOutput"
    >
      <div className="flex justify-between">
        <div
          className={classNames(
            "flex border-b justify-between w-full p-3",
            isDarkTheme ? "border-custom-gray-400" : "border-custom-gray-100"
          )}
        >
          <p
            className={classNames(
              "font-bold text-md",
              isDarkTheme ? "text-custom-white" : "text-custom-green-slate"
            )}
          >
            Run Code
          </p>
          <Icon
            iconName="close"
            ariaLabel="close element"
            fill="fill-custom-gray-200"
            onClick={() => switchAnimation()}
          />
        </div>
      </div>

      <div className="mb-4 overflow-auto" data-testid="testResults">
        <div className="flex flex-row my-3">
          <div
            className={classNames(
              "p-3 text-sm",
              isDarkTheme ? "text-custom-white" : "text-custom-green-slate"
            )}
          >
            Test
          </div>
          {type && <RunTestChip status={type} />}
        </div>
        {/* FIXME: Error component? Or what should this look like? */}
        {error && (
          <p
            className={classNames(
              "ml-6",
              isDarkTheme ? "text-custom-white" : "text-custom-green-slate"
            )}
          >
            Oops! Something went wrong.
          </p>
        )}
        {hint && <Hint message={hint} />}
        {(stdout || stderr) && (
          <div
            className={classNames(
              "m-4 p-4 rounded font-mono text-xxs",
              isDarkTheme
                ? "text-custom-white bg-editor-base03"
                : "text-editor-base0 bg-editor-base2"
            )}
            data-testid="consoleOutput"
          >
            {stdout && (
              <>
                <p className="p-3 uppercase">Console Output:</p>
                <Markdown
                  content={convertToCodeStyleMarkdown(stdout)}
                  elementStyles={{ code: ["stdout"] }}
                />
              </>
            )}
            {stderr && (
              <Markdown
                content={convertToCodeStyleMarkdown(stderr)}
                elementStyles={{ code: ["stderr"] }}
              />
            )}
          </div>
        )}
      </div>

      {type === "TESTS_PASS" && (
        <p
          className="p-3 bg-primary-green text-custom-white font-bold text-md w-full animation mt-auto"
          data-testid="passed"
        >
          Lesson complete!
        </p>
      )}
    </div>
  );
}

RunTestOutput.propTypes = {
  evaluatedResult: PropTypes.shape({
    type: PropTypes.string,
    stdout: PropTypes.string,
    stderr: PropTypes.string,
    hint: PropTypes.string,
  }),
  setShowOutput: PropTypes.func.isRequired,
  error: PropTypes.string,
};
