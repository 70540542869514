import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { AvailableIcons } from "./availableIcons";
import { ReactComponent as Account } from "../../assets/svgs/account.svg";
import { ReactComponent as Arrow } from "../../assets/svgs/arrow.svg";
import { ReactComponent as Book } from "../../assets/svgs/book.svg";
import { ReactComponent as Cancel } from "../../assets/svgs/cancel.svg";
import { ReactComponent as ChatBubbles } from "../../assets/svgs/chatBubbles.svg";
import { ReactComponent as Check } from "../../assets/svgs/check.svg";
import { ReactComponent as CheckInCircle } from "../../assets/svgs/check-in-circle.svg";
import { ReactComponent as Chevron } from "../../assets/svgs/chevron-down.svg";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";
import { ReactComponent as Coin } from "../../assets/svgs/coin.svg";
import { ReactComponent as Collapse } from "../../assets/svgs/collapse.svg";
import { ReactComponent as Discord } from "../../assets/svgs/landingPage/icon_discord.svg";
import { ReactComponent as Edit } from "../../assets/svgs/edit.svg";
import { ReactComponent as Ellipse } from "../../assets/svgs/ellipse.svg";
import { ReactComponent as Envelope } from "../../assets/svgs/envelope.svg";
import { ReactComponent as Expand } from "../../assets/svgs/expand.svg";
import { ReactComponent as FlagPerson } from "../../assets/svgs/flag-person.svg";
import { ReactComponent as GoogleLogo } from "../../assets/svgs/google-logo.svg";
import { ReactComponent as Hamburger } from "../../assets/svgs/hamburger.svg";
import { ReactComponent as Home } from "../../assets/svgs/home.svg";
import { ReactComponent as Instructor } from "../../assets/svgs/instructor.svg";
import { ReactComponent as Leaf } from "../../assets/svgs/leaf.svg";
import { ReactComponent as LinkTo } from "../../assets/svgs/link-to.svg";
import { ReactComponent as List } from "../../assets/svgs/list.svg";
import { ReactComponent as Medal } from "../../assets/svgs/medal.svg";
import { ReactComponent as MobilePhone } from "../../assets/svgs/mobilePhone.svg";
import { ReactComponent as Minus } from "../../assets/svgs/minus.svg";
import { ReactComponent as NFT } from "../../assets/svgs/nft.svg";
import { ReactComponent as LogOut } from "../../assets/svgs/log-out.svg";
import { ReactComponent as OfficeDoor } from "../../assets/svgs/officeDoor.svg";
import { ReactComponent as Pencil } from "../../assets/svgs/pencil.svg";
import { ReactComponent as Play } from "../../assets/svgs/play.svg";
import { ReactComponent as Plus } from "../../assets/svgs/plus.svg";
import { ReactComponent as QLogo } from "../../assets/svgs/q-logo.svg";
import { ReactComponent as QuestionMark } from "../../assets/svgs/question-mark.svg";
import { ReactComponent as QuickbeamBook } from "../../assets/svgs/quickbeam-book.svg";
import { ReactComponent as Reset } from "../../assets/svgs/reset.svg";
import { ReactComponent as SuccessPerson } from "../../assets/svgs/success-person.svg";
import { ReactComponent as Star } from "../../assets/svgs/star.svg";
import { ReactComponent as ThemeToggle } from "../../assets/svgs/theme-toggle.svg";
import { ReactComponent as Twitter } from "../../assets/svgs/landingPage/icon_twitter.svg";
import { ReactComponent as UnitedHands } from "../../assets/svgs/united-hands.svg";
import { ReactComponent as Wallet } from "../../assets/svgs/wallet.svg";
import { ReactComponent as Warning } from "../../assets/svgs/warning.svg";
import { ReactComponent as XInCircle } from "../../assets/svgs/x-in-circle.svg";

export default function Icon({
  fill,
  stroke,
  iconName,
  onClick,
  ariaLabel,
  additionalClasses,
  size,
  styleType,
}) {
  const sizes = {
    default: "w-6 h-6",
    xxlarge: "w-16 h-16",
    xlarge: "w-10 h-10",
    large: "w-8 h-8",
    small: "w-5 h-5",
    xsmall: "w-4 h-4",
  };

  const iconColor = () => {
    if (!styleType || styleType === "none") return fill;
    if (styleType === "gray") return "fill-custom-gray-300";
    if (styleType === "primary" || styleType === "outline-white")
      return "fill-custom-white";
    return "fill-primary-green";
  };

  const strokeColor = () => {
    if (!styleType) return stroke;
    if (styleType === "gray") return "stroke-custom-gray-300";
    return "stroke-primary-green";
  };

  const sharedClasses = classNames(sizes[size], iconColor(), additionalClasses);

  const icons = {
    account: <Account className={sharedClasses} />,
    arrowLeft: <Arrow className={classNames(sharedClasses, "rotate-180")} />,
    arrowRight: <Arrow className={sharedClasses} />,
    book: <Book className={sharedClasses} />,
    cancel: <Cancel className={sharedClasses} />,
    chatBubbles: <ChatBubbles className={sharedClasses} />,
    check: <Check className={sharedClasses} />,
    checkInCircle: <CheckInCircle className={sharedClasses} />,
    chevron: <Chevron className={sharedClasses} />,
    chevronUp: (
      <Chevron
        className={classNames(sharedClasses, "rotate-180 translate-y-0.5")}
      />
    ),
    chevronRight: (
      <Chevron
        className={classNames(sharedClasses, "-rotate-90 translate-y-0.5")}
      />
    ),
    close: <Close className={sharedClasses} />,
    coin: <Coin className={sharedClasses} />,
    collapse: <Collapse className={sharedClasses} />,
    discord: <Discord className={sharedClasses} />,
    edit: <Edit className={sharedClasses} />,
    ellipse: <Ellipse className={sharedClasses} />,
    envelope: <Envelope className={sharedClasses} />,
    expand: <Expand className={sharedClasses} />,
    flagPerson: <FlagPerson className={sharedClasses} />,
    googleLogo: <GoogleLogo className={sharedClasses} />,
    hamburger: <Hamburger className={sharedClasses} />,
    home: <Home className={sharedClasses} />,
    instructor: <Instructor className={sharedClasses} />,
    leaf: <Leaf className={sharedClasses} />,
    linkTo: <LinkTo className={sharedClasses} />,
    list: <List className={sharedClasses} />,
    medal: <Medal className={sharedClasses} />,
    minus: <Minus className={sharedClasses} />,
    mobilePhone: <MobilePhone className={sharedClasses} />,
    nft: <NFT className={sharedClasses} />,
    pencil: <Pencil className={sharedClasses} />,
    play: <Play className={sharedClasses} />,
    plus: <Plus className={sharedClasses} />,
    logOut: <LogOut className={sharedClasses} />,
    officeDoor: <OfficeDoor className={sharedClasses} />,
    qLogo: <QLogo className={sharedClasses} />,
    questionMark: <QuestionMark className={sharedClasses} />,
    quickbeamBook: <QuickbeamBook />,
    reset: <Reset className={sharedClasses} />,
    successPerson: <SuccessPerson className={sharedClasses} />,
    themeToggle: (
      <ThemeToggle className={classNames(sharedClasses, strokeColor())} />
    ),
    twitter: <Twitter className={sharedClasses} />,
    star: <Star className={sharedClasses} />,
    unitedHands: <UnitedHands className={sharedClasses} />,
    wallet: <Wallet className={sharedClasses} />,
    warning: <Warning className={sharedClasses} />,
    xInCircle: <XInCircle className={sharedClasses} />,
  };
  const IconComponent = () => icons[iconName];
  return onClick ? (
    <button type="submit" onClick={onClick} aria-label={ariaLabel}>
      <IconComponent />
    </button>
  ) : (
    <IconComponent />
  );
}

Icon.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
  iconName: PropTypes.oneOf(AvailableIcons).isRequired,
  onClick: PropTypes.func,
  // TODO: Make required if onClick is defined
  ariaLabel: PropTypes.string,
  // Note: Any classes already defined with tailwind will not necessarily be overridden
  // this is mostly meant to allow flexibility to add padding, positioning, etc
  additionalClasses: PropTypes.string,
  size: PropTypes.oneOf([
    "default",
    "xxlarge",
    "xlarge",
    "large",
    "small",
    "xsmall",
  ]),
  // For icons used with buttons/links
  styleType: PropTypes.oneOf([
    "primary",
    "secondary",
    "outline",
    "outline-white",
    "gray",
    "auth",
    "none",
  ]),
};

Icon.defaultProps = {
  fill: "fill-primary-green",
  stroke: "stroke-primary-green",
  size: "default",
};
