/* eslint-disable import/prefer-default-export */

const OAUTH_STATE_KEY_PREFIX = "oauth_state_key";
const POST_LOGIN_PATH_KEY = "post_login_path_key";

/**
 * Generates a random state key
 * @return {string}
 */
// https://medium.com/@dazcyril/generating-cryptographic-random-state-in-javascript-in-the-browser-c538b3daae50
const generateState = () => {
  const validChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  array = array.map((x) => validChars.codePointAt(x % validChars.length));
  const randomState = String.fromCharCode.apply(null, array);
  return randomState;
};

const oauthStateKey = (redirectPath) =>
  `${OAUTH_STATE_KEY_PREFIX}_${redirectPath}`;

// This manages the auth session state for the OAuth protocol,
// tracking `state` for oauth query parameter and tracking the post-login redirect path.
// https://auth0.com/docs/secure/attack-protection/state-parameters
export const OAuthStateManager = {
  initiateFor(sessionIdentifier) {
    const state = generateState();
    sessionStorage.setItem(oauthStateKey(sessionIdentifier), state);
    return state;
  },
  finishFor(sessionIdentifier) {
    sessionStorage.removeItem(oauthStateKey(sessionIdentifier));
  },
  getFor(sessionIdentifier) {
    return sessionStorage.getItem(oauthStateKey(sessionIdentifier));
  },
  // Note: We could instead encode the post-login redirect url into the state string,
  // but it doesn't seem worth the complexity when we can simply track the redirect url
  // in a separate session storage key via `pushPostLoginPath` and `popPostLoginPath`.
  pushPostLoginPath(postLoginPath) {
    sessionStorage.setItem(POST_LOGIN_PATH_KEY, postLoginPath || "");
  },
  popPostLoginPath() {
    const postLoginPath = sessionStorage.getItem(POST_LOGIN_PATH_KEY);
    sessionStorage.removeItem(POST_LOGIN_PATH_KEY);
    return postLoginPath;
  },
};
