import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as AccessibleImage } from "../../assets/svgs/landingPage/accessible.svg";
import { ReactComponent as AssessmentsImage } from "../../assets/svgs/landingPage/assessments.svg";
import { ReactComponent as BridgeImage } from "../../assets/svgs/landingPage/bridge.svg";
import { ReactComponent as CommunityImage } from "../../assets/svgs/landingPage/community.svg";

function FeatureItem({ imageComponent, text, isImageLeft }) {
  return (
    <div
      className={`flex lg:flex-col xl:flex-row basis-1/4 bg-gradient-rad-green-darker px-5 md:px-4 py-8 xl:p-12 justify-center items-center ${
        isImageLeft ? "flex-row" : "flex-row-reverse"
      }`}
    >
      {imageComponent}
      <p className="lg:mt-4 md:mr-4 md:ml-4 px-4 xl:px-0 xl:mt-0 xl:mr-0 xl:ml-5 text-left md:text-center xl:text-left body-2-condensed text-custom-cream shrink">
        {text}
      </p>
    </div>
  );
}

FeatureItem.propTypes = {
  imageComponent: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  isImageLeft: PropTypes.bool,
};

export default function FeatureItemsSection() {
  const VALUE_ACCESSIBILITY_TAG_LINE =
    "No set up required, just start coding in your browser.";
  const VALUE_BRIDGE_TAG_LINE =
    "Building the bridge between Web2 and Web3 concepts";
  const VALUE_ASSESSMENTS_TAG_LINE =
    "Mint your own Course Completion NFT on the blockchain.";
  const VALUE_COMMUNITY_TAG_LINE = "Never walk alone. Join our Discord";

  const features = [
    {
      name: "Accessibility",
      text: VALUE_ACCESSIBILITY_TAG_LINE,
      imageComponent: <AccessibleImage className="w-20 min-w-[5rem] h-20" />,
      isImageLeft: true,
    },
    {
      name: "Bridge",
      text: VALUE_BRIDGE_TAG_LINE,
      imageComponent: <BridgeImage className="w-20 min-w-[5rem] h-20" />,
      isImageLeft: false,
    },
    {
      name: "Assessments",
      text: VALUE_ASSESSMENTS_TAG_LINE,
      imageComponent: <AssessmentsImage className="w-20 min-w-[5rem] h-20" />,
      isImageLeft: true,
    },
    {
      name: "Community",
      text: VALUE_COMMUNITY_TAG_LINE,
      imageComponent: <CommunityImage className="w-20 min-w-[5rem] h-20" />,
      isImageLeft: false,
    },
  ];

  return (
    <div className="flex flex-col lg:flex-row w-full bg-gradient-rad-green-darker max-w-[1440px] self-center xl:-translate-y-1/2 xl:-mb-20">
      {features.map((feature) => (
        <FeatureItem
          imageComponent={feature.imageComponent}
          text={feature.text}
          isImageLeft={feature.isImageLeft}
          key={feature.name}
        />
      ))}
    </div>
  );
}
