/* eslint-disable react/no-array-index-key */

import classNames from "classnames";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, Icon } from "../../index";
import { theme } from "../../../context";

export default function AccordionItem({ item, themeOverride }) {
  let isDarkMode = theme()[0] === "dark";
  if (themeOverride) isDarkMode = themeOverride === "dark";
  const { title, content } = item;

  const [isExpanded, setIsExpanded] = useState(false);

  const contentItems = content.map((contentItem, index) => {
    const { type, value } = contentItem;
    let element;
    switch (type) {
      case "text":
        element = (
          <p className="text-custom-gray-400" key={index}>
            {value}
          </p>
        );
        break;
      case "link":
        element = (
          <Link
            href={value?.href}
            text={value?.text}
            key={index}
            size="small"
            customLinkColor="primary-green"
            isUnderlined
          />
        );
        break;
      case "markdown":
        element = (
          <div className="text-custom-gray-400" key={index}>
            {value}
          </div>
        );
        break;
      default:
        console.error("accordion item type not supported");
        break;
    }
    return element;
  });

  return (
    <li
      className={classNames(
        "border-b py-4 md:px-4 md:py-3",
        isDarkMode ? "border-b-custom-gray-300" : "border-b-custom-gray-100"
      )}
    >
      <button
        className="w-full text-left cursor-pointer focus:outline-none"
        type="submit"
        onClick={() => setIsExpanded(!isExpanded)}
        aria-label={`reveal description of ${title}`}
        data-testid="accordionItem"
      >
        <div
          className={classNames(
            "flex items-center justify-between gap-14 text-body-2",
            { "md:pb-3": isExpanded },
            isDarkMode ? "text-custom-white" : "text-custom-black"
          )}
        >
          <h4 className="font-bold md:text-body-1-mobile">{title}</h4>
          <Icon
            iconName={isExpanded ? "chevronUp" : "chevron"}
            fill={isDarkMode ? "fill-custom-white" : "fill-custom-black"}
            additionalClasses="shrink-0"
          />
        </div>
      </button>
      {!!isExpanded && (
        <div
          className="flex flex-col gap-4 md:pb-6"
          data-testid="accordionItemContent"
        >
          {contentItems}
        </div>
      )}
    </li>
  );
}

AccordionItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.array,
  }).isRequired,
  themeOverride: PropTypes.string,
};
