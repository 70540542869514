import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Button from "../button";

export default function Modal({
  modalContent,
  backgroundStyling,
  isInterstitial,
}) {
  const {
    title,
    icon,
    iconStyling,
    description,
    buttonText,
    buttonIcon,
    onClick,
  } = modalContent;

  return (
    <div
      className={classNames(
        "fixed flex flex-col justify-center items-center h-full w-full",
        backgroundStyling,
        isInterstitial ? "z-40" : "z-[999] bg-custom-black bg-opacity-50"
      )}
      data-testid="modalContainer"
    >
      <div
        className={classNames(
          "flex flex-col items-center px-6 pb-4 pt-10 gap-6 max-w-md h-auto text-custom-green-slate",
          { "bg-custom-green-soft shadow-md-purple": !isInterstitial }
        )}
      >
        <div className="flex flex-col items-center text-center">
          <img src={icon} alt="modal icon" className={iconStyling} />
          <h1
            className={classNames("text-md font-bold", {
              "text-header-large": isInterstitial,
            })}
          >
            {title}
          </h1>
          <p
            className={classNames("text-xs leading-5", {
              "leading-6 mt-1": isInterstitial,
            })}
          >
            {description}
          </p>
        </div>
        <Button
          onClick={onClick}
          label={buttonText}
          styleType="primary"
          iconName={buttonIcon}
          isIconFirst={false}
          isFullWidth
          ariaLabel={buttonText}
        />
      </div>
    </div>
  );
}

Modal.propTypes = {
  modalContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    iconStyling: PropTypes.string,
    description: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    buttonIcon: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  }),
  backgroundStyling: PropTypes.string,
  isInterstitial: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  modalContent: {
    iconStyling: "",
    buttonText: "",
    buttonIcon: "",
  },
  backgroundStyling: "",
};
