import React from "react";

import leavesImage from "../../../assets/pngs/leaves.png";
import quickbeamQ from "../../../assets/svgs/q-hero.svg";

export default function FancyQ() {
  const fancyQClassNames = [
    "p-0",
    "w-1/2",
    "md:w-full",
    "max-w-[300px]",
    "sm:max-w-[360px]",
    "lg:max-w-none",
    "animate-bg-oscillating",
    "[background:linear-gradient(230.68deg,#FBF9F3_0%,#EAC130_16.68%,#FBF9F3_32.32%,#916FEF_50.05%,#FBF9F3_67.77%,#2AE4AE_83.41%,#FBF9F3_100.1%)]",
    "[background-size:400%_400%]",
    "bg-no-repeat",
    "[mask-repeat:no-repeat]",
    "[-webkit-mask-repeat:no-repeat]",
    "[mask-position:top_left]",
    "[-webkit-mask-position:top_left]",
  ].join(" ");

  // since twcss can't seem to deal with mask-image correctly, we'll use an inline style instead
  const maskImageStyles = {
    maskImage: `url('${quickbeamQ}')`,
    WebkitMaskImage: `url('${quickbeamQ}')`,
  };

  return (
    <div className={fancyQClassNames} style={maskImageStyles}>
      <img
        className="m-0 m-auto w-full [mix-blend-mode:screen]"
        src={leavesImage}
        alt="leafy background for quickbeam logo"
      />
    </div>
  );
}
