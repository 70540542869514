/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import classNames from "classnames";
import { LabelWrapper } from "./index";

export default function Input({
  label,
  name,
  placeholder,
  size,
  isLabelUpperCase,
}) {
  const [field, meta] = useField({ type: "text", name, placeholder });

  const { value } = meta;

  const sizeClasses = {
    default: "default",
    small: "small",
  };

  const textSizeClasses = {
    default: "text-body-1-mobile",
    small: "text-headlines-h4",
  };

  return (
    <LabelWrapper
      label={label}
      name={name}
      isLabelUpperCase={isLabelUpperCase}
      sizeClasses={textSizeClasses[size]}
    >
      <input
        className={classNames(
          "input border border-custom-gray-200 rounded-sm px-4 bg-editor-base3",
          value ? "text-custom-gray-400" : "text-custom-gray-200",
          sizeClasses[size]
        )}
        {...field}
        placeholder={placeholder}
      />
      {meta?.touched && meta?.error && (
        <p
          className={classNames("text-primary-red pt-2", textSizeClasses[size])}
        >
          {meta?.error}
        </p>
      )}
    </LabelWrapper>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["default", "small"]),
  isLabelUpperCase: PropTypes.bool,
};

Input.defaultProps = {
  size: "default",
  isLabelUpperCase: false,
};
