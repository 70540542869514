import { ApolloClient, InMemoryCache, from, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import * as Sentry from "@sentry/react";
import { REFRESH_AUTH_TOKEN } from "../queries";
import { shouldRefreshAuth } from "../utils/auth/authUtil";
import {
  dangerousLogIn,
  dangerousLogOut,
  getAuthAndRefreshTokens,
} from "../context";
import analytics from "../utils/analyticsTracker";

const SERVER_URL =
  import.meta.env.VITE_USE_MOCK_SERVER === "true"
    ? import.meta.env.VITE_MOCK_GRAPHQL_HOST
    : import.meta.env.VITE_SERVER_GRAPHQL_HOST;

const client = new ApolloClient({
  // https://www.apollographql.com/docs/react/caching/cache-configuration/
  cache: new InMemoryCache({
    addTypename: true,
  }),
});

const httpLink = new HttpLink({ uri: SERVER_URL });

const getRefreshedAuthToken = (authToken, refreshToken) =>
  client
    .mutate({
      mutation: REFRESH_AUTH_TOKEN,
      variables: { refreshToken: refreshToken, authToken: authToken },
    })
    .then(({ data: { refreshAuthToken: refreshedAuthToken } }) => {
      dangerousLogIn(refreshedAuthToken, refreshToken);

      return refreshedAuthToken;
    });

const getAuthToken = () => {
  const { authToken, refreshToken } = getAuthAndRefreshTokens();

  if (!authToken || !refreshToken) {
    return Promise.resolve();
  }

  if (shouldRefreshAuth(authToken)) {
    return getRefreshedAuthToken(authToken, refreshToken);
  }

  return Promise.resolve(authToken);
};

const authLink = setContext(async (request, { headers: previousHeaders }) => {
  // this prevents recursing when making refreshAuthToken request
  if (
    request.operationName === "refreshAuthToken" ||
    request.operationName === "loginOauth"
  ) {
    return previousHeaders;
  }

  const authToken = await getAuthToken();
  if (!authToken) {
    dangerousLogOut();
    analytics.resetSession();
    return previousHeaders;
  }

  return {
    headers: {
      ...previousHeaders,
      Authorization: `Bearer ${authToken}`,
    },
  };
});

const unAuthLink = onError(({ networkError, graphQLErrors }) => {
  const unauthorized =
    networkError?.statusCode === 401 ||
    graphQLErrors?.some((err) => err?.message.includes("Unauthorized")); // graphql lib used in the backend might return error message in place of 401 status

  if (networkError && !unauthorized) {
    if (import.meta.env.DEV)
      console.error(
        `[Network error]: Message: ${networkError.message}, Location: ${networkError.locations}, Path: ${networkError.path}`
      );
    // These errors are not actually JS errors, should be easier to read with captureMessage
    Sentry.captureMessage(networkError);
  }

  if (graphQLErrors?.length > 0) {
    graphQLErrors.forEach((error) => {
      if (import.meta.env.DEV)
        console.error(
          `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
        );
      // These errors are not actually JS errors, should be easier to read with captureMessage
      // might want to consider fingerprinting in the future: https://docs.sentry.io/platforms/javascript/guides/react/usage/sdk-fingerprinting/
      if (error.message !== "Unauthorized") {
        Sentry.captureMessage(error);
      }
    });
  }

  if (unauthorized) {
    dangerousLogOut();
    analytics.resetSession();

    // Now only fields require authentication, but if a field is not allowed to be null,
    // the whole query returns null, and an authentication error
    // this can lead to a request loop here with the reload, so we will kill
    // that loop if we are at the root or the login page
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/login"
    ) {
      return;
    }
    window.location.reload();
  }
});

client.setLink(from([authLink, unAuthLink, httpLink]));

export default client;
