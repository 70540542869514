import { useLocalStorage } from "./useLocalStorage";
import {
  lsFileKey,
  migrations,
  getLatestFileBody,
} from "./helpers/editorFileBodyHelpers";

export default function useEditorFileBody(apiLesson, apiFile, userId) {
  const [lsFile, setLsFile] = useLocalStorage(
    lsFileKey(apiLesson, apiFile.path, userId),
    { body: apiFile.currentCode, updatedAt: apiFile.updatedAt },
    migrations(apiLesson, apiFile, userId)
  );

  const body = getLatestFileBody(lsFile, apiFile);

  const updateBody = (newBody) => {
    if (!apiFile.readonly) {
      setLsFile({ body: newBody, updatedAt: new Date() });
    }
  };
  const resetBody = () => {
    updateBody(apiFile.initialCode); // Note: initialCode is either the previous lesson's code or the curriculum's initialization value
  };
  return [body, updateBody, resetBody];
}
