import { gql } from "@apollo/client";

const CREATE_CHECKOUT_SESSION = gql`
  mutation CreateCheckoutSession(
    $successRedirectUrl: String!
    $cancelRedirectUrl: String!
    $courseId: String!
    $quantity: Int!
  ) {
    createCheckoutSession(
      successRedirectUrl: $successRedirectUrl
      cancelRedirectUrl: $cancelRedirectUrl
      courseId: $courseId
      quantity: $quantity
    ) {
      id
      url
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { CREATE_CHECKOUT_SESSION };
