import React from "react";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { Button } from "../index";

export default function ResetButton({
  label,
  id,
  isFullWidth,
  styleType,
  testId,
  newValues,
}) {
  const { resetForm } = useFormikContext();
  return (
    <Button
      onClick={() => resetForm({ values: newValues })}
      label={label}
      styleType={styleType}
      id={id}
      testId={testId}
      isFullWidth={isFullWidth}
    />
  );
}

ResetButton.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  isFullWidth: PropTypes.bool,
  styleType: PropTypes.oneOf(["primary", "secondary", "outline"]),
  // Optional additional logic to perform on the reset click
  testId: PropTypes.string,
  newValues: PropTypes.object.isRequired,
};

ResetButton.defaultProps = {
  label: "Reset",
  id: "resetButton",
  isFullWidth: true,
  styleType: "secondary",
  testId: "resetButton",
};
