import classNames from "classnames";
import React from "react";
import { useLocation } from "react-router-dom";
import {
  AllMentorsChevron,
  WordBubble,
  CourseList,
  ProgressMenuHeader,
} from "../../components";
import { course as CourseContext } from "../../context";
import CourseIncludesSection from "../courseDetails/CourseIncludesSection";
import CourseBenefitsSection from "../courseDetails/CourseBenefitsSection";
import CourseSyllabusSection from "../courseDetails/CourseSyllabusSection";
import {
  COURSE_NFT_2,
  HERES_A_REMINDER,
  MOBILE_FIRST_EXPERIENCE,
  MONTHS_ACCESS,
  VIRTUAL_MENTOR_SESSIONS,
  WEEKLY_OFFICE_HOURS,
  DISCORD_ACCESS_2,
} from "../courseDetails/introSolidityDetails";

export default function PaymentPage() {
  const {
    currentCourse: course,
    isLoadingCurrentCourse,
    isErrorCurrentCourse,
  } = CourseContext();

  const courses = course ? [course] : [];

  const location = useLocation();
  const { currentLessonId } = location?.state || {};
  const realCurrentLessonId = currentLessonId || course?.currentLessonId;
  const lesson = course?.getLesson(realCurrentLessonId);
  const { ordinal, prevLessonId } = lesson || {};

  const PAYMENT_TEXT =
    "Great job on those first few lessons. We hope you’ve gotten a taste for our teaching style. If you’d like to continue, you’ll need to pay for the course at this time.";
  const TRIAL_COMPLETE = "Trial Complete";
  const trialComplete = () => <p>{TRIAL_COMPLETE}</p>;

  const isProd = import.meta.env.prod;
  const courseIncludesContent = [
    {
      iconName: "book",
      text: MONTHS_ACCESS,
    },
    {
      iconName: "discord",
      text: DISCORD_ACCESS_2,
    },
    {
      iconName: "officeDoor",
      text: WEEKLY_OFFICE_HOURS,
    },
    {
      iconName: "chatBubbles",
      text: VIRTUAL_MENTOR_SESSIONS,
    },
    {
      iconName: "mobilePhone",
      text: MOBILE_FIRST_EXPERIENCE,
    },
    {
      iconName: "nft",
      text: COURSE_NFT_2,
    },
  ];

  const shouldShowDetails = () => {
    // FIXME: temp method of determining whether to show details
    if (course?.id === "course_wlu8y" || (!isProd && course?.id === "1")) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={classNames(
        "fixed flex flex-col h-full w-full overflow-y-scroll",
        "bg-gradient-purple-light animate-bg-oscillating bg-[length:400%_400%] bg-no-repeat"
      )}
    >
      <ProgressMenuHeader
        currentLessonTitle=""
        headerStyle="px-4 lg:px-6"
        course={course}
        isLoading={isLoadingCurrentCourse}
        error={isErrorCurrentCourse}
        lessonMetaData={{ ordinal, prevLessonId, nextLessonId: null }}
        overrideCenter={trialComplete}
        canNavigateForward={false}
        changeColor="custom-white"
      />
      <div className="flex flex-col items-center pt-10">
        <div className="flex flex-col items-center pt-5 mx-10 px-6 -mb-10 md:-mb-20">
          <WordBubble textItems={[PAYMENT_TEXT]} />
          <AllMentorsChevron additionalClasses="-z-10" />
          <CourseList
            courses={courses}
            additionalClasses="-translate-y-20 md:-translate-y-36 w-3/4 lg:w-full"
            isLoading={isLoadingCurrentCourse}
            isError={isErrorCurrentCourse}
          />
        </div>
        {shouldShowDetails() && (
          <>
            <div className="flex flex-col items-center w-full bg-primary-green">
              <CourseIncludesSection
                headerText={HERES_A_REMINDER}
                items={courseIncludesContent}
                containerStyles="md:px-72"
              />
            </div>
            <CourseBenefitsSection />
            <CourseSyllabusSection />
          </>
        )}
      </div>
    </div>
  );
}
