import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default function LabelWrapper({
  children,
  label,
  name,
  sizeClasses,
  isLabelUpperCase,
}) {
  return (
    <div className="flex flex-col relative w-full">
      {label && (
        <label
          htmlFor={name}
          className={classNames("pb-1.5", sizeClasses, {
            uppercase: isLabelUpperCase,
          })}
        >
          {label}
        </label>
      )}
      {children}
    </div>
  );
}

LabelWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  label: PropTypes.string,
  // isRequired if label is present
  name: PropTypes.string,
  sizeClasses: PropTypes.string.isRequired,
  isLabelUpperCase: PropTypes.bool,
};

LabelWrapper.defaultProps = {
  isLabelUpperCase: false,
};
