import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Icon, AvailableIcons } from "../index";

export default function Button({
  onClick,
  label,
  ariaLabel,
  isDisabled,
  disabledIconStyleClasses,
  styleType,
  size,
  rounded,
  iconName,
  isIconFirst,
  isIconOnly,
  isFullWidth,
  id,
  testId,
  additionalClasses,
}) {
  const sizeClasses = {
    default: "gap-2 default",
    large: "gap-2 large",
    small: "gap-1 small",
    xsmall: "gap-1 xsmall",
  };

  const isDisabledStyling = disabledIconStyleClasses || "fill-custom-gray-200";

  return (
    <button
      onClick={onClick}
      type="submit"
      className={classNames(
        "button flex items-center justify-center hover:opacity-75",
        isFullWidth ? "w-full" : "w-full md:w-fit",
        styleType.toString(),
        sizeClasses[size],
        { disabled: isDisabled },
        { "flex-row-reverse": !isIconFirst },
        { "border border-custom-gray-400": isIconOnly && styleType === "gray" },
        rounded,
        additionalClasses
      )}
      aria-label={ariaLabel}
      disabled={isDisabled}
      id={id}
      data-testid={testId}
    >
      {iconName && (
        <Icon
          styleType={isDisabled ? null : styleType}
          fill={isDisabled ? isDisabledStyling : null}
          iconName={iconName}
          size={size}
          additionalClasses={`icon ${size}`}
          ariaLabel={isIconOnly ? ariaLabel : null}
        />
      )}
      {label && !isIconOnly && <p>{label}</p>}
    </button>
  );
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  // FIXME: To have a warning if label and iconName are both undefined
  label: PropTypes.string,
  // FIXME: To make required if isIconOnly is true.
  ariaLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  disabledIconStyleClasses: PropTypes.string,
  styleType: PropTypes.oneOf([
    "primary",
    "secondary",
    "outline",
    "outline-white",
    "gray",
    "auth",
  ]),
  size: PropTypes.oneOf(["default", "large", "small", "xsmall"]),
  rounded: PropTypes.oneOf([
    "rounded-none",
    "rounded-sm",
    "rounded",
    "rounded-md",
    "rounded-lg",
    "rounded-xl",
  ]),
  iconName: PropTypes.oneOf(AvailableIcons),
  isIconFirst: PropTypes.bool,
  // FIXME: To have warning if this is true and no iconName
  isIconOnly: PropTypes.bool,
  // An optional prop to allow the button to stretch to full width of parent
  // probably not for isIconOnly
  isFullWidth: PropTypes.bool,
  // An optional prop to pass an id for targeting
  id: PropTypes.string,
  // An optional prop to pass to make element test selection easier
  testId: PropTypes.string,
  // Note: Careful! Any classes already defined with tailwind will not necessarily be overridden
  // this is mostly meant to allow flexibility to add positioning without adding extra divs
  // or ensuring the button words don't wrap whitespace-nowrap
  additionalClasses: PropTypes.string,
};

Button.defaultProps = {
  isDisabled: false,
  styleType: "primary",
  size: "default",
  rounded: "rounded-none",
  isIconOnly: false,
  isIconFirst: true,
  isFullWidth: false,
};
