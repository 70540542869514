import React from "react";
import PropTypes from "prop-types";
import {
  Header,
  AllMentorsChevron,
  FooterSection,
  Image,
  GradientBorder,
} from "../index";
import images from "../image/availableImages";

export default function StaticPageLayout({
  title,
  testid,
  children,
  mentorOption,
}) {
  const getMentorOption = () => {
    const sharedClasses = "absolute w-44 lg:w-52";
    if (mentorOption === "AllMentorsChevron") {
      return (
        <AllMentorsChevron
          additionalClasses={`${sharedClasses} right-24 lg:right-36 top-12 -bottom-3 md:-bottom-6 lg:bottom-0 lg:top-4`}
        />
      );
    }

    return (
      <Image
        name={mentorOption}
        additionalClasses={`${sharedClasses} right-0 md:right-20 lg:right-24 -bottom-6 lg:-bottom-8`}
      />
    );
  };

  return (
    <div className="bg-gradient-purple-light min-h-screen" data-testid={testid}>
      <Header isDarkMode loginButtonStyle="secondary" />
      <div className="flex flex-col max-w-[1440px] justify-center pt-4 lg:pt-[6.5rem]">
        <h1 className="text-headlines-h2 md:text-headlines-h1 font-bold w-full self-center text-center md:w-3/4">
          {title}
        </h1>
        <div className="relative h-32 md:h-28">{getMentorOption()}</div>
      </div>
      <GradientBorder
        gradientBorderClass="bg-gradient-green-light"
        backgroundColor="bg-gradient-white-orange-reverse-light"
        rounded="rounded-t-[3.5rem]"
        additionalClassesForInner="relative z-10 px-4 pb-20"
      >
        <section className="flex flex-col items-center pt-16 lg:pt-20">
          {children}
        </section>
      </GradientBorder>
      <div className="bg-custom-green-dark flex justify-center">
        <div className="w-full">
          <FooterSection />
        </div>
      </div>
    </div>
  );
}

StaticPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  testid: PropTypes.string.isRequired,
  mentorOption: PropTypes.oneOf([...Object.keys(images), "AllMentorsChevron"]),
};

StaticPageLayout.defaultProps = {
  mentorOption: "AllMentorsChevron",
};
