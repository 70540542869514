import React from "react";

import FancyQ from "../../components/landingPage/fancyQ";
import { H1 } from "../../components/text";
import { Link, SubscribeForm } from "../../components";

import { ReactComponent as Logo } from "../../assets/svgs/logo.svg";
import { ReactComponent as HeroPolygons } from "../../assets/svgs/landingPage/hero_polygons.svg";
import { ReactComponent as HeroPolygonsCentered } from "../../assets/svgs/landingPage/hero_polygons_centered.svg";

const START_CODING_NOW_CTA = "Start Coding Now";
const HELLO_HUMANS = "Hello Humans";
const WELCOME_TO = "Welcome to";
const INTRO_TAG_LINE = "Develop the blockchain skills of tomorrow, today.";

export default function HeroSection() {
  return (
    <div
      id="hero-section"
      className="flex items-center flex-col py-14 md:items-start md:text-left md:mt-8 md:pt-8 lg:pt-16 xl:pt-48 md:pb-12 lg:pb-24 lg:pb-40"
    >
      <HeroPolygons className="hidden md:block w-full md:w-[896px] lg:w-[1024px] xl:w-[1440px] absolute left-auto -right-[18px] -top-[24px] lg:right-[4px] lg:top-0 xl:-right-[4px] xl:-top-[12px]" />
      <HeroPolygonsCentered className="md:hidden absolute w-[800px] -top-[36px] sm:w-[960px] sm:-top-[66px]" />
      <div className="flex justify-center md:w-[250px] md:right-[100px] md:top-[127px] md:lg:w-[290px] lg:right-[137px] lg:top-[168px] xl:w-[400px] xl:right-[187px] xl:top-[235px] md:absolute">
        <FancyQ />
      </div>
      <div className="flex flex-col text-center md:text-start mt-12 md:mt-0 md:w-1/2 md:max-w-xs lg:max-w-none z-10">
        <p className="uppercase text-transparent bg-clip-text body-2-condensed font-bold bg-gradient-green-light md:bg-gradient-to-r md:from-secondary-green-200 md:to-custom-green-slate mb-4 md:mb-0">
          {HELLO_HUMANS}
        </p>
        <div className="px-12 md:px-0 flex flex-col md:flex-none items-center md:items-start">
          <H1
            size={H1.Sizes.XL_DYNAMIC}
            color={H1.Colors.CUSTOM_CREAM}
            isBold
            className="mb-0 leading-tight lg:leading-none md:leading-[68px] w-full"
          >
            {WELCOME_TO}
            <span className="hidden md:inline" />
            <span className="invisible w-0 h-0 text-[0px]">Quickbeam</span>
          </H1>
          <Logo className="fill-custom-white h-8 mb-8 md:mb-0 sm:h-[42px] mt-2 sm:-mt-3 md:-mt-3.5 lg:mt-5 xl:-mt-4 lg:h-16 xl:h-[84px] md:w-auto" />
          <p className="body-1 text-custom-green-light md:mt-9">
            {INTRO_TAG_LINE}
          </p>
        </div>
        <Link
          href="/courses"
          text={START_CODING_NOW_CTA}
          styleType="primary"
          asButton
          testid="heroStartCoding"
          additionalClasses="mt-12"
        />
        <SubscribeForm additionalClasses="pt-3 mt-3" />
      </div>
    </div>
  );
}

HeroSection.propTypes = {};
